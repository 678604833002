import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {View, Text, Pressable} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import DownloadSummary from '../../components/DownloadSummary.web';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {
  centsToDollars,
  ellipsify,
  formatBytes,
  formatDate,
  formatTime,
} from '../../utils/common/funcs';
import {getCart, getOrderDetails} from '../../utils/common/orders';

/**
 *
 * @param {Order} order - Order model
 * @returns - Total size of uncompressed assets in bytes
 */
function getOrderBytes(order) {
  // Compile all order assets into an array
  const assets = order.packs.map((pack) => pack.assets).flat();
  // Add up the bytes of each asset
  const result = assets.reduce((acc, asset) => acc + asset.bytes, 0);
  return result;
}

export default function Download({route}) {
  const {style} = useTheme(getThemedStyles);
  const [cart, setCart] = useState(null);
  const [previewThumbs, setPreviewThumbs] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [packList, setPackList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [zippedPack, setZippedPack] = useState(null);

  const cartId = route?.params?.cart;

  useEffect(() => {
    getCart(cartId)
      .then((cartData) => {
        setCart(cartData);

        const previewThumbnails = cartData.packs.reduce((acc, {assets}) => {
          const packUrls = assets.map((asset) => asset.thumb.url);
          return [...acc, ...packUrls];
        }, []);
        setPreviewThumbs(previewThumbnails);
      })
      .catch((err) => {});
  }, [cartId]);

  useEffect(() => {
    getOrderDetails(cartId)
      .then((data) => {
        setOrderData(data);
        setZippedPack(data.hydratedOrder.zippedPack || null);
        setPackList(data.hydratedOrder.packs.map((pack) => pack.name));
      })
      .then(() => setLoaded(true));
  }, [cartId]);

  const orderDownloadSize = cart ? getOrderBytes(cart) : 0;
  const displaySize = formatBytes(orderDownloadSize, 0);

  if (!loaded) {
    return (
      <View style={style.loadingContainer}>
        <Text style={style.loadMessage}>Loading order... please wait</Text>
        <ActivityIndicator size={40} style={{marginTop: 40}} />
      </View>
    );
  }

  return (
    <View style={style.main}>
      <Text style={style.thankyou}>
        Thank You,{' '}
        {orderData.payment_intent.charges.data[0].billing_details.name}
      </Text>
      <Text style={style.text}>Your purchase was completed successfully</Text>
      <View style={style.details}>
        <View style={style.detailsLeft}>
          <View style={style.infoCell}>
            <Text style={style.boldText}>Event Name</Text>
            <Text style={style.valueText}>
              {ellipsify(orderData.hydratedOrder.eventName, 24)}
            </Text>
          </View>
          <View style={style.infoCell}>
            <Text style={style.boldText}>Packs Purchased</Text>
            {packList.map((name, index) => (
              <Text key={index}>{ellipsify(name, 24)}</Text>
            ))}
          </View>
        </View>
        <View style={style.detailsRight}>
          <View style={style.infoCell}>
            <Text style={style.boldText}>Seller</Text>
            <Text style={style.valueText}>
              {ellipsify(orderData.hydratedOrder.username, 18)}
            </Text>
          </View>
          <View style={style.infoCell}>
            <Text style={style.boldText}>Price</Text>
            <Text style={style.valueText}>
              {centsToDollars(orderData.payment_intent.amount)}
            </Text>
          </View>
          <View style={style.infoCell}>
            <Text style={style.boldText}>Date Of Purchase</Text>
            <Text style={style.valueText}>
              {formatDate(orderData.hydratedOrder.purchased)}
            </Text>
            <Text style={style.valueText}>
              {formatTime(orderData.hydratedOrder.purchased)}
            </Text>
          </View>
          <View style={style.infoCell}>
            <Text style={style.boldText}>Receipt</Text>
            <Pressable
              onPress={() => {
                window.open(
                  `${orderData.payment_intent.charges.data[0].receipt_url}`,
                );
              }}
            >
              <FaIcon
                size={18}
                style={style.receipt}
                icon={faExternalLinkAlt}
              />
            </Pressable>
          </View>
        </View>
      </View>
      <Text style={style.headerText}>Here's a preview</Text>
      <DownloadSummary
        style={style.downloadSummary}
        previewThumbs={previewThumbs}
      />
      {zippedPack?.key ? (
        <EtherButton
          href={zippedPack.url}
          style={style.button}
          spinnerSize={24}
        >
          <Text style={style.buttonText}>Download</Text>
        </EtherButton>
      ) : (
        <View style={style.filesProcessingContainer}>
          <Text style={style.boldText}>
            Your files are currently being prepared. We'll send you an email at{' '}
            {orderData.payment_intent.receipt_email} when your files are ready
            for download. This process can take up to 10 minutes.
          </Text>
        </View>
      )}
      <Text style={style.bottomText}>
        {cart
          ? `Your download will be approximately ${displaySize}`
          : 'loading'}
        <br />
        Files will be hosted here for 30 days.
      </Text>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  boldText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    marginBottom: 5,
    textAlign: 'center',
  },
  bottomText: {
    marginTop: 20,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  button: {
    height: 50,
    width: 230,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  cartID: {
    marginTop: 12,
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  details: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 60,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 15,
    width: '100%',
    maxWidth: 500,
    overflow: 'hidden',
  },
  detailsLeft: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 20,
    overflowY: 'auto',
  },
  detailsRight: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    borderLeftWidth: 2,
    borderColor: theme.FIRST,
    justifyContent: 'center',
  },
  downloadSummary: {
    marginTop: 10,
    marginBottom: 30,
  },
  filesProcessingContainer: {
    padding: 20,
    width: '100%',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderRadius: 15,
    textAlign: 'center',
    borderWidth: 2,
    maxWidth: 800,
  },
  headerText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    color: theme.DARK,
  },
  infoCell: {
    paddingVertical: 20,
    alignItems: 'center',
  },
  loadingContainer: {
    backgroundColor: theme.BGSECOND,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  loadMessage: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
  },
  main: {
    padding: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.subheader,
    color: theme.DARK,
    mobile: {
      fontSize: fontSize.body,
    },
  },
  thankyou: {
    maxWidth: '100%',
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 48,
    color: theme.DARK,
    mobile: {
      fontSize: 24,
    },
  },
  valueText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Regular',
    textAlign: 'center',
    fontSize: fontSize.legal,
    marginBottom: 5,
  },
});
