import etherFetch from '../ether-fetch/etherFetch';

export function downloadItem(endpoint) {
  let downloadName = 'download';

  return etherFetch(endpoint, {timeout: 0})
    .then((response) => {
      if (response.status === '400') {
        throw new Error('Failed to download');
      }

      const contentDisposition = response.headers.get('content-disposition');
      downloadName = contentDisposition
        .replace(/.*filename="/, '')
        .slice(0, -1);
      const contentType = response.headers.get('content-type');
      if (contentType === 'video/quicktime') {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch) {
          const originalFilename = filenameMatch[1];
          downloadName = `${originalFilename.replace(/\.[^.]+$/, '')}.mov`;
        }
      }

      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = downloadName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}
