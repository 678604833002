import {node, object, bool, string} from 'prop-types';
import React from 'react';
import {View, Text, TouchableOpacity, ActivityIndicator} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import FaIcon from './FaIcon';

Button.propTypes = {
  text: string,
  icon: object,
};
function Button({text, icon, status, style: overrides, ...rest}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <TouchableOpacity
      style={[style.menuItem, overrides]}
      activeOpacity={1}
      {...rest}
    >
      {status === STATUS.BUSY ? (
        <ActivityIndicator color={values.DARK} size={20} />
      ) : (
        <FaIcon icon={icon} color={values.DARK} size={20} />
      )}
      <Text style={style.itemText}>{text}</Text>
    </TouchableOpacity>
  );
}

CheckBox.propTypes = {
  text: string,
  icon: object,
};
function CheckBox({text, icon, onToggle, style: overrides, ...rest}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <TouchableOpacity
      style={[style.menuItem, overrides]}
      onPress={onToggle}
      activeOpacity={1}
      {...rest}
    >
      <View style={style.checkBox}>
        <FaIcon icon={icon} color={values.BGSECOND} size={12} />
      </View>
      <Text style={style.itemText}>{text}</Text>
    </TouchableOpacity>
  );
}

FileManagementMenu.propTypes = {
  narrow: bool,
  children: node,
};
export default function FileManagementMenu({
  narrow,
  children,
  style: overrides,
}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View
      style={[
        narrow ? style.fileManagementMenuNarrow : style.fileManagementMenu,
        overrides,
      ]}
    >
      {children}
    </View>
  );
}

FileManagementMenu.CheckBox = CheckBox;
FileManagementMenu.Button = Button;

const getThemedStyles = (theme, fontSize) => ({
  checkBox: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 20,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: theme.DARK,
    backgroundColor: theme.BLACKGROUND,
  },
  fileManagementMenu: {
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: theme.BGSECOND,
    maxHeight: 40,
  },
  fileManagementMenuNarrow: {
    borderRadius: 10,
    backgroundColor: theme.BGSECOND,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  itemText: {
    color: theme.DARK,
    fontSize: fontSize.tiny,
    alignSelf: 'center',
    marginLeft: 5,
    letterSpacing: 0,
    fontFamily: 'NotoSans_Bold',
  },
  menuItem: {
    flexDirection: 'row',
    cursor: 'pointer',
    padding: 7,
  },
});
