import {faCheck} from '@fortawesome/free-solid-svg-icons';
import LogRocket from 'logrocket';
import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import EtherMediaLogo from '../../components/EtherMediaLogoSVG';
import FaIcon from '../../components/FaIcon';
import {useAssets} from '../../context/AssetsContext';
import {useOperator} from '../../context/OperatorContext';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {isProd} from '../../utils/common/funcs';
import etherFetch from '../../utils/ether-fetch/etherFetch';

export default function SignUp({navigation}) {
  const {navigate} = navigation;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tickedPasswordBox, setTickedPasswordBox] = useState(false);
  const {trySocketConnection} = useAssets();
  const [email, setEmail] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const [usernameStatus, setUsernameStatus] = useState(STATUS.IDLE);
  const [passwordStatus, setPasswordStatus] = useState(STATUS.IDLE);
  const [emailStatus, setEmailStatus] = useState(STATUS.IDLE);
  const [accessTokenStatus, setAccessTokenStatus] = useState(STATUS.IDLE);
  const [signUpStatus, setSignUpStatus] = useState(STATUS.IDLE);
  const {style, values} = useTheme(getThemedStyles);
  const {changeOperator} = useOperator();

  useEffect(() => {
    setUsernameStatus(status);
    setPasswordStatus(status);
    setEmailStatus(status);
    setAccessTokenStatus(status);
    setSignUpStatus(status);
  }, [status]);

  function downHandler({key}) {
    if (key === 'Enter') {
      postSignUp();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  //TODO: refactor to API wrapper

  function postSignUp() {
    if (status === STATUS.IDLE) {
      setStatus(STATUS.BUSY);
      etherFetch('/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {username, password, email, accessToken},
      })
        .then((response) => response.json())
        .then((data) => {
          localStorage.username = data.username;
          localStorage.token = data.token;
          localStorage.userID = data.userID;
          trySocketConnection(data.userID);

          if (isProd()) {
            LogRocket.identify(data.userID, {
              name: data.username,
              email: data.identification.email,
            });
          }

          changeOperator(data.username);
          navigate('Event Manager');
        })
        .catch((error) => {
          error.json().then((result) => {
            const conflict = result.msg;
            console.log(conflict);
            if (conflict) {
              setSignUpStatus(STATUS.INVALID);
            }
            if (
              conflict.includes('Username taken') ||
              conflict.includes('Please enter a username')
            ) {
              setUsernameStatus(STATUS.INVALID);
            }
            if (
              conflict.includes('Invalid password') ||
              conflict.includes('Please enter a password')
            ) {
              setPasswordStatus(STATUS.INVALID);
            }
            if (
              conflict.includes('Email taken') ||
              conflict.includes('Invalid email') ||
              conflict.includes('Please enter an email address')
            ) {
              setEmailStatus(STATUS.INVALID);
            }
            if (
              conflict.includes('Invalid access token') ||
              conflict.includes('Please enter an access code')
            ) {
              setAccessTokenStatus(STATUS.INVALID);
            }
          });
        })
        .finally(() => setTimeout(() => setStatus(STATUS.IDLE), 3000));
    }
  }

  return (
    <View style={style.container}>
      <View style={style.logo}>
        <EtherMediaLogo color={values.FIRST} />
      </View>
      <View style={style.inputFields}>
        <Text style={style.title}>Sign Up</Text>
        <EtherInput
          title="Username"
          value={username}
          onChangeText={setUsername}
          status={usernameStatus}
        />
        <EtherInput
          title="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry={!tickedPasswordBox}
          data-private
          status={passwordStatus}
          description="* Password must contain: 8 characters - one lowercase, one uppercase,
          a number and a symbol."
        />
        <View style={style.passwordBox}>
          {tickedPasswordBox ? (
            <TouchableOpacity
              style={style.checkBoxFocus}
              onPress={() => setTickedPasswordBox(false)}
            >
              <FaIcon icon={faCheck} color={values.BGSECOND} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={style.checkBoxBlur}
              onPress={() => setTickedPasswordBox(true)}
            />
          )}
          <Text style={style.passwordBoxText}>Show my password</Text>
        </View>
        <EtherInput
          title="Email"
          value={email}
          onChangeText={setEmail}
          status={emailStatus}
        />
        <EtherInput
          title="Access Code"
          value={accessToken}
          onChangeText={setAccessToken}
          status={accessTokenStatus}
        />
      </View>
      <View>
        <EtherButton
          onPress={postSignUp}
          style={style.signup}
          status={signUpStatus}
        >
          <Text style={style.signupText}>Sign Up</Text>
        </EtherButton>
        <View style={style.logInPrompt}>
          <Text style={style.bottomText}>Already have an account?</Text>
          <TouchableOpacity onPress={() => navigate('Log In')}>
            <Text style={style.loginLink}>Log In.</Text>
          </TouchableOpacity>
        </View>
        <Text style={style.bottomText}>
          By clicking 'Sign Up' you agree to our{' '}
          <TouchableOpacity onPress={() => navigate('Home', {terms: true})}>
            <Text style={style.legalLink}>Terms of Service</Text>
          </TouchableOpacity>
          ,{' '}
          <TouchableOpacity onPress={() => navigate('Home', {privacy: true})}>
            <Text style={style.legalLink}>Privacy Policy</Text>
          </TouchableOpacity>
          and{' '}
          <TouchableOpacity onPress={() => navigate('Home', {eula: true})}>
            <Text style={style.legalLink}>End User License Agreement</Text>
          </TouchableOpacity>
        </Text>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  bottomText: {
    marginTop: 10,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.DARK,
    textAlign: 'center',
  },
  checkBoxBlur: {
    height: 25,
    width: 25,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.FIRST,
    backgroundColor: 'rgba(52, 52, 52, 0.4)',
  },
  checkBoxFocus: {
    height: 25,
    width: 25,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.FIRST,
    backgroundColor: 'rgba(52, 52, 52, 0.4)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 500,
    marginTop: 40,
    marginBottom: 40,
    padding: 20,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    flexDirection: 'column',
    zIndex: 1,
  },
  legalLink: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
  },
  loginLink: {
    marginTop: 10,
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
    marginLeft: 10,
  },
  logInPrompt: {
    marginTop: 10,
    flexDirection: 'row',
    margin: 'auto',
  },
  logo: {
    justifyContent: 'center',
    alignSelf: 'center',
    height: 140,
    width: '90%',
  },
  passwordBox: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 25,
    marginTop: 10,
  },
  passwordBoxText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.tiny,
    color: theme.DARK,
    marginLeft: 5,
  },
  signup: {
    marginTop: 40,
    width: '100%',
  },
  signupText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  title: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.DARK,
    marginBottom: 10,
  },
});
