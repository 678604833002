import {
  faAngleLeft,
  faAngleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {func, bool} from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Modal,
  TouchableOpacity,
  Pressable,
  Text,
  Switch,
  View,
} from 'react-native';

import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {assetType} from '../utils/common/types';
import {imgIsCached, imgOnLoad} from '../utils/web/funcs';

AssetViewer.propTypes = {
  asset: assetType,
  onPressClose: func,
  onPressLeft: func,
  onPressRight: func,
  visible: bool,
};
export default function AssetViewer({
  asset = null,
  onPressClose = () => {},
  onPressLeft = () => {},
  onPressRight = () => {},
  visible,
}) {
  const {style, values} = useTheme(getThemedStyles);
  const isImage = asset?.mime.includes('image');
  const assetRef = useRef(null);
  const [showOriginal, setshowOriginal] = useState(false);

  const [imgLoaded, setImgLoaded] = useState(
    isImage ? imgIsCached(asset?.preview.url) : false,
  );
  useEffect(() => {
    if (!isImage) return () => {};
    const isCurrentCached = imgIsCached(asset?.preview.url);
    setImgLoaded(isCurrentCached);
    if (!isCurrentCached) {
      imgOnLoad(asset?.preview.url, () => {
        setImgLoaded(imgIsCached(asset?.preview.url));
        assetRef.current.classList.add('blur-in');
      });
    }
  });

  useEffect(() => {
    if (!visible) {
      setshowOriginal(false);
    }
  }, [visible]);

  const keyHandler = useCallback(
    (event) => {
      assetRef.current.classList.remove('blur-in');
      switch (event.key) {
        case 'ArrowLeft':
          onPressLeft();
          break;
        case 'ArrowRight':
          onPressRight();
          break;
        case 'Escape':
          onPressClose();
          break;
        default:
      }
    },
    [onPressLeft, onPressRight, onPressClose],
  );

  // Handle keys
  useEffect(() => {
    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, [keyHandler]);

  return (
    <Modal
      style={style.modal}
      visible={visible}
      transparent
      animationType="fade"
    >
      <Pressable style={style.outerArea} onPress={onPressClose} />
      <TouchableOpacity style={style.leftButton} onPress={onPressLeft}>
        <FaIcon icon={faAngleLeft} color={values.BGSECOND} size={40} />
      </TouchableOpacity>
      <TouchableOpacity style={style.rightButton} onPress={onPressRight}>
        <FaIcon icon={faAngleRight} color={values.BGSECOND} size={40} />
      </TouchableOpacity>
      <TouchableOpacity style={style.closeButton} onPress={onPressClose}>
        <FaIcon icon={faTimes} color={values.BGSECOND} size={40} />
      </TouchableOpacity>
      <Text textAlign="center" style={style.name}>
        {asset?.name}
      </Text>
      {visible && isImage ? (
        <img
          src={imgLoaded ? asset?.preview.url : asset?.thumb.url}
          style={{
            ...style.image,
            ...(!imgLoaded ? {filter: 'blur(5px)', width: '100%'} : {}),
          }}
          ref={assetRef}
        />
      ) : null}
      {visible && asset.mime.includes('video') ? (
        <>
          <View style={style.switchContainer}>
            <Text style={style.switchText}>preview</Text>
            <Switch
              style={style.switch}
              trackColor={{false: values.FIRST, true: values.FIRST}}
              thumbColor={values.BGFIRST}
              activeThumbColor={values.BGFIRST}
              onValueChange={() => setshowOriginal(!showOriginal)}
              value={showOriginal}
            />
            <Text style={style.switchText}>original</Text>
          </View>
          <video
            style={style.image}
            poster={asset.thumb.url}
            src={showOriginal ? asset.original.url : asset.preview.url}
            controls
            ref={assetRef}
          />
        </>
      ) : null}
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  image: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    objectFit: 'contain',
    maxHeight: 'calc(100vh - 160px)',
    maxWidth: 'calc(100vw - 160px)',
    filter: 'blur(0px)',
  },
  leftButton: {
    position: 'fixed',
    top: '50%',
    left: 20,
  },
  modal: {
    inset: 0,
    position: 'fixed',
  },
  name: {
    position: 'absolute',
    top: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
    fontSize: fontSize.header,
  },
  outerArea: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    borderColor: theme.DARK,
    backgroundColor: 'rgba(0, 0, 0, 0.98)',
  },
  rightButton: {
    position: 'fixed',
    top: '50%',
    right: 20,
  },
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
  },
  switch: {
    marginHorizontal: 20,
  },
  switchContainer: {
    position: 'absolute',
    bottom: 40,
    height: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchText: {
    fontFamily: 'NotoSans_Regular',
    color: theme.LIGHT,
    fontSize: fontSize.body,
  },
});
