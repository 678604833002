import {
  faMapMarkerAlt,
  faEllipsisV,
  faTrashAlt,
  faRemove,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {string, number, shape} from 'prop-types';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, TouchableOpacity, Image} from 'react-native';

import EtherDialog from './EtherDialog.js';
import FaIcon from './FaIcon';
import SimpleDropdown from './SimpleDropdown.web';
import icon from '../assets/EtherMediaIcon.png';
import EventInfoModal from '../components/EventInfoModal.web';
import UploadImageModal from '../components/UploadImageModal.web';
import {useAssets} from '../context/AssetsContext';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {ellipsify} from '../utils/common/funcs';
import etherFetch from '../utils/ether-fetch/etherFetch';
import showNetworkError from '../utils/ether-fetch/showNetworkError.web';

function uploadFile(url, eventID, file) {
  const formData = new FormData();
  formData.append('uploads', file);
  formData.append('eventID', eventID);
  const {origin} = window.location;
  const uploadRoute = `${origin}${url}`;

  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };
  return axios.post(uploadRoute, formData, config).catch((res) => {
    showNetworkError(res, config);
    throw {msg: res?.msg || 'Unknown upload error'};
  });
}

EventTile.propTypes = {
  event: shape({
    name: string.isRequired,
    location: string.isRequired,
    _id: string.isRequired,
    flyer: shape({
      key: string.isRequired,
      url: string.isRequired,
      expiresAt: number.isRequired,
    }),
    images: number,
    videos: number,
    sample: string,
  }),
};

export default function EventTile({
  event,
  style: overrides,
  onSelectEvent = () => {},
}) {
  const {initialize} = useAssets();
  const eventImage = event.flyer?.url || event.sample?.url || icon;
  const [dropdownVis, setDropdownVis] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [showFlyerModal, setShowFlyerModal] = useState(false);
  const [uploadImageModalVis, setUploadImageModalVis] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(STATUS.IDLE);
  const [mouseHover, setMouseHover] = useState(false);
  const {style, values} = useTheme(getThemedStyles);
  const menuEntries = [
    {
      title: event.flyer != null ? 'Change Flyer' : 'Add Flyer',
      faIcon: faImage,
      callback: () => setUploadImageModalVis(true),
    },
    {
      title: 'Remove Flyer',
      faIcon: faRemove,
      callback: () => setShowFlyerModal(true),
      show: event.flyer != null,
    },
    {
      title: 'Delete Event',
      faIcon: faTrashAlt,
      callback: () => setModalMode('delete'),
    },
  ];
  const removeFlyerControls = [
    {
      text: 'Cancel',
      onPress: () => setShowFlyerModal(false),
    },
    {
      text: 'Remove',
      onPress: () => {
        handleFlyerDelete();
        setShowFlyerModal(false);
      },
      priority: 'primary',
    },
  ];

  function clickHandler() {
    setDropdownVis(false);
  }

  function handleFlyerUpload(files) {
    setUploadStatus(STATUS.BUSY);
    return uploadFile('/packs/event/flyer', event._id, files[0])
      .then(() => {
        toast.success('Flyer upload successful');
        initialize();
      })
      .catch(() => {
        toast.error('Flyer upload failed');
      })
      .finally(() => {
        setUploadImageModalVis(false);
        setDropdownVis(false);
        setUploadStatus(STATUS.IDLE);
      });
  }

  function handleFlyerDelete() {
    return etherFetch('/packs/event/flyer', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        eventID: event._id,
        flyerKey: event.flyer?.key,
      },
    })
      .then(() => {
        toast.success('Flyer removed successfully');
        initialize();
      })
      .catch(() => {
        toast.error('Flyer removal failed');
      })
      .finally(() => {
        setShowFlyerModal(false);
        setDropdownVis(false);
      });
  }

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  return (
    <>
      <UploadImageModal
        title="Current Flyer"
        recommendedSize="a 1:2 height:width ratio (ex: 300 pixels high by 600 pixels wide) and less than 50mb."
        show={uploadImageModalVis}
        onClose={() => setUploadImageModalVis(false)}
        previewImageStyle={style.modalImagePreview}
        onUpload={(files) => handleFlyerUpload(files)}
        preview={event.flyer?.url}
        status={uploadStatus}
      />
      {modalMode ? (
        <EventInfoModal
          event={event}
          mode={modalMode}
          onPressClose={() => setModalMode(false)}
        />
      ) : null}
      <EtherDialog
        header="Remove Event Flyer?"
        subheader="This will remove the flyer from the current event."
        controls={removeFlyerControls}
        onClose={() => setShowFlyerModal(false)}
        show={showFlyerModal}
      />
      <TouchableOpacity
        style={[style.mainContainer, overrides]}
        onPress={onSelectEvent}
      >
        {dropdownVis ? (
          <SimpleDropdown style={style.dropdown} entries={menuEntries} />
        ) : null}
        <View style={style.imageContainer}>
          <Image
            source={eventImage}
            style={
              event.flyer?.url || event.sample?.key
                ? style.image
                : style.imagePlaceholder
            }
          />
        </View>
        <View style={style.menuIconContainer}>
          <TouchableOpacity
            style={style.menuIconButton}
            onPress={() => setDropdownVis(!dropdownVis)}
          >
            <FaIcon
              icon={faEllipsisV}
              size={20}
              color={values.BGSECOND}
              style={style.menuIcon}
            />
          </TouchableOpacity>
        </View>
        <View
          style={style.infoContainer}
          onMouseLeave={() => setMouseHover(false)}
        >
          {mouseHover && event.name.length > 26 ? (
            <Text style={style.eventNameLong}>{event.name}</Text>
          ) : (
            <>
              <View
                style={style.infoTopBar}
                onMouseOver={() => setMouseHover(true)}
              >
                {mouseHover && event.name.length > 26 ? null : (
                  <Text style={style.eventName}>
                    {ellipsify(event.name, 26)}
                  </Text>
                )}
              </View>
              <View style={style.infoMidBar}>
                {event.location ? (
                  <>
                    <FaIcon
                      icon={faMapMarkerAlt}
                      size={14}
                      color={values.FIRST}
                      style={style.infoIcon}
                    />
                    <Text style={style.locationText}>
                      {ellipsify(event.location, 40)}
                    </Text>
                  </>
                ) : null}
              </View>
            </>
          )}
        </View>
      </TouchableOpacity>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  dropdown: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.BGFIRST,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.FIRST,
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 30,
  },
  eventName: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 5,
  },
  eventNameLong: {
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    backgroundColor: theme.BGFIRST,
    padding: 5,
  },
  fileCount: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 5,
  },
  image: {
    flex: 1,
  },
  imageContainer: {
    width: '100%',
    height: '68%',
    backgroundColor: theme.BGSECOND,
  },
  imagePlaceholder: {
    flex: 1,
    resizeMode: 'contain',
  },
  infoBotBar: {
    height: '33%',
    borderColor: theme.BGFIRST,
    borderTopWidth: 1,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderTopWidth: 2,
    flex: 1,
    zIndex: 1,
  },
  infoIcon: {
    marginLeft: 5,
  },
  infoMidBar: {
    height: '33%',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  infoTopBar: {
    flexDirection: 'row',
    height: '33%',
    width: '100%',
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationText: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 5,
  },
  mainContainer: {
    borderWidth: 2,
    borderColor: theme.FIRST,
    height: 220,
    width: 300,
    zIndex: -1,
  },
  menuIcon: {
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 1,
    textShadowColor: '#444444',
  },
  menuIconButton: {
    padding: 5,
  },
  menuIconContainer: {
    alignSelf: 'flex-end',
    flex: 1,
    position: 'absolute',
    backgroundColor: theme.SECOND,
  },
  modalImagePreview: {
    resizeMode: 'center',
  },
});
