/*eslint import/no-unresolved: [2, { ignore: ['swiper/*'] }]*/

import {
  faCheckCircle,
  faCircleExclamation,
  faCookieBite,
  faTabletScreenButton,
} from '@fortawesome/free-solid-svg-icons';
import {useNavigation} from '@react-navigation/native';
import React, {useState} from 'react';
import {Text, View, Switch} from 'react-native';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {useIsMobile} from '../../utils/common/hooks';

function Item({icon, text, color}) {
  const {style} = useTheme(useThemedStyles);

  return (
    <View style={style.item}>
      <FaIcon icon={icon} size={26} color={color} />
      <Text style={style.itemText}>{text}</Text>
    </View>
  );
}

export function Pricing() {
  const {style, values} = useTheme(useThemedStyles);
  const {navigate} = useNavigation();
  const [isYearly, setIsYearly] = useState(false);
  const {isMobile} = useIsMobile();

  function ToggleSwitch() {
    setIsYearly(!isYearly);
  }

  return (
    <>
      <View style={style.pricing}>
        <Text style={style.rolloutTitle}>Available Plans</Text>
        <Switch
          trackColor={{false: '#767577', true: values.GREEN}}
          thumbColor={values.BGFIRST}
          activeThumbColor={values.BGFIRST}
          onValueChange={ToggleSwitch}
          value={isYearly}
          style={{marginBottom: 15}}
        />
        <Text
          style={[style.customText, {color: values.BGFIRST, marginBottom: 40}]}
        >
          {isYearly ? 'yearly plans' : 'monthly plans'}
        </Text>
        {!isMobile ? (
          <View style={style.cards}>
            <View style={style.card}>
              <View style={style.cardHeader}>
                <Text style={style.priceNumber}>
                  {isYearly ? '$79.99' : '$99.99'}
                </Text>
                <Text style={style.pricePer}>/month</Text>
              </View>
              <Text style={style.billType}>
                {isYearly ? 'annually' : 'monthly'}
              </Text>
              <Text style={style.header}>Standard</Text>
              <Text style={style.tagline}>
                The ideal package for most.
                <br />
                Full access to the EtherMedia platform.
              </Text>
              <View style={style.separator} />
              <View style={style.cardBottom}>
                <Item
                  icon={faCircleExclamation}
                  color={values.FIRST}
                  text="15% Revenue Share"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Digital Asset Delivery"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Cloud Management"
                />
                <Item icon={faCheckCircle} color="green" text="250GB Storage" />
                <Item
                  icon={faTabletScreenButton}
                  color={values.FIRST}
                  text="2 Active Kiosks Licenses"
                />
                {isYearly ? (
                  <Item
                    icon={faCookieBite}
                    color={values.FIRST}
                    text="Free Cookie (if possible)"
                  />
                ) : null}
              </View>
            </View>
            <View style={style.card}>
              <View style={style.cardHeader}>
                <Text style={style.priceNumber}>
                  {isYearly ? '$129.99' : '$149.99'}
                </Text>
                <Text style={style.pricePer}>/month</Text>
              </View>
              <Text style={style.billType}>
                {isYearly ? 'annually' : 'monthly'}
              </Text>
              <Text style={style.header}>Pro</Text>
              <Text style={style.tagline}>
                For those with large ideas and custom needs.
              </Text>
              <View style={style.separator} />
              <View style={style.cardBottom}>
                <Item
                  icon={faCircleExclamation}
                  color={values.FIRST}
                  text="15% Revenue Share"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Digital Asset Delivery"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Cloud Management"
                />
                <Item icon={faCheckCircle} color="green" text="500GB Storage" />
                <Item
                  icon={faTabletScreenButton}
                  color={values.FIRST}
                  text="6 Active Kiosks Licenses"
                />
                {isYearly ? (
                  <Item
                    icon={faCookieBite}
                    color={values.FIRST}
                    text="Free Cookie (if possible)"
                  />
                ) : null}
              </View>
            </View>
          </View>
        ) : (
          <Swiper
            slidesPerView="auto"
            centeredSlides
            rewind
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="homeSwiper"
          >
            <SwiperSlide style={style.card}>
              <View style={style.cardHeader}>
                <Text style={style.priceNumber}>
                  {isYearly ? '$79.99' : '$99.99'}
                </Text>
                <Text style={style.pricePer}>/month</Text>
              </View>
              <Text style={style.billType}>
                {isYearly ? 'annually' : 'monthly'}
              </Text>
              <Text style={style.header}>Standard</Text>
              <Text style={style.tagline}>
                The ideal package for most.
                <br />
                Full access to the EtherMedia platform.
              </Text>
              <View style={style.separator} />
              <View style={style.cardBottom}>
                <Item
                  icon={faCircleExclamation}
                  color={values.FIRST}
                  text="15% Revenue Share"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Digital Asset Delivery"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Cloud Management"
                />
                <Item icon={faCheckCircle} color="green" text="250GB Storage" />
                <Item
                  icon={faTabletScreenButton}
                  color={values.FIRST}
                  text="2 Active Kiosks Licenses"
                />
                {isYearly ? (
                  <Item
                    icon={faCookieBite}
                    color="green"
                    text="Free Cookie (if possible)"
                  />
                ) : null}
              </View>
            </SwiperSlide>
            <SwiperSlide style={style.card}>
              <View style={style.cardHeader}>
                <Text style={style.priceNumber}>
                  {isYearly ? '$129.99' : '$149.99'}
                </Text>
                <Text style={style.pricePer}>/month</Text>
              </View>
              <Text style={style.billType}>
                {isYearly ? 'annually' : 'monthly'}
              </Text>
              <Text style={style.header}>Pro</Text>
              <Text style={style.tagline}>
                For those with large ideas and custom needs.
              </Text>
              <View style={style.separator} />
              <View style={style.cardBottom}>
                <Item
                  icon={faCircleExclamation}
                  color={values.FIRST}
                  text="15% Revenue Share"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Digital Asset Delivery"
                />
                <Item
                  icon={faCheckCircle}
                  color="green"
                  text="Cloud Management"
                />
                <Item icon={faCheckCircle} color="green" text="500GB Storage" />
                <Item
                  icon={faTabletScreenButton}
                  color={values.FIRST}
                  text="6 Active Kiosks Licenses"
                />
                {isYearly ? (
                  <Item
                    icon={faCookieBite}
                    color="green"
                    text="Free Cookie (if possible)"
                  />
                ) : null}
              </View>
            </SwiperSlide>
          </Swiper>
        )}
      </View>
      <View>
        <Text style={style.bottomText}>
          Closed beta in progress!
          <br />
          Get in touch with us for exclusive access
        </Text>
        <EtherButton
          style={style.contactButton}
          onPress={() => navigate('Contact')}
        >
          <Text style={style.buttonText}>Contact Us</Text>
        </EtherButton>
      </View>
    </>
  );
}

const useThemedStyles = (theme, fontSize) => ({
  bottomText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 16,
    color: theme.LIGHT,
    marginBottom: 30,
    textAlign: 'center',
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    mobile: {
      fontSize: 18,
    },
  },
  billType: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 10,
    color: '#7a7a7a',
    paddingVertical: 5,
    marginBottom: 20,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.BGFIRST,
    paddingVertical: 20,
    padding: 30,
    borderRadius: 10,
    width: 300,
    marginBottom: 40,
    marginHorizontal: 20,
    mobile: {
      marginHorizontal: null,
      width: 200,
      maxWidth: 200,
    },
  },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cards: {
    flexDirection: 'row',
  },
  contactButton: {
    alignSelf: 'center',
    height: 60,
    width: 300,
    borderRadius: 10,
    backgroundColor: theme.YELLOW,
    borderColor: theme.FIRST,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 60,
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    marginBottom: 5,
  },
  itemText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    marginLeft: 10,
  },
  customText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    textAlign: 'center',
    paddingVertical: 10,
  },
  priceNumber: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 36,
    color: theme.DARK,
    marginBottom: -8,
  },
  pricePer: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: '#7a7a7a',
  },
  pricing: {
    alignItems: 'center',
    marginTop: 20,
  },
  cardBottom: {
    flex: 1,
    justifyContent: 'center ',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  rolloutTitle: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.starHeader,
    color: theme.LIGHT,
    marginBottom: 40,
    mobile: {
      marginBottom: 10,
      fontSize: fontSize.planetHeader,
      textAlign: 'center',
    },
  },
  separator: {
    backgroundColor: theme.DISABLED,
    width: '100%',
    height: 2,
    marginVertical: 40,
  },
  tagline: {
    width: 240,
    fontFamily: 'NotoSans_Regular',
    fontSize: 13,
    color: '#7a7a7a',
    mobile: {
      width: 200,
    },
  },
});
