import React from 'react';
import {View} from 'react-native';

import {useTheme} from '../context/ThemeContext';

export function Incline({width}) {
  const {style} = useTheme(getThemedStyles);
  return <View style={[style.incline, {borderLeftWidth: width}]} />;
}

export function InclineFlip({width}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <View
      style={[style.incline, style.inclineFlip, {borderLeftWidth: width}]}
    />
  );
}

export function Decline({width}) {
  const {style} = useTheme(getThemedStyles);
  return <View style={[style.decline, {borderRightWidth: width}]} />;
}

export function DeclineFlip({width}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <View
      style={[style.decline, style.declineFlip, {borderRightWidth: width}]}
    />
  );
}

const getThemedStyles = (theme, fontSize) => ({
  decline: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 1920,
    borderBottomWidth: 70,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.BGFIRST,
  },
  declineFlip: {
    transform: [{rotate: '180deg'}],
  },
  incline: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 1920,
    borderBottomWidth: 70,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.BGFIRST,
  },
  inclineFlip: {
    transform: [{rotate: '180deg'}],
  },
});
