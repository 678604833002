import AsyncStorage from '@react-native-async-storage/async-storage';
import {Platform} from 'react-native';

import {PLATFORMS} from './constants';

/**
 * @param {string} string - The string to truncate with ellipses
 * @param {number} max - Max character count to return
 * @return {string} Truncated string if it's longer than max
 */

export function ellipsify(string, max) {
  if (!string?.length || string.length <= max) return string;

  const start = string.substring(0, max * (1 / 3));
  const remainingChars = max - 3 - start.length;
  const end = string.slice(-remainingChars);
  return `${start}...${end}`;
}

const ENVS = {
  DEV: 'development',
  STAGE: 'staging',
  PROD: 'production',
};

export function isDev() {
  if (Platform.OS === PLATFORMS.web) {
    return window.location.hostname === 'localhost';
  }

  return process.env.NODE_ENV === ENVS.DEV;
}
export function isStage() {
  if (Platform.OS === PLATFORMS.web) {
    return window.location.hostname === 'staging.ethermedia.app';
  }

  return process.env.NODE_ENV === ENVS.STAGE;
}
export function isProd() {
  if (Platform.OS === PLATFORMS.web) {
    return window.location.hostname === 'ethermedia.app';
  }

  return process.env.NODE_ENV === ENVS.PROD;
}

export function createKey(len = 10) {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

export async function getToken() {
  if (Platform.OS === PLATFORMS.web) {
    return localStorage.token || null;
  }

  try {
    return (await AsyncStorage.getItem('token')) || null;
  } catch (e) {
    console.error(e);
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}

export function conditionalS(number) {
  if (number > 1) {
    return 's';
  }
  return '';
}

/**
 *
 * @param {number} cents / the number to turn into Dollar and Cents
 * @returns {string}
 */
export function centsToDollars(cents) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100);
}

/**
 *
 * @param {string} date / turns 'August 19, 1975 23:15:30 GMT+00:00' into '11:15 PM'
 */
export function formatTime(date) {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(date).toLocaleTimeString('en-US', options);
}

/**
 *
 * @param {string} date / turns 'August 19, 1975 23:15:30 GMT+00:00' into 'Fri, August 9, 1975'
 */
export function formatDate(date) {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(date).toLocaleString('en-US', options);
}

/**
 * Use this if a value must be an array
 *
 * @param {any} value - value to be returned as array
 * @returns value, or value within an array
 *
 * e.g. 'foo' -> ['foo']
 * e.g. ['foo', 'bar'] -> ['foo', 'bar']
 */
export function asArray(value) {
  const result = Array.isArray(value) ? value : [value];
  return result;
}

/**
 *
 * @param {number} duration - duration in seconds
 * @returns duration in format hh:mm:ss
 */
export function secondsToTimestamp(duration) {
  let seconds = Math.floor(duration % 60);
  let minutes = Math.floor((duration / 60) % 60);
  let hours = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return hours + ':' + minutes + ':' + seconds;
}
