/**This utility can be used to cleanse and verify text inputs from the user.
 * It does a light santizing and either returns false for bad input, or
 * returns cleansed and verified text. Don't rely on this utility as a
 * security measure. Front end code can be bypassed by the user so this can
 * be easily ignored. Use this primarily for styling and user flow purposes. */

import {
  AsYouType,
  parsePhoneNumberFromString,
  isValidNumber,
} from 'libphonenumber-js';

function scrub(input) {
  return input.toString();
}

export function validateName(name) {
  name = scrub(name);
  if (!name) {
    return false;
  }
  return name;
}

export function validatePhone(number) {
  number = scrub(number);
  if (!number) {
    return false;
  }
  if (number.includes(!'?[0-9]')) {
    number = number.replace('(', '');
    number = number.replace(')', '');
  }
  const formattedPhone = new AsYouType('US').input(number);
  const parsedNumber = parsePhoneNumberFromString(`+1 ${formattedPhone}`);
  var valid = false;
  if (parsedNumber) {
    valid = isValidNumber(parsedNumber.number);
  }
  if (valid) {
    return parsedNumber.number;
  } else {
    return false;
  }
}

export function validateEmail(email) {
  email = scrub(email);
  if (!email) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
  email = re.test(email.toLowerCase());
  return email;
}

export function validateMessage(message) {
  message = scrub(message);
  if (message.length < 30) {
    return false;
  }
  return message;
}
