import {string, shape, number} from 'prop-types';

export const assetType = shape({
  bytes: number,
  height: number,
  width: number,
  mime: string,
  name: string,
  thumb: shape({
    key: string.isRequired,
    url: string.isRequired,
    expiresAt: number.isRequired,
  }),
  original: shape({
    key: string,
    url: string,
    expiresAt: number,
  }),
  preview: shape({
    key: string,
    url: string,
    expiresAt: number,
  }),
  progress: string,
  createdAt: number,
  duration: number,
  _id: string,
  __v: number,
});
