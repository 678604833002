import {AsYouType} from 'libphonenumber-js';
import {func, bool, string, shape} from 'prop-types';
import React, {useState} from 'react';
import {View, Text} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import etherFetch from '../utils/ether-fetch/etherFetch';
import EtherButton from './EtherButton';
import EtherInput from './EtherInput';

UserInformation.propTypes = {
  onSuccess: func,
  onError: func,
  userInfo: shape({
    fname: string,
    lname: string,
    email: string,
    phone: string,
    orgname: string,
    complete: bool,
  }),
};
export function UserInformation({
  userInfo,
  onSuccess,
  onError,
  editableEmail = true,
}) {
  const {style} = useTheme(getThemedStyles);
  const [firstName, setFirstName] = useState(userInfo?.fname || '');
  const [lastName, setLastName] = useState(userInfo?.lname || '');
  const [phone, setPhone] = useState(
    userInfo?.phone ? new AsYouType('US').input(userInfo?.phone) : '',
  );
  const [email, setEmail] = useState(userInfo?.email || '');
  const [orgName, setOrgName] = useState(userInfo?.orgname || '');

  function updateProfile() {
    return etherFetch('/config/user', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        firstName,
        lastName,
        email,
        phone,
        orgName,
      },
    });
  }

  function onSubmit() {
    updateProfile()
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((parsed) => {
        onSuccess(parsed);
      })
      .catch(onError);
  }

  function processPhone(newPhone) {
    /*fix libphonenumber-js bug where backspacing a phone field
     *with string '(555)' would flash '(555' then go back to '(555)'
     */
    const removeParenthesis =
      phone.slice(-1) === ')' && newPhone.length < phone.length;
    newPhone = removeParenthesis ? newPhone.slice(0, 3) : newPhone;
    const formattedPhone = new AsYouType('US').input(newPhone);
    setPhone(formattedPhone);
  }

  return (
    <View>
      <EtherInput
        title="First Name"
        placeholder="ex: John"
        value={firstName}
        onChangeText={setFirstName}
      />

      <EtherInput
        title="Last Name"
        placeholder="ex: Doe"
        value={lastName}
        onChangeText={setLastName}
      />

      <EtherInput
        title="Phone"
        placeholder="ex: (555) 555-5555"
        maxLength={14}
        value={phone}
        onChangeText={processPhone}
      />
      <EtherInput
        title="Email"
        placeholder="ex: address@email.com"
        value={email}
        onChangeText={setEmail}
        editable={editableEmail}
      />
      <EtherInput
        title="Organization Name"
        placeholder="ex: company name"
        value={orgName}
        onChangeText={setOrgName}
      />
      <EtherButton onPress={onSubmit} style={style.updateButton}>
        <Text style={style.buttonText}>Submit</Text>
      </EtherButton>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    borderColor: theme.DARK,
  },
  updateButton: {
    alignSelf: 'center',
    width: '80%',
    marginTop: 40,
    padding: 20,
  },
});
