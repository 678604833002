import {
  faIcons,
  faSignOutAlt,
  faStamp,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {View, Text} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import {StripeQuarter} from './StripeQuarter';
import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import FaIcon from '../../components/FaIcon';
import UploadImageModal from '../../components/UploadImageModal.web';
import {UserInformation} from '../../components/UserInformation';
import {useOperator} from '../../context/OperatorContext';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {formatPhoneNumber, isProd} from '../../utils/common/funcs';
import {useIsMobile} from '../../utils/common/hooks';
import {logOut} from '../../utils/common/login';
import {getStripeAccountLink} from '../../utils/common/orders';
import etherFetch from '../../utils/ether-fetch/etherFetch';
import showNetworkError from '../../utils/ether-fetch/showNetworkError';

function uploadFile(url, file) {
  const formData = new FormData();
  formData.append('uploads', file);
  const {origin} = window.location;
  const uploadRoute = `${origin}${url}`;

  const config = {
    headers: {
      authorization: localStorage.token,
    },
  };
  return axios.post(uploadRoute, formData, config);
}

export default function Profile({navigation: {navigate}}) {
  const {style, values} = useTheme(getThemedStyles);
  const {isMobile} = useIsMobile();
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const [watermark, setWatermark] = useState('');
  const [username, setUsername] = useState('');
  const [logo, setLogo] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const [showWatermarkModal, setShowWatermarkModal] = useState(false);
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [stripeAccountUrl, setStripeAccountUrl] = useState(null);
  const [stripeId, setStripeId] = useState(false);
  const [price, setPrice] = useState(100);
  const [displayPrice, setDisplayPrice] = useState('--');
  const [displayData, setDisplayData] = useState({
    fname,
    lname,
    phone,
    email,
    orgName,
    username,
  });
  const {clearOperator} = useOperator();

  async function loadUser() {
    return etherFetch('/auth/me')
      .then((r) => r.json())
      .then((user) => {
        const {customization, identification} = user;
        setWatermark(customization.preview);
        // thwart browser caching
        setLogo(`${customization.logo}?${(Date.now() / 1000).toFixed()}`);
        setPrice(customization.price);
        const inDollars = (customization.price / 100).toFixed(2);
        setDisplayPrice(inDollars);
        setFName(identification.fname || '');
        setLName(identification.lname || '');
        setEmail(identification.email || '');
        setPhone(identification.phone || '');
        setOrgName(identification.orgname || '');
        setStripeId(user.stripeId || '');
        setUsername(user.username || '');

        setDisplayData({
          fname: identification.fname,
          lname: identification.lname,
          phone: identification.phone,
          email: identification.email,
          orgname: identification.orgname,
          username: user.username,
        });
      });
  }

  function changePrice(inputPrice) {
    const cleanPrice = inputPrice.replace(/\D/g, '');
    const fixedPrice = `${cleanPrice.slice(
      0,
      cleanPrice.length - 2,
    )}.${cleanPrice.slice(-2)}`;
    setPrice(parseInt(cleanPrice, 10));
    setDisplayPrice(fixedPrice);
  }

  function onSubmitPrice() {
    if (status === STATUS.IDLE) {
      etherFetch('/config/price', {
        method: 'PUT',
        body: {price},
      })
        .then((res) => res.json())
        .then(() => {
          setStatus(STATUS.VALID);
          toast.success(`Price changed to $${displayPrice}`);
        })
        .catch(() => setStatus(STATUS.INVALID))
        .finally(() => setTimeout(() => setStatus(STATUS.IDLE), 3000));
    }
  }

  function handleLogOut() {
    logOut().then(clearOperator).catch(console.error);
    navigate('Log In');
  }

  function onProfileUpdate(profileRes) {
    toast.success(profileRes?.msg);
    loadUser().catch(() => console.error('Failed to load user'));
    setTimeout(() => setStatus(STATUS.IDLE), 3000);
  }

  function onProfileError() {
    setStatus(STATUS.INVALID);
    setTimeout(() => setStatus(STATUS.IDLE), 3000);
  }

  useEffect(() => {
    if (!stripeId) return;
    getStripeAccountLink().then(({url}) => {
      setStripeAccountUrl(url);
    });
  }, [stripeId]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <SkeletonTheme
      baseColor={values.BGFIRST}
      highlightColor={values.SECOND}
      width={180}
    >
      <UploadImageModal
        title="Watermark preview"
        recommendedSize="1024x1024px"
        show={showWatermarkModal}
        onClose={() => setShowWatermarkModal(false)}
        onUpload={async (files) => {
          try {
            await uploadFile('/config/watermark', files[0]);
            await loadUser();
            toast.success('Watermark upload successful');
          } catch (err) {
            showNetworkError(err);
          }
        }}
        preview={watermark}
      />
      <UploadImageModal
        title="Logo upload"
        recommendedSize="1024x1024px"
        show={showLogoModal}
        onClose={() => setShowLogoModal(false)}
        onUpload={async (files) => {
          try {
            await uploadFile('/config/logo', files[0]);
            await loadUser();
            toast.success('Logo upload successful');
          } catch (err) {
            showNetworkError(err);
          }
        }}
        preview={logo}
      />
      <View style={style.main}>
        <View style={style.top}>
          <View style={style.profile}>
            <View style={style.profileName}>
              <Text style={style.profileText}>
                {displayData.email && displayData.lname ? (
                  displayData.fname + ' ' + displayData.lname
                ) : (
                  <Skeleton width={240} />
                )}
              </Text>
              <Text style={style.profileSubText}>
                {displayData.email ? displayData.email : <Skeleton />}
              </Text>
              <Text style={style.profileSubText}>
                {displayData.phone ? (
                  `+1 ${formatPhoneNumber(displayData.phone)}`
                ) : (
                  <Skeleton />
                )}
              </Text>
              <Text style={style.profileSubTextOrg}>
                {displayData.username ? (
                  `Username: ${displayData.username}`
                ) : (
                  <Skeleton />
                )}
              </Text>
              <Text style={style.profileSubTextOrg}>
                {displayData.orgname ? (
                  `Company: ${displayData.orgname}`
                ) : (
                  <Skeleton />
                )}
              </Text>
            </View>
          </View>
          {isMobile ? null : (
            <EtherButton style={style.options} onPress={handleLogOut}>
              <FaIcon icon={faSignOutAlt} color={values.SECOND} size={60} />
              <Text style={style.optionsText}>Log Out</Text>
            </EtherButton>
          )}
        </View>
        <View style={style.bottom}>
          <View style={style.info}>
            {displayData.fname ? (
              <UserInformation
                userInfo={displayData}
                onSuccess={onProfileUpdate}
                onError={onProfileError}
              />
            ) : (
              <ActivityIndicator size={64} color={values.FIRST} />
            )}
          </View>
          <View>
            <View style={style.finances}>
              <View style={style.half}>
                {stripeAccountUrl || !stripeId ? (
                  <StripeQuarter
                    stripeId={stripeId}
                    stripeAccountUrl={stripeAccountUrl}
                    status={STATUS}
                  />
                ) : (
                  <ActivityIndicator
                    style={style.quarter}
                    size={42}
                    color={values.FIRST}
                  />
                )}

                <View style={style.quarter}>
                  <View>
                    <EtherInput
                      style={style.input}
                      inputStyle={{textAlign: 'right'}}
                      value={displayPrice}
                      title="Price per pack"
                      onChangeText={(newPrice) => changePrice(newPrice)}
                      onSubmitEditing={onSubmitPrice}
                    />
                    <Text style={style.money}>$</Text>
                  </View>
                  <EtherButton
                    style={style.apply}
                    value={price}
                    onPress={onSubmitPrice}
                  >
                    <Text style={style.buttonText}>Apply</Text>
                  </EtherButton>
                </View>
              </View>
            </View>
            <View style={style.optionsContainer}>
              <EtherButton
                style={style.options}
                onPress={() => setShowWatermarkModal(true)}
              >
                <FaIcon icon={faStamp} color={values.SECOND} size={60} />
                <Text style={style.optionsText}>Watermark</Text>
              </EtherButton>
              {!isProd() ? (
                <EtherButton
                  style={style.options}
                  onPress={() => setShowLogoModal(true)}
                >
                  <FaIcon icon={faIcons} color={values.SECOND} size={60} />
                  <Text style={style.optionsText}>Logo</Text>
                </EtherButton>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </SkeletonTheme>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  apply: {
    marginTop: 15,
    height: 30,
    width: 60,
    borderRadius: 5,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.FIRST,
    backgroundColor: theme.SECOND,
  },
  bottom: {
    flexDirection: 'row',
    marginTop: 40,
    mobile: {
      flexDirection: 'column',
    },
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  finances: {
    padding: 20,
    width: 400,
    height: 360,
    marginLeft: 40,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    flexDirection: 'row',
    marginBottom: 40,
    mobile: {
      width: '100%',
      marginLeft: 0,
    },
  },
  half: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  info: {
    width: 400,
    padding: 20,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    mobile: {
      width: '100%',
      marginBottom: 40,
    },
    justifyContent: 'center',
  },
  input: {
    width: 100,
    height: 80,
  },
  main: {
    alignSelf: 'center',
    flexDirection: 'column',
    padding: 40,
  },
  money: {
    position: 'absolute',
    left: 12,
    bottom: 12,
    fontFamily: 'NotoSans_Bold',
  },
  options: {
    width: 180,
    height: 180,
    padding: 20,
    marginLeft: 40,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    mobile: {
      marginLeft: null,
    },
  },
  optionsContainer: {
    flexDirection: 'row',
    width: 400,
    justifyContent: 'space-between',
  },
  optionsText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    marginTop: 20,
    color: theme.DARK,
  },
  profile: {
    flexDirection: 'row',
    width: '100%',
    maxWidth: 620,
    height: 180,
    padding: 20,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    mobile: {
      maxWidth: null,
    },
  },
  profileName: {
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  profileSubText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  profileSubTextOrg: {
    alignSelf: 'flex-end',
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  profileText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
  },
  quarter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
  },
  top: {
    flexDirection: 'row',
    mobile: {
      flexDirection: null,
    },
  },
});
