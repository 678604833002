import etherFetch from '../ether-fetch/etherFetch';
import {ellipsify} from './funcs';

const BASE_ROUTE = '/packs';

/**
 * Returns a promise for a list of events
 */
export function findEvents() {
  return etherFetch(`/packs`).then((res) => res.json());
}

/**
 * Returns a promise for a list packs
 * @param {String} event - Event name
 */
export function findPacks(event) {
  return etherFetch(`/packs/${event}`).then((res) => res.json());
}
/**
 * Returns a promise for a list of assets within a pack and the price
 * @param {String} event - Event name
 * @param {String} packName - Pack name
 */
export function getPack(event, packName, config) {
  return new Promise((resolve, reject) => {
    if (!event || !packName) {
      reject(new Error('Missing required param'));
    }

    resolve(
      etherFetch(`/packs/${event}/${packName}`, config).then((res) =>
        res.json(),
      ),
    );
  });
}

/**
 * Returns a promise, when it resolves the pack has been deleted
 * @param {String} event - Event name
 * @param {String} packName - Pack name
 */
export function delPack(event, packName) {
  const toDelete = [event, packName].join('/');
  const route = [BASE_ROUTE, toDelete].join('/');

  return etherFetch(route, {method: 'DELETE', timeout: 0}).then((res) =>
    res.json(),
  );
}

/**
 * Returns a promise, when it resolves the asset has been deleted
 * @param {String} event - Event name
 * @param {String} packName - Pack name
 * @param {String} asset - Asset name, or names delimited by commas,like,this
 */
export function deleteAsset(event, packName, asset) {
  const route = [BASE_ROUTE, event, packName, asset].join('/');
  return etherFetch(route, {method: 'DELETE', timeout: 0}).then((res) =>
    res.json(),
  );
}

/**
 * Returns a promise, when it resolved the asset has been renamed to the newName
 * @param {String} event - Event name
 * @param {String} packName - Pack name
 * @param {String} asset - Asset name
 * @param {String} newName - String to rename the asset to
 */
export function renameAsset(event, pack, asset, newName) {
  const route = [BASE_ROUTE, 'rename', 'asset', event, pack, asset].join('/');
  return etherFetch(route, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {newName},
    timeout: 30,
  }).then((res) => res.json());
}

/**
 * Returns a promise, when it resolves the pack has been created
 * @param {String} event - Event name
 * @param {String} packName - Pack name
 */
export function addPack(event, packName) {
  const toAdd = [event, packName].join('/');
  const route = [BASE_ROUTE, toAdd].join('/');

  return new Promise((resolve, reject) => {
    if (!packName) {
      reject(new Error('No pack name found'));
    }

    findPacks(event)
      .then((packList) => {
        if (packList.includes((item) => item.name === packName)) {
          reject(new Error('Duplicate'));
        }
      })
      .catch((err) => reject(err))
      .then(() =>
        etherFetch(route, {method: 'POST'})
          .then((res) => res.json())
          .then(resolve)
          .catch(reject),
      );
  });
}

/**
 * Returns a promise, when it resolves the event has been deleted
 * @param {String} event - Event name
 */
export function delEvent(event) {
  const route = [BASE_ROUTE, event].join('/');
  return etherFetch(route, {method: 'DELETE', timeout: 0}).then((res) =>
    res.json(),
  );
}

/**
 * Returns a promise, when it resolves  the event has been created
 * @param {String} name - Event name
 * @param {String} location - Event location
 */
export function addEvent(name, location) {
  return etherFetch([BASE_ROUTE, 'event'].join('/'), {
    method: 'POST',
    body: {
      name,
      location,
    },
  }).then((req) => req.json());
}

/**
 * Summarizes pack names to a shortened string
 *
 * @param {Object} orderData - hydrated order from api
 * @returns {string} / eg: 'Pack One, Pack Two... (+1 Items)'
 */
export function getPacksSummary(orderData, maxPacks = 2) {
  let result = '';

  const names = orderData.packNames;
  const packCount = names.length;
  if (packCount > maxPacks) {
    const firstNames = names.slice(0, maxPacks);
    const packsRemaining = names.length - maxPacks;
    result = `${firstNames}... (+${packsRemaining} Items)`;
  } else {
    result = names.join(', ');
  }
  return ellipsify(result, 28);
}
