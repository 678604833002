import {string, func, shape, object} from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {View, TouchableOpacity, Text} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import FaIcon from './FaIcon';

const DropdownContext = React.createContext();

MenuItem.propTypes = {
  entry: shape({
    title: string.isRequired,
    faIcon: object.isRequired,
    callback: func.isRequired,
  }).isRequired,
  onPress: func,
};
function MenuItem({entry, onPress = () => {}}) {
  const [mouseHover, setMouseHover] = useState(false);

  function onPressItem(e) {
    onPress();
    entry.callback(e);
  }
  const {style, values} = useTheme(getThemedStyles);

  return (
    <TouchableOpacity
      style={[style.menuItem, mouseHover ? style.highlight : null]}
      onMouseOver={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      onPress={(e) => onPressItem(e)}
    >
      <FaIcon
        icon={entry.faIcon}
        color={mouseHover ? values.BGSECOND : values.DARK}
      />
      <Text style={[style.menuItemTitle, mouseHover ? style.highlight : null]}>
        {entry.title}
      </Text>
    </TouchableOpacity>
  );
}

function DropdownProvider({children}) {
  const containerRef = useRef(null);
  const [menuEntries, setMenuEntries] = useState([]);
  const [coords, setCoords] = useState({x: 0, y: 0});
  const [showMenu, setShowMenu] = useState(false);

  /**
   *
   * @param {entry[]} entries - A list of menu entries to use
   * @param {Number} x - X offset from left to spawn at
   * @param {Number} y - Y offset from top to spawn at
   */
  function spawnMenu(entries, x, y) {
    if (entries.length === 0) return;
    setMenuEntries(entries);
    setCoords({x, y});
    setShowMenu(true);
  }

  useEffect(() => {
    function onBlurMenu(e) {
      if (!showMenu) return;

      const container = containerRef.current;
      const clickedEl = e.target;

      // Hide if we clicked outside this component
      if (!container.contains(clickedEl)) setShowMenu(false);
    }

    document.body.addEventListener('click', onBlurMenu);
    return () => {
      document.body.removeEventListener('click', onBlurMenu);
    };
  }, [showMenu]);

  const {style} = useTheme(getThemedStyles);

  return (
    <DropdownContext.Provider value={{spawnMenu}}>
      {!!showMenu && (
        <View
          style={[style.container, {left: coords.x, top: coords.y}]}
          ref={containerRef}
        >
          {menuEntries.map((item) => (
            <MenuItem
              key={item.title}
              onPress={() => setShowMenu(false)}
              entry={item}
            />
          ))}
        </View>
      )}
      {children}
    </DropdownContext.Provider>
  );
}

export default DropdownContext;
export {DropdownProvider};

const getThemedStyles = (theme) => ({
  container: {
    flexDirection: 'column',
    borderColor: theme.DARK,
    borderWidth: 1,
    backgroundColor: theme.BGSECOND,
    position: 'absolute',
    zIndex: 1,
    transform: 'translateY(-100%)',
    borderRadius: 5,
  },
  highlight: {
    backgroundColor: theme.SECOND,
    color: theme.BGSECOND,
  },
  menuItem: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuItemTitle: {
    fontFamily: 'NotoSans_Regular',
    marginLeft: 10,
  },
});
