import {number, string, node} from 'prop-types';
import {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

Portal.propTypes = {
  children: node,
  className: string.isRequired,
  zIndex: number,
  //zIndex defaulted to Modal zIndex (9999)
};
export default function Portal({children, className, zIndex = 9999}) {
  const [container] = useState(() => {
    const el = document.createElement('div');
    el.classList.add(className);
    el.style.zIndex = zIndex;
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => document.body.removeChild(container);
  }, [container]);

  return ReactDOM.createPortal(children, container);
}
