import {
  useFonts,
  NotoSans_400Regular as NotoSans_Regular,
  NotoSans_700Bold as NotoSans_Bold,
} from '@expo-google-fonts/noto-sans';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {Toaster} from 'react-hot-toast';
import {ScrollView, Text} from 'react-native';

import Cookies from './components/Cookies';
import LoadingScreen from './components/LoadingScreen';
import Portal from './components/Portal.web';
import TopBar from './components/TopBar';
import {AdminProvider} from './context/AdminContext';
import {AssetsProvider} from './context/AssetsContext';
import {OperatorProvider} from './context/OperatorContext';
import {ThemeProvider} from './context/ThemeContext';
import FourOhFour from './screens/404/404.web';
import Download from './screens/Download/Download.web';
import FAQ from './screens/FAQ/FAQ.web';
import AdminPanel from './screens/admin-panel/AdminPanel';
import AssetManager from './screens/asset-manager/AssetManager';
import Checkout from './screens/checkout/Checkout.web';
import ContactPage from './screens/contact-us/ContactPage';
import EventManager from './screens/event-manager/EventManager';
import Home from './screens/home/Home.web';
import Setup from './screens/lockout-screen/Setup';
import LogIn from './screens/login/LogIn.web';
import NewPassword from './screens/login/NewPassword.web';
import ResetPassword from './screens/login/ResetPassword.web';
import Orders from './screens/orders/Orders.web';
import Profile from './screens/profile/Profile.web';
import SignUp from './screens/signup/SignUp.web';
import StripeLink from './screens/stripe-link/StripeLink';
import {isDev, isProd} from './utils/common/funcs';
import {toasterCfg} from './utils/web/constants.web';
import {setupLogrocketWeb} from './utils/web/funcs';
import './css/app.css';
import './css/animation.css';

if (isProd() && document.cookie.includes('EtherMediaCookieConsent')) {
  setupLogrocketWeb();
}

// Redirect locals to use server proxy
if (isDev() && window.location.host.split(':')[1] !== '8080') {
  const {protocol, pathname} = window.location;
  window.location.href = `${protocol}//localhost:8080${pathname}`;
}

// To add a new screen, simply add an entry to the 'screens' object
const linking = {
  config: {
    screens: {
      Home: {
        path: 'home',
        screens: Home,
      },
      Checkout: {
        path: 'checkout',
        screens: Checkout,
      },
      Download: {
        path: 'download',
        screens: Download,
      },
      'Log In': {
        path: 'log-in',
        screens: LogIn,
      },
      'Sign Up': {
        path: 'sign-up',
        screens: SignUp,
      },
      'Reset Password': {
        path: 'reset-password',
        screens: ResetPassword,
      },
      Orders: {
        path: 'orders',
        screens: Orders,
      },
      Profile: {
        path: 'profile',
        screens: Profile,
      },
      StripeLink: {
        path: 'stripe-link',
        screens: StripeLink,
      },
      Contact: {
        path: 'contact-us',
        screens: ContactPage,
      },
      'Event Manager': {
        path: 'event-manager',
        screens: EventManager,
      },
      'Asset Manager': {
        path: 'asset-manager',
        screens: AssetManager,
        // params: {event: undefined, pack: undefined},
      },
      'OoooooOoooo secret': {
        path: 'admin-panel',
        screens: AdminPanel,
      },
      'New Password': {
        path: 'new-password',
        screens: NewPassword,
      },
      FAQ: {
        path: 'faq',
        screens: FAQ,
      },
      FourOhFour: {
        path: '*',
        screens: FourOhFour,
      },
      Setup: {
        path: 'setup',
        screens: Setup,
      },
    },
  },
};

const Stack = createStackNavigator();
const screenOptions = {
  cardStyle: {
    backgroundAttachment: 'fixed',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 75%, rgba(111,111,222,1) 100%)',
  },
  headerMode: 'float',
  // Make sure the header renders in an arrow function here.
  // See: https://github.com/react-navigation/react-navigation/issues/8463#issuecomment-645298848
  header: (props) => <TopBar {...props} />,
};

function Nav() {
  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator screenOptions={screenOptions}>
        {/* Build stack screens from navigation linking */}
        {Object.entries(linking.config.screens).map(
          ([name, {screens: Screen, params}]) => (
            <Stack.Screen name={name} key={name} initialParams={params}>
              {(props) => (
                // Allow pages to be scrolled
                <ScrollView
                  contentContainerStyle={{
                    flexGrow: 1,
                  }}
                >
                  <Screen {...props} />
                </ScrollView>
              )}
            </Stack.Screen>
          ),
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default function App() {
  const [fontsLoaded] = useFonts({
    NotoSans_Regular,
    NotoSans_Bold,
  });

  if (!fontsLoaded) {
    return <LoadingScreen />;
  }

  return (
    <AdminProvider>
      <OperatorProvider>
        <ThemeProvider>
          <AssetsProvider>
            <Portal className="toast-portal" zIndex={99999}>
              <Toaster position="bottom-left" toastOptions={toasterCfg} />
            </Portal>
            <Nav />
            <Cookies />
          </AssetsProvider>
        </ThemeProvider>
      </OperatorProvider>
    </AdminProvider>
  );
}
