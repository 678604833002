import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getSettings() {
  try {
    const rawSettings = await AsyncStorage.getItem('settings');
    return JSON.parse(rawSettings);
  } catch (err) {
    console.error('Error getting settings');
    throw err;
  }
}

export async function saveSettings(newSettings) {
  try {
    await AsyncStorage.setItem('settings', JSON.stringify(newSettings));
  } catch (err) {
    throw "Couldn't save settings" + err;
  }
}
