import React from 'react';
import {View, StyleSheet} from 'react-native';

export default function ProgressBar({
  progress = 0,
  color = 'black',
  width = '100%',
  height = '100%',
}) {
  if (progress >= 3) {
    progress = progress - 3;
  } else {
    progress = 0;
  }
  return (
    <View style={[style.outer, {borderColor: color}, {height}, {width}]}>
      <View
        style={[style.inner, {backgroundColor: color}, {width: progress}]}
      />
    </View>
  );
}

const style = StyleSheet.create({
  outer: {
    borderWidth: 3,
    borderRadius: 20,
    justifyContent: 'flex-start',
  },
  inner: {
    height: '100%',
    borderRadius: '10%',
  },
});
