import {faFolder, faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import {
  bool,
  arrayOf,
  array,
  func,
  string,
  object,
  shape,
  oneOfType,
  number,
} from 'prop-types';
import React, {useState} from 'react';
import {View, Platform, FlatList, Text, TouchableOpacity} from 'react-native';

import FaIcon from './FaIcon';
import Search from './Search';
import {useTheme} from '../context/ThemeContext';
import {PLATFORMS} from '../utils/common/constants';
import {ellipsify} from '../utils/common/funcs';

function ListButton({title, onPress, style: overrides, active}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <TouchableOpacity
      style={[
        style.listButton,
        active ? style.listButtonActive : null,
        overrides,
      ]}
      title={title}
      onPress={onPress}
    >
      <FaIcon
        icon={active ? faFolderOpen : faFolder}
        color={active ? values.LIGHT : values.DARK}
        size={30}
      />
      <Text style={[style.buttonText, active ? style.buttonTextActive : null]}>
        {ellipsify(title, 30)}
      </Text>
    </TouchableOpacity>
  );
}

function PullToRefresh() {
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={style.pullToRefresh}>
      <View style={style.separator} />
      <View style={style.separator} />
      <Text style={style.refreshText}>Pull to refresh</Text>
    </View>
  );
}

DirList.propTypes = {
  dirs: arrayOf(shape({name: string, path: string})),
  selected: string,
  onPress: func,
  style: oneOfType([object, number]),
  searchStyle: oneOfType([object, array, number]),
  onRefresh: func,
  refreshing: bool,
  scrollable: bool,
};
export default function DirList({
  dirs,
  selected,
  onPress = () => {},
  style: overrides,
  scrollable = false,
  searchStyle: searchBarOverrides,
  onRefresh,
  msgHeader,
  msgBody,
}) {
  const {style} = useTheme(getThemedStyles);
  const [filter, setFilter] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  let displayContent = dirs;
  if (filter) {
    displayContent = dirs.filter((item) => {
      const itemNameLower = item.name.toLowerCase();
      const filterLower = filter.toLowerCase();
      return itemNameLower.includes(filterLower);
    });
  }

  function changeSelectedPack(newPack) {
    setFilter('');
    onPress(newPack);
  }

  function handleRefresh() {
    setRefreshing(true);
    onRefresh()
      .catch((err) => console.error('Failed to refresh', err))
      .finally(() => setRefreshing(false));
  }

  return (
    <View style={[style.container, overrides]}>
      <Search
        onChangeText={(text) => setFilter(text)}
        focusStyle={searchBarOverrides}
        textboxStyle={searchBarOverrides}
        style={style.searchBar}
        placeholder="Search"
      />
      <FlatList
        style={
          scrollable
            ? {
                maxHeight: '100vh',
                overflow: scroll,
              }
            : null
        }
        refreshing={refreshing}
        onRefresh={handleRefresh}
        data={displayContent}
        keyboardShouldPersistTaps="always"
        ListHeaderComponent={
          Platform.OS !== PLATFORMS.web ? <PullToRefresh /> : null
        }
        renderItem={({item, index}) => (
          <ListButton
            active={item.name === selected}
            title={item.name}
            onPress={() => changeSelectedPack(item)}
            style={index > 0 ? style.bumpDown : null}
          />
        )}
        keyExtractor={(item) => item.name}
        ListEmptyComponent={
          <>
            <Text style={style.noDirsText}>{msgHeader}</Text>
            <Text style={style.noDirsSubtext}>{msgBody}</Text>
          </>
        }
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  bumpDown: {
    marginTop: 10,
  },
  buttonText: {
    color: theme.DARK,
    marginLeft: 10,
    fontFamily: 'NotoSans_Bold',
  },
  buttonTextActive: {
    color: theme.LIGHT,
  },
  container: {
    backgroundColor: theme.BGFIRST,
    width: '100%',
  },
  listButton: {
    padding: 10,
    backgroundColor: theme.BGFIRST,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listButtonActive: {
    backgroundColor: theme.SECOND,
    color: theme.LIGHT,
    shadowColor: theme.DARK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  noDirsSubtext: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.tiny,
    alignSelf: 'center',
  },
  noDirsText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
  pullToRefresh: {
    alignItems: 'center',
    marginBottom: 20,
  },
  refreshText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
  },
  searchBar: {
    backgroundColor: theme.BGFIRST,
  },
  separator: {
    borderBottomColor: theme.FIRST,
    borderBottomWidth: 1,
    width: '10%',
    marginBottom: 3,
  },
});
