import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text} from 'react-native';

import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import etherFetch from '../../utils/ether-fetch/etherFetch';

export default function SignUp({navigation, route}) {
  const {navigate} = navigation;
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [submitStatus, setSubmitStatus] = useState(STATUS.IDLE);
  const [passwordStatus, setPasswordStatus] = useState(STATUS.IDLE);
  const {style} = useTheme(getThemedStyles);

  function postReset() {
    if (submitStatus !== STATUS.IDLE) return;

    if (newPassword !== repeatPassword) {
      toast.error('Password and Confirm Password must match');
      setPasswordStatus(STATUS.INVALID);
      return;
    }

    if (!route?.params?.token) {
      navigate('Log In');
      return setSubmitStatus(STATUS.IDLE);
    }

    setSubmitStatus(STATUS.BUSY);
    etherFetch(`/auth/resetPassword/${route.params?.token}`, {
      method: 'POST',
      body: {password: newPassword},
    })
      .then(() => {
        toast.success('Your password has been reset.');
        navigate('Log In');
      })
      .catch((error) => {
        console.error('Error:', error);
        error.json().then((result) => {
          const conflict = result.msg;
          if (conflict) {
            setPasswordStatus(STATUS.INVALID);
          }
        });
      })
      .finally(() => {
        setTimeout(() => {
          setPasswordStatus(STATUS.IDLE);
          setSubmitStatus(STATUS.IDLE);
        }, 3000);
      });
  }

  return (
    <View style={style.centerContainer}>
      <View style={style.textInputContainer}>
        <Text style={style.topText}>Create new password</Text>
        <Text style={style.subText}>
          Your new password must be different from previous used passwords.
        </Text>
        <View style={style.passwordContainer}>
          <EtherInput
            secureTextEntry
            onChangeText={setNewPassword}
            title="Password"
            value={newPassword}
            status={passwordStatus}
          />
          <Text style={style.underText}>
            Password must be over 8 characters and contain both numbers and
            symbols.
          </Text>
          <EtherInput
            secureTextEntry
            onChangeText={setRepeatPassword}
            onSubmitEditing={postReset}
            title="Confirm Password"
            value={repeatPassword}
            status={passwordStatus}
          />
          <Text style={style.underText}>Both passwords must match.</Text>
        </View>
      </View>
      <View style={style.buttonContainer}>
        <EtherButton
          style={
            status === STATUS.BUSY ? style.cancelButtonBusy : style.cancelButton
          }
          onPress={status === STATUS.BUSY ? () => {} : () => navigate('Log In')}
        >
          <Text style={style.cancelText}>Cancel</Text>
        </EtherButton>
        <EtherButton
          onPress={postReset}
          style={style.submitButton}
          status={submitStatus}
        >
          <Text style={style.submitText}>Reset Password</Text>
        </EtherButton>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 10,
  },
  cancelButton: {
    marginHorizontal: 20,
    height: 50,
    width: '33%',
    borderRadius: 5,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.FIRST,
    backgroundColor: theme.BGSECOND,
  },
  cancelButtonBusy: {
    marginHorizontal: 20,
    height: 50,
    width: '33%',
    borderRadius: 5,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.SECOND,
    backgroundColor: theme.DISABLED,
  },
  cancelText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
    textAlign: 'center',
  },
  centerContainer: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 480,
    marginTop: 50,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 10,
  },
  passwordContainer: {
    paddingVertical: 20,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: '33%',
    borderRadius: 5,
    borderColor: theme.FIRST,
    borderWidth: 2,
    backgroundColor: theme.SECOND,
  },
  submitText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  subText: {
    alignSelf: 'flex-start',
    paddingVertical: 10,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  textInputContainer: {
    padding: 10,
  },
  topText: {
    alignSelf: 'flex-start',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
  },
  underText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.tiny,
    color: theme.FIRST,
    textAlign: 'left',
    paddingVertical: 5,
  },
});
