import React, {useEffect, useState, useRef} from 'react';
import {Checkmark} from 'react-checkmark';
import toast from 'react-hot-toast';
import {View, Text, ActivityIndicator} from 'react-native';

import EtherButton from '../../components/EtherButton';
import LoadingScreen from '../../components/LoadingScreen';
import {UserInformation} from '../../components/UserInformation';
import {useAdminData} from '../../context/AdminContext';
import {useOperator} from '../../context/OperatorContext';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {useFadeIn} from '../../utils/common/hooks';
import {logOut} from '../../utils/common/login';
import {createStripeAccount} from '../../utils/common/orders';
import etherFetch from '../../utils/ether-fetch/etherFetch';

function loadUser() {
  return etherFetch('/auth/me').then((r) => r.json());
}

export default function Setup({navigation}) {
  const {style, values} = useTheme(getThemedStyles);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [leftHalfDone, setLeftHalfDone] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [stripeId, setStripeId] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const {clearOperator} = useOperator();
  const {adminData} = useAdminData();

  const ref = useRef(null);
  const doFade = useFadeIn(ref);

  function onLinkStripe() {
    setStripeLoading(true);
    createStripeAccount()
      .catch(() => {
        setStripeLoading(false);
        toast.error(
          'Error contacting stripe, please refresh the page and try again',
        );
      })
      .then(({signUpUrl}) => {
        window.location.href = signUpUrl.url;
      });
  }

  function onLogOut() {
    logOut()
      .finally(() => {
        clearOperator();
        navigation.navigate('Log In');
      })
      .catch(console.error);
  }

  useEffect(() => {
    loadUser().then((user) => {
      setStripeId(user.stripeId || '');
      setUserInfo(user.identification);
      if (
        user.identification.fname &&
        user.identification.lname &&
        user.identification.email &&
        user.identification.phone &&
        user.identification.orgname
      ) {
        setLeftHalfDone(true);
      }
      if (!adminData.skipStripe && !user.stripeId) {
        // In case the user has extremely fast internet, check again for stripe ID
        setTimeout(
          () =>
            loadUser().then((reloadedUser) => {
              setStripeId(reloadedUser.stripeId || '');
            }),
          3000,
        );
      }
    });
  }, [adminData.skipStripe]);

  // Go to thank you screen
  useEffect(() => {
    let timeoutID = null;

    const stripeComplete = adminData?.skipStripe || stripeId;

    if (leftHalfDone && !showThanks && stripeComplete) {
      timeoutID = setTimeout(() => {
        setShowThanks(true);
        doFade();
      }, 1500);
    }
    return () => clearTimeout(timeoutID);
  }, [stripeId, leftHalfDone, doFade, showThanks, adminData.skipStripe]);

  // Go away
  useEffect(() => {
    if (showThanks) {
      setTimeout(() => navigation.replace('Event Manager'), 5000);
    }
  }, [navigation, showThanks]);

  if (!userInfo) {
    return <LoadingScreen />;
  }
  return (
    <View style={style.main}>
      {showThanks ? (
        <View style={style.thanks} ref={ref}>
          <Text style={style.header}>Thank you!</Text>
          <Text style={style.subHeader}>and welcome to EtherMedia</Text>
          <ActivityIndicator color={values.FIRST} size={48} />
        </View>
      ) : (
        <>
          <Text style={style.header} dataSet={{animation: 'animation-fade-in'}}>
            We need a couple of things first...
          </Text>
          <Text style={style.subHeader}>
            Please provide the following information
          </Text>
          <View style={style.container}>
            <View style={style.half}>
              {leftHalfDone ? (
                <Checkmark size={120} color={values.FIRST} />
              ) : (
                <UserInformation
                  userInfo={userInfo}
                  onSuccess={() => setLeftHalfDone(true)}
                  editableEmail={false}
                />
              )}
            </View>
            {adminData?.skipStripe ? null : (
              <>
                <View style={style.separator} />
                <View style={style.half}>
                  {stripeId ? (
                    <Checkmark size={120} color={values.FIRST} />
                  ) : (
                    <>
                      <Text style={style.stripeText}>
                        Stripe is required to use EtherMedia commercially
                      </Text>
                      <EtherButton
                        style={style.stripeButton}
                        status={stripeLoading ? STATUS.BUSY : STATUS.IDLE}
                        onPress={onLinkStripe}
                      >
                        <Text style={style.stripeButtonText}>Link Stripe</Text>
                      </EtherButton>
                    </>
                  )}
                </View>
              </>
            )}
          </View>
          <Text style={style.footer}>
            This information can be updated/changed in the profile screen after
            signup
          </Text>
          <EtherButton style={style.logoutButton} onPress={onLogOut}>
            <Text style={style.logoutButtonText}>Log out</Text>
          </EtherButton>
        </>
      )}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    marginTop: 30,
    backgroundColor: theme.SECOND,
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    borderColor: theme.DARK,
  },
  container: {
    flexDirection: 'row',
    mobile: {
      flexDirection: 'column',
    },
  },
  footer: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.DARK,
    textAlign: 'center',
  },
  half: {
    flex: 1,
    padding: 40,
    height: 620,
    width: 420,
    justifyContent: 'center',
    mobile: {
      flex: 'auto',
    },
  },
  header: {
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 42,
    color: theme.DARK,
    mobile: {
      marginBottom: 20,
    },
  },
  image: {
    height: 300,
    width: 300,
    borderRadius: 15,
  },
  logoutButton: {
    marginTop: 20,
    height: 60,
    width: 180,
    borderRadius: 10,
    backgroundColor: theme.BGSECOND,
  },
  logoutButtonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.FIRST,
  },
  main: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 40,
  },
  separator: {
    borderWidth: 1,
    borderRadius: 100,
    borderColor: theme.FIRST,
    marginBottom: 40,
    height: 620,
    mobile: {
      height: 1,
      width: '90%',
      alignSelf: 'center',
      position: 'relative',
    },
  },
  stripeButton: {
    alignSelf: 'center',
    width: '80%',
    marginBottom: 20,
  },
  stripeButtonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  stripeText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.DARK,
    marginBottom: 20,
    textAlign: 'center',
  },
  subHeader: {
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    marginBottom: 40,
    mobile: {
      marginBottom: 10,
    },
  },
  text: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    marginTop: 10,
  },
  thanks: {
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
});
