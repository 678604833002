import {
  faBolt,
  faChalkboardTeacher,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import Particles from 'react-tsparticles';
import {loadFull} from 'tsparticles';

import {Instructions} from './Instructions';
import {Pricing} from './Pricing';
import eulaDoc from '../../assets/documents/EULA.txt';
import ppDoc from '../../assets/documents/PP.txt';
import tosDoc from '../../assets/documents/TOS.txt';
import lqHeroPoster from '../../assets/poster-lowres.png';
import heroPoster from '../../assets/poster.png';
import heroHevcMobile from '../../assets/videos/500x227_hevc.mp4';
import heroWebM from '../../assets/videos/900x500_VP9.webm';
import heroHevc from '../../assets/videos/900x500_hevc.mp4';
import {
  Decline,
  DeclineFlip,
  Incline,
  InclineFlip,
} from '../../components/Decorations.web';
import {DocumentModal} from '../../components/DocumentModal';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {useIsMobile, useWindowDimensions} from '../../utils/common/hooks';
import {particleCfg} from '../../utils/web/constants.web';

function SellingPoint({icon, title, children}) {
  const {style, values} = useTheme(getThemedStyles);
  return (
    <View style={style.sellingPoint}>
      <FaIcon icon={icon} color={values.DARK} size={36} />
      <Text style={style.headerText}>{title}</Text>
      <Text style={style.bodyText}>{children}</Text>
    </View>
  );
}

export default function Home({route, navigation}) {
  const {navigate} = navigation;
  const {style} = useTheme(getThemedStyles);
  const {isMobile} = useIsMobile();
  const {width} = useWindowDimensions();
  const {eula = false, privacy = false, terms = false} = route.params || {};
  const [playing, setPlaying] = useState(false);
  const [showLqPoster, setShowLqPoster] = useState(true);
  const particlesRef = useRef(null);

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  function showTOS() {
    navigate('Home', {terms: true});
    navigation.setOptions({title: 'Terms of Service'});
  }

  function showPP() {
    navigate('Home', {privacy: true});
    navigation.setOptions({title: 'Privacy Policy'});
  }

  function showEULA() {
    navigate('Home', {eula: true});
    navigation.setOptions({title: 'End User License Agreement'});
  }

  function hideTOS() {
    navigate('Home', {terms: undefined});
    navigation.setOptions({title: 'Home'});
  }
  function hidePP() {
    navigate('Home', {privacy: undefined});
    navigation.setOptions({title: 'Home'});
  }

  function hideEULA() {
    navigate('Home', {eula: undefined});
    navigation.setOptions({title: 'Home'});
  }

  // Show High Quality poster after a delay
  useEffect(() => {
    const timeoutHandler = setTimeout(() => setShowLqPoster(false), 10_000);
    return () => clearTimeout(timeoutHandler);
  }, []);

  const particlesLoaded = useCallback((container) => {
    particlesRef.current = container;
    container.canvas.element.style.position = 'absolute';
    container.canvas.element.classList.add('visible');
  }, []);

  useEffect(() => {
    const resizeHandler = function () {
      particlesRef.current.canvas.element.classList.remove('visible');

      // The browser won't trigger the class-based animation unless we delay it by a tick/frame
      setTimeout(() => {
        particlesRef.current.canvas.element.classList.add('visible');
      }, 0);
    };

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <View style={style.everything}>
      <Particles
        style={style.particles}
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleCfg}
      />
      <DocumentModal show={eula} onHide={hideEULA} doc={eulaDoc} />
      <DocumentModal show={privacy} onHide={hidePP} doc={ppDoc} />
      <DocumentModal show={terms} onHide={hideTOS} doc={tosDoc} />
      {/* HEADER */}
      <View style={style.topContainer}>
        <View style={style.topColumn}>
          <Text style={style.rolloutPreHeader}>ETHERMEDIA</Text>
          <Text style={style.rolloutTitle}>
            Reach your customers like never before.
          </Text>
          <Text style={style.rolloutDescription}>
            EtherMedia gives you the tools to maximize your media sale
            potential. Never miss an opportunity to deliver outstanding quality.
          </Text>
          {!isMobile ? (
            <EtherButton
              style={style.contactButton}
              onPress={() => navigate('Contact')}
            >
              <Text style={style.buttonText}>Contact Us</Text>
            </EtherButton>
          ) : null}
        </View>
        <View style={[style.topColumn, {paddingLeft: 0}]}>
          <video
            poster={showLqPoster ? lqHeroPoster : heroPoster}
            playsInline
            autoPlay
            muted
            onPlay={() => setPlaying(true)}
            loop
            // Why I couldn't pass styles as an array is a mystery ¯\_(ツ)_/¯
            style={{
              ...style.video,
              ...(!playing ? {height: '100%', objectFit: 'contain'} : {}),
              ...(!playing && showLqPoster ? {filter: 'blur(5px)'} : {}),
            }}
          >
            <source
              src={isMobile ? heroHevcMobile : heroHevc}
              type="video/quicktime"
            />
            <source src={heroWebM} type="video/webm" />
          </video>
        </View>
      </View>
      {/* BELOW THE FOLD */}
      <Incline width={width} />
      <View style={style.taglineContainer}>
        <View style={style.tagline}>
          <Text style={style.taglineHeaderText}>
            EtherMedia is a suite of software solutions for photographers and
            media companies. Simplify your workflow with our media-centric cloud
            storage solution. Easily boost your sales with our streamlined
            checkout process. You don't need to be a web developer or an
            accountant to be a great photographer, you just need EtherMedia!
          </Text>
          <Text style={style.tagLineBodyText}>
            We understand the effort you put into your work. EtherMedia allows
            you to seamlessly connect with your customers. Instant sales and
            delivery of your media, driven by YOU, supported by us.
          </Text>
        </View>
      </View>
      <InclineFlip width={width} />
      <Instructions />
      <Decline width={width} />
      <View style={style.sellingPointContainer}>
        <SellingPoint icon={faChalkboardTeacher} title="Simple Ordering">
          QR code ordering, supported by an app on your tablet, allows customers
          to easily see and order photos and videos while attending your live
          event. Of course, email and sms options are also available for
          flexibility.
        </SellingPoint>
        <SellingPoint icon={faHandHoldingUsd} title="Automatic Payments">
          Easily collect payments directly into your safe and secure Stripe
          account. For information about Stripe,{' '}
          <a href="https://stripe.com/connect">click here</a>.
        </SellingPoint>
        <SellingPoint icon={faBolt} title="Instant Digital Delivery">
          Hard drives, CDs, jump drives, JUNK! EtherMedia provides direct
          digital delivery to you customer's phone, tablet or computer.
        </SellingPoint>
      </View>
      <View style={style.faqContainer}>
        <EtherButton style={style.faqButton} onPress={() => navigate('FAQ')}>
          <Text style={style.buttonText}>Frequently Asked Questions</Text>
        </EtherButton>
      </View>
      <DeclineFlip width={width} />
      <Pricing />
      {/* FOOTER */}
      <View style={style.footer}>
        <View style={style.blurBackground} />
        <TouchableOpacity onPress={showTOS}>
          <Text style={style.footerText}>Terms of Service</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={showPP}>
          <Text style={style.footerText}>Privacy Policy</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={showEULA}>
          <Text style={style.footerText}>EULA</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  blurBackground: {
    backgroundColor: 'rgba(135, 141, 236, 0.25)',
    backdropFilter: 'blur(10px)',
    position: 'absolute',
    inset: 0,
  },
  bodyText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.bigBody,
    color: theme.DARK,
    textAlign: 'left',
    mobile: {
      textAlign: 'center',
    },
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 20,
    color: theme.LIGHT,
    mobile: {
      fontSize: 18,
    },
  },
  contactButton: {
    height: 60,
    width: 180,
    borderRadius: 10,
    alignSelf: 'flex-start',
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 80,
    mobile: {
      alignSelf: 'center',
      marginBottom: 40,
    },
  },
  everything: {
    overflowX: 'hidden',
    backgroundColor: theme.FIRST,
  },
  faqButton: {
    alignSelf: 'center',
    height: 60,
    width: 300,
    borderRadius: 10,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  faqContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 40,
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
  },
  footer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderTopWidth: 2,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: 60,
    mobile: {
      justifyContent: 'center',
      height: 50,
    },
  },
  footerText: {
    paddingHorizontal: 20,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  headerText: {
    marginTop: 10,
    marginBottom: 20,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
  },
  particles: {
    position: 'absolute',
    inset: 0,
  },
  rolloutDescription: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.bigBody,
    color: theme.LIGHT,
    marginBottom: 40,
    mobile: {
      textAlign: 'center',
    },
  },
  rolloutPreHeader: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.SECOND,
    letterSpacing: 21,
    marginBottom: 20,
    mobile: {
      marginBottom: 10,
    },
  },
  rolloutTitle: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.starHeader,
    color: theme.LIGHT,
    marginBottom: 40,
    mobile: {
      marginBottom: 10,
      fontSize: fontSize.planetHeader,
      textAlign: 'center',
    },
  },
  sellingPoint: {
    maxWidth: 400,
    padding: 20,
    alignItems: 'left',
    mobile: {
      alignItems: 'center',
    },
  },
  sellingPointContainer: {
    backgroundColor: theme.BGFIRST,
    justifyContent: 'center',
    width: '100%',
    paddingVertical: 20,
    flexDirection: 'row',
    mobile: {
      alignItems: 'center',
      padding: 20,
      flexDirection: 'column',
    },
  },
  tagline: {
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 1200,
  },
  tagLineBodyText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.bigBody,
    color: theme.DARK,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.body,
    },
  },
  taglineContainer: {
    padding: 40,
    paddingVertical: 60,
    backgroundColor: theme.BGFIRST,
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    mobile: {
      padding: 20,
    },
  },
  taglineHeaderText: {
    marginTop: 10,
    marginBottom: 20,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  topColumn: {
    flex: 1,
    alignItems: 'flex-start',
    paddingHorizontal: 80,
    mobile: {
      flex: null,
      alignItems: 'center',
      paddingHorizontal: 20,
    },
  },
  topContainer: {
    paddingVertical: 60,
    flexDirection: 'row',
    maxWidth: 1140,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '70vh',
    maxHeight: 900,
    minHeight: 600,
    mobile: {
      marginTop: 20,
      paddingTop: 0,
      flexDirection: 'column',
      maxWidth: null,
      height: null,
      maxHeight: null,
    },
  },
  video: {
    width: '100%',
    maxHeight: '100%',
    mobile: {
      width: '45%',
    },
  },
});
