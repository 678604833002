import React, {useState, useEffect, useContext, useCallback} from 'react';

import {useAdminData} from './AdminContext';
import etherFetch from '../utils/ether-fetch/etherFetch';
import {clearLocalStorageSessionInfo} from '../utils/web/funcs';

const OperatorContext = React.createContext();

export function OperatorProvider({children}) {
  const [op, setOp] = useState('');
  const [customization, setCustomization] = useState(null);
  const currentRoute = window.location.pathname;
  const {adminData} = useAdminData();

  const changeOperator = useCallback((value) => {
    async function loadUser() {
      return await etherFetch('/auth/me').then((r) => r.json());
    }

    loadUser().then((parsedResponse) => setCustomization(parsedResponse));
    setOp(value);
  }, []);

  const identificationComplete = customization?.identification?.complete;
  const stripeComplete = adminData?.skipStripe || customization?.stripeId;

  const missingInfo = !identificationComplete || !stripeComplete;

  if (op && customization && missingInfo && !currentRoute.includes('setup')) {
    window.location.pathname = 'setup';
  }

  // Log in to Operator
  useEffect(() => {
    if (localStorage.token) {
      changeOperator(localStorage.username || null);
    }
  }, [changeOperator]);

  function clearOperator() {
    clearLocalStorageSessionInfo();
    setOp('');
  }

  return (
    <OperatorContext.Provider
      value={{
        op,
        changeOperator,
        clearOperator,
        customization,
      }}
    >
      {children}
    </OperatorContext.Provider>
  );
}

function useOperator() {
  const context = useContext(OperatorContext);
  if (!context) {
    throw new Error('useOperator must be used within an OperatorProvider');
  }

  return context;
}

export {useOperator};
export default OperatorContext;
