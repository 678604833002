import {faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons';
import {string, func, bool, oneOf} from 'prop-types';
import React from 'react';
import {useDropzone} from 'react-dropzone';
import {Text, View, Pressable, Image, Modal} from 'react-native';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';

UploadModal.propTypes = {
  onClose: func.isRequired,
  show: bool.isRequired,
  preview: string,
  onUpload: func.isRequired,
  title: string.isRequired,
  status: oneOf(Object.values(STATUS)),
};
export default function UploadModal({
  recommendedSize = false,
  onClose,
  show,
  onUpload,
  title,
  preview,
  previewImageStyle,
  status = STATUS.IDLE,
}) {
  const {style, values} = useTheme(getThemedStyles);
  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    onDrop: onUpload,
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/jpg, image/png',
  });

  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent
      visible={show}
      ariaHideApp={false}
    >
      <View style={style.modalContainer}>
        <Pressable style={style.modalBackground} onPress={onClose} />
        <View style={style.modal}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <View style={style.uploadZone}>
                <Text style={style.textDark}>Drop here!</Text>
              </View>
            ) : (
              <View style={style.uploadZone}>
                <FaIcon
                  icon={faCloudUploadAlt}
                  color={values.SECOND}
                  size={120}
                />
                <EtherButton
                  style={style.uploadButton}
                  onPress={open}
                  status={status}
                >
                  <View style={style.uploadButton}>
                    <Text style={style.buttonText}>Choose files to upload</Text>
                  </View>
                </EtherButton>
                <Text style={style.text}>or drag and drop here</Text>
                <Text style={style.text}>
                  {recommendedSize
                    ? 'File dimensions should be ' + recommendedSize
                    : null}
                </Text>
              </View>
            )}
          </div>
          <View style={style.separator} />
          <Text style={style.textDark}>{title}</Text>
          <View style={style.previewContainer}>
            <Image
              style={[style.preview, previewImageStyle]}
              source={{uri: preview}}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonText: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  modal: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 15,
    alignItems: 'center',
    padding: 20,
    maxWidth: 600,
    height: 'auto',
  },
  modalBackground: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
  },
  preview: {
    width: '100%',
    height: '100%',
  },
  previewContainer: {
    marginTop: 10,
    borderRadius: 10,
    overflow: 'hidden',
    borderWidth: 2,
    borderColor: theme.DARK,
    width: 350,
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 2,
    width: '100%',
    backgroundColor: theme.FIRST,
  },
  text: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.tiny,
    color: theme.DARK,
    textAlign: 'center',
  },
  textDark: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  uploadButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    width: 200,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadZone: {
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 380,
    height: 240,
  },
});
