import {
  faFolder,
  faFileImage,
  faFileVideo,
  faCheck,
  faTrashAlt,
  faEllipsisV,
  faPencilAlt,
  faDownload,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import {func, string} from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
} from 'react-native';
import 'react-loading-skeleton/dist/skeleton.css';

import EtherButton from './EtherButton';
import EtherInput from './EtherInput';
import FaIcon from './FaIcon';
import SimpleDropdown from './SimpleDropdown.web';
import {useAssets} from '../context/AssetsContext';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {downloadItem} from '../utils/common/download';
import {
  ellipsify,
  formatBytes,
  secondsToTimestamp,
} from '../utils/common/funcs';
import {assetType} from '../utils/common/types';
import {imgIsCached} from '../utils/web/funcs';

function RenameModal({onClose, name, onChangeName, show, onRename, loading}) {
  function downHandler({key}) {
    if (key === 'Enter') {
      onRename();
    }
    if (key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  const {style} = useTheme(getThemedStyles);
  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent
      visible={show}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <TouchableOpacity
        style={style.modalBackdrop}
        onPress={onClose}
        activeOpacity={1}
      />
      <View style={style.modalContainer}>
        <View style={[style.modal, style.renameModal]}>
          <EtherInput
            placeholder="Current file name"
            value={name}
            onChangeText={onChangeName}
            title="Rename to"
            style={style.input}
            onKeyPress={downHandler}
            autoFocus
          />
          <View style={style.modalRow}>
            <EtherButton
              onPress={onClose}
              style={style.closeButton}
              disabled={loading}
            >
              <Text style={style.closeText}>Close</Text>
            </EtherButton>
            <EtherButton
              onPress={onRename}
              style={style.modalButton}
              status={loading ? STATUS.BUSY : STATUS.IDLE}
            >
              <Text style={style.modalButtonText}>Confirm</Text>
            </EtherButton>
          </View>
        </View>
      </View>
    </Modal>
  );
}

function DeleteModal({onClose, show, onDelete, loading}) {
  function downHandler({key}) {
    if (key === 'Enter') {
      onDelete();
    }
    if (key === 'Escape') {
      onClose();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  const {style} = useTheme(getThemedStyles);
  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent
      visible={show}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <TouchableOpacity
        style={style.modalBackdrop}
        onPress={onClose}
        activeOpacity
      />
      <View style={style.modalContainer}>
        <View style={[style.modal, style.deleteModal]}>
          <Text style={style.deleteHeader}>Are you sure?</Text>
          <Text style={style.deleteSub}>This action is permanent.</Text>
          <View style={style.modalRow}>
            <EtherButton
              style={style.closeButton}
              onPress={onClose}
              disabled={loading}
            >
              <Text style={style.closeText}>Cancel</Text>
            </EtherButton>
            <EtherButton
              onPress={onDelete}
              style={style.modalButton}
              status={loading ? STATUS.BUSY : STATUS.IDLE}
            >
              <Text style={style.modalButtonText}>Delete</Text>
            </EtherButton>
          </View>
        </View>
      </View>
    </Modal>
  );
}

Details.propType = {
  meta: assetType.isRequired,
};
function Details({meta}) {
  const [mouseHover, setMouseHover] = useState(false);
  const {style, values} = useTheme(getThemedStyles);

  const formattedSize = meta.bytes ? formatBytes(meta.bytes) : NaN;

  const keyArr = meta.original.key.split('/');
  const displayLocation = ellipsify(
    // trim master bucket key and file name from path
    keyArr.splice(1, keyArr.length - 3).join('/'),
    36,
  );

  return (
    <View style={style.optionBar}>
      <View
        style={[style.subOptionBar, style.subOptionBarTop]}
        onMouseOver={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
      >
        {mouseHover && meta.name.length > 28 ? (
          <Text style={style.nameLong}>{meta.name}</Text>
        ) : (
          <Text style={style.name}>{ellipsify(meta.name, 28)}</Text>
        )}
      </View>
      <View style={[style.subOptionBar, style.subOptionBarMid]}>
        <FaIcon
          icon={faFolder}
          size={15}
          color={values.FIRST}
          style={style.folder}
        />
        <Text style={style.location}>{displayLocation}</Text>
      </View>
      <View style={[style.subOptionBar, style.subOptionBarBot]}>
        <FaIcon
          icon={meta.mime.includes('image') ? faFileImage : faFileVideo}
          size={13}
          color={values.FIRST}
          style={style.typeident}
        />
        <View style={style.botBarDetails}>
          <Text style={style.filetype}>{meta.mime.split('/')[1]}</Text>
          {formattedSize ? (
            <Text style={style.filesize}>{formattedSize}</Text>
          ) : (
            <Skeleton width={50} height={12} />
          )}
          {meta.width && meta.height ? (
            <Text style={style.dimensions}>
              {meta.width} x {meta.height}
            </Text>
          ) : (
            <Skeleton width={100} height={12} />
          )}
        </View>
      </View>
    </View>
  );
}

Overlay.propType = {
  meta: assetType.isRequired,
};
function Overlay({meta}) {
  const {style, values} = useTheme(getThemedStyles);

  if (meta.progress === 'processing') {
    return (
      <View style={style.progressContainer}>
        <ActivityIndicator size="large" color={values.FIRST} />
        <Text style={style.progressText}>
          Tip: Videos won't show up on mobile until processing is complete
        </Text>
      </View>
    );
  }
  if (meta.mime.includes('video')) {
    return (
      <>
        <FaIcon size={100} icon={faVideo} style={style.videoIcon} />
        <Text style={style.duration}>{secondsToTimestamp(meta.duration)}</Text>
      </>
    );
  } else {
    return null;
  }
}

AdminGalleryTile.propTypes = {
  onPress: func,
  onSelectPress: func,
  selected: assetType,
  source: string.isRequired,
  meta: assetType,
};
export default function AdminGalleryTile({
  onPress = () => {},
  onSelectPress = () => {},
  preDeleteCallback = () => {},
  selected,
  style: overrides,
  source,
  meta,
}) {
  const {deleteAssets, renameAsset} = useAssets();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [thumbLoaded, setThumbLoaded] = useState(imgIsCached(source));
  const [isBusy, setIsBusy] = useState(false);
  const [newAssetName, setNewAssetName] = useState('');
  const kebabRef = useRef(null);
  const {style, values} = useTheme(getThemedStyles);
  const [, event, pack] = meta.original.key
    .replace('ether-media/', '')
    .split('/');
  const menuEntries = [
    {
      title: 'Rename',
      faIcon: faPencilAlt,
      callback: () => setShowRenameModal(true),
    },
    {
      title: 'Delete',
      faIcon: faTrashAlt,
      callback: () => setShowDeleteModal(true),
    },

    {
      title: 'Download',
      faIcon: faDownload,
      callback: downloadAsset,
    },
  ];

  function downloadAsset() {
    downloadItem(`/packs/download/asset/${event}/${pack}/${meta.name}`);
  }

  function clickHandler(e) {
    const clickedMe = e.target === kebabRef.current;
    const clickedChild = kebabRef.current.contains(e.target);

    // Don't force-hide ourself since we were clicked
    if (clickedMe || clickedChild) return;

    setShowDropdown(false);
  }

  useEffect(() => {
    const appRoot = document.getElementById('root');
    appRoot.addEventListener('click', clickHandler);
    return () => {
      appRoot.removeEventListener('click', clickHandler);
    };
  }, []);

  function onDelete() {
    setIsBusy(true);
    deleteAssets(meta.name)
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsBusy(false));
  }

  function onRename() {
    setIsBusy(true);
    renameAsset(meta.name, newAssetName)
      .catch((err) => console.error(err))
      .finally(() => setIsBusy(false));
  }

  return (
    <View style={[style.container, overrides]}>
      {!thumbLoaded ? (
        <SkeletonTile style={style.skeletonTile} meta={meta} />
      ) : null}
      <RenameModal
        show={showRenameModal}
        onClose={() => setShowRenameModal(false)}
        onChangeName={setNewAssetName}
        name={newAssetName}
        loading={isBusy}
        onRename={onRename}
      />
      <DeleteModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={() => {
          preDeleteCallback();
          onDelete();
        }}
        loading={isBusy}
      />
      <TouchableOpacity
        style={[style.container, {opacity: thumbLoaded ? 1 : 0}]}
        onPress={(e) => onPress(e)}
      >
        {showDropdown ? (
          <SimpleDropdown
            style={style.dropdown}
            entries={menuEntries}
            onHide={() => setShowDropdown(false)}
          />
        ) : null}
        <View style={style.imageContainer}>
          <Overlay meta={meta} />
          <Image
            onLoad={() => {
              if (!thumbLoaded) {
                setThumbLoaded(true);
              }
            }}
            onError={() => setThumbLoaded(true)}
            style={style.image}
            source={source}
          />
        </View>
        {selected ? (
          <View style={style.checkBoxFocus}>
            <TouchableOpacity
              style={style.checkContainer}
              onPress={onSelectPress}
            >
              <FaIcon icon={faCheck} color={values.BGSECOND} />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={style.checkBoxBlur}>
            <TouchableOpacity
              style={style.checkContainer}
              onPress={onSelectPress}
            />
          </View>
        )}
        <View style={style.menuIconContainer}>
          <TouchableOpacity
            style={style.menuIcon}
            ref={kebabRef}
            onPress={() => setShowDropdown(!showDropdown)}
          >
            <FaIcon icon={faEllipsisV} size={20} color={values.BGSECOND} />
          </TouchableOpacity>
        </View>
        <Details meta={meta} />
      </TouchableOpacity>
    </View>
  );
}

function SkeletonTile(props) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <View {...props}>
      <SkeletonTheme baseColor={values.BGFIRST} highlightColor="#d2d2d2">
        {/* Image */}
        <FaIcon
          icon={props.meta.mime.includes('image') ? faFileImage : faFileVideo}
          size={36}
          style={style.skeletonIcon}
        />
        <Skeleton height={131} borderRadius={0} />
        {/* Bottom */}
        <View style={{height: 3, backgroundColor: values.BGSECOND}} />
        <View style={style.skeletonBar}>
          <Skeleton width={260} height={12} />
          <View style={{flexDirection: 'row'}}>
            <Skeleton width={15} height={12} circle />
            <Skeleton width={245} height={12} />
          </View>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Skeleton width={15} height={12} circle />
            <Skeleton width={122.5} height={12} />
            <Skeleton width={122.5} height={12} />
          </View>
        </View>
      </SkeletonTheme>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  botBarDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  checkBoxBlur: {
    flex: 1,
    position: 'absolute',
    marginTop: 5,
    marginLeft: 5,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: theme.BGSECOND,
    backgroundColor: theme.BLACKGROUND,
  },
  checkBoxFocus: {
    flex: 1,
    position: 'absolute',
    marginTop: 5,
    marginLeft: 5,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: theme.BGSECOND,
    backgroundColor: theme.BLACKGROUND,
  },
  checkContainer: {
    paddingLeft: 2,
    margin: 1,
    height: 17,
    width: 17,
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    width: 80,
    marginRight: 15,
  },
  closeText: {
    fontSize: fontSize.legal,
    color: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  container: {
    height: 200,
    width: 270,
  },
  deleteHeader: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 5,
  },
  deleteModal: {
    width: 300,
  },
  deleteSub: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  dimensions: {
    marginRight: 3,
    color: theme.DARK,
  },
  dropdown: {
    width: 125,
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.BGFIRST,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.FIRST,
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 30,
  },
  duration: {
    color: theme.LIGHT,
    backgroundColor: 'black',
    padding: 3,
    zIndex: 99999,
    position: 'absolute',
    right: 2,
    bottom: 2,
    borderRadius: 5,
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
  },
  filesize: {
    color: theme.DARK,
  },
  filetype: {
    marginLeft: 3,
    color: theme.DARK,
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
  },
  folder: {
    marginLeft: 5,
    marginTop: 2,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    flex: 1,
  },
  input: {
    width: '100%',
  },
  location: {
    marginLeft: 3,
    marginTop: 2,
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },
  menuIcon: {
    padding: 10,
    filter: `drop-shadow(0px 0px 3px ${theme.DARK})`,
  },
  menuIconContainer: {
    alignSelf: 'flex-end',
    flex: 1,
    position: 'absolute',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 20,
  },
  modalBackdrop: {
    inset: 0,
    position: 'absolute',
    backgroundColor: theme.BLACKGROUND,
  },
  modalButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    width: 80,
  },
  modalButtonText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  modalContainer: {
    margin: 'auto',
  },
  modalRow: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  name: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 3,
  },
  nameLong: {
    width: '98%',
    marginLeft: 3,
    position: 'absolute',
    fontSize: fontSize.body,
    lineHeight: 22,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    backgroundColor: theme.BGFIRST,
  },
  optionBar: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 1,
    height: 69,
    zIndex: 1,
  },
  progressContainer: {
    position: 'absolute',
    backgroundColor: theme.DARK,
    opacity: 0.8,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '10px',
  },
  progressText: {
    color: theme.LIGHT,
    textAlign: 'center',
    fontSize: fontSize.legal,
  },
  renameModal: {
    height: 210,
    width: 380,
  },
  skeletonBar: {
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 62,
    backgroundColor: theme.BGFIRST,
  },
  skeletonIcon: {
    position: 'absolute',
    alignSelf: 'center',
    top: '25%',
    zIndex: 99999,
    color: theme.BGSECOND,
  },
  skeletonTile: {
    position: 'absolute',
    height: 200,
    width: 270,
  },
  subOptionBar: {
    height: 23,
    flexDirection: 'row',
    alignContent: 'center',
  },
  subOptionBarBot: {
    borderTopColor: theme.FIRST,
    borderTopWidth: 1,
  },
  subOptionBarMid: {
    zIndex: -1,
  },
  subOptionBarTop: {},
  typeident: {
    marginTop: 3,
    marginLeft: 3,
  },
  videoIcon: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: 15,
    alignSelf: 'center',
    color: 'rgba(255, 255, 255, 0.8)',
  },
});
