import React from 'react';
import {View, Text, FlatList, Image} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import {conditionalS, ellipsify, formatBytes} from '../utils/common/funcs';
import {useIsMobile} from '../utils/common/hooks';

export default function PackSummary({pack}) {
  const {style} = useTheme(getThemedStyles);
  const {isMobile} = useIsMobile();
  const totalSize = pack.assets.reduce((acc, asset) => acc + asset.bytes, 0);

  return (
    <View style={style.main}>
      <View style={style.header}>
        <Text style={style.headerText}>
          {ellipsify(pack.name, isMobile ? 20 : 80)}
        </Text>
        <Text style={style.subheaderText}>
          {pack.assets.length} file{conditionalS(pack.assets.length)},{' '}
          {formatBytes(totalSize, 0)}
        </Text>
      </View>
      <View style={style.imageZone}>
        <FlatList
          data={pack.assets.slice(0, isMobile ? 4 : 12)}
          renderItem={({item}) => (
            <Image style={style.image} source={{uri: item.thumb.url}} />
          )}
          numColumns={isMobile ? 2 : 6}
          keyExtractor={({item}) => item}
          key={isMobile}
        />
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  header: {
    height: 60,
    width: '100%',
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 10,
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 20,
    mobile: {
      width: '100%',
    },
  },
  headerText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  image: {
    borderRadius: 10,
    marginTop: 20,
    marginRight: 20,
    height: 180,
    width: 180,
    mobile: {
      marginTop: 10,
      marginRight: 10,
      height: 105,
      width: 105,
    },
  },
  main: {
    padding: 20,
  },
  subheaderText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
});
