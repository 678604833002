import {
  faMoneyBill,
  faShare,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {Text, View} from 'react-native';

import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {useIsMobile} from '../../utils/common/hooks';

export function Instructions() {
  const {style, values} = useTheme(getThemedStyles);
  const {isMobile} = useIsMobile();

  return (
    <View style={style.instructions}>
      <Text style={style.instructionTitle}>How It Works</Text>
      <View style={style.instructionRow}>
        <View style={style.instructionColumn}>
          <Text style={style.instructionTextHeader}>1. Upload</Text>
          <Text style={style.instructionText}>
            Upload your content to our cloud storage system. Your content is
            organized individually into easily marketable packs.
          </Text>
        </View>
        {!isMobile ? (
          <View style={style.instructionColumn}>
            <FaIcon
              style={style.instructionIcon}
              icon={faUpload}
              color={values.LIGHT}
              size={100}
            />
          </View>
        ) : null}
      </View>
      <View style={style.instructionRow}>
        <View style={style.instructionColumn}>
          <Text style={style.instructionTextHeader}>2. Share</Text>
          <Text style={style.instructionText}>
            Connect with your customers using one of our many purchase routes.
            Allow attendees of your event to create their own cart through our
            tablet application, or send them a link directly with a premade
            cart.
          </Text>
        </View>
        {!isMobile ? (
          <View style={style.instructionColumn}>
            <FaIcon
              style={style.instructionIcon}
              icon={faShare}
              color={values.LIGHT}
              size={100}
            />
          </View>
        ) : null}
      </View>
      <View style={style.instructionRow}>
        <View style={style.instructionColumn}>
          <Text style={style.instructionTextHeader}>3. Get Paid</Text>
          <Text style={style.instructionText}>
            Your customers purchase and download your content with our simple
            checkout process. Once the purchase is complete, you get your money
            automatically.
          </Text>
        </View>
        {!isMobile ? (
          <View style={style.instructionColumn}>
            <FaIcon
              style={style.instructionIcon}
              icon={faMoneyBill}
              color={values.LIGHT}
              size={100}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  instructionColumn: {
    flex: 1,
    maxWidth: 400,
    justifyContent: 'center',
  },
  instructionIcon: {
    margin: 60,
    marginLeft: 100,
  },
  instructionRow: {
    flexDirection: 'row',
    maxWidth: '80%',
  },
  instructions: {
    alignItems: 'center',
    marginVertical: 70,
  },
  instructionText: {
    marginTop: 10,
    marginBottom: 20,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  instructionTextHeader: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.LIGHT,
  },
  instructionTitle: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.starHeader,
    color: theme.LIGHT,
    marginBottom: 40,
    mobile: {
      marginBottom: 20,
      fontSize: fontSize.planetHeader,
      textAlign: 'center',
    },
  },
});
