import {faFolderPlus} from '@fortawesome/free-solid-svg-icons';
import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import AdminGallery from '../../components/AdminGallery.web';
import DirList from '../../components/DirList';
import {DropdownProvider} from '../../components/Dropdown';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {FolderModal} from '../../components/FolderModal';
import {useAssets} from '../../context/AssetsContext';
import {useTheme} from '../../context/ThemeContext';
import {ellipsify} from '../../utils/common/funcs';

export default function AssetManager({navigation, route}) {
  const {
    currentPack,
    currentEvent,
    events,
    packs,
    createPack,
    selectPack,
    selectEvent,
    assets,
    initialize,
  } = useAssets();
  useEffect(() => window.scrollTo(0, 0), []);

  const [showAdd, setShowAdd] = useState(false);
  const [showEventList, setShowEventList] = useState(false);
  const {style, values} = useTheme(getThemedStyles);

  function viewPack(newPack) {
    navigation.setParams({pack: newPack.name});
    selectPack(newPack.name);
  }

  function createFolder(chosenPack) {
    createPack(chosenPack);
  }

  useEffect(initialize, [initialize]);

  if (!events) return null;
  return (
    <View style={style.masterContainer}>
      <FolderModal
        show={showAdd}
        onAddFolder={(name) => createFolder(name)}
        onHide={() => setShowAdd(false)}
      />
      <DropdownProvider>
        <View style={style.mainContainer}>
          <View style={style.leftPane}>
            <TouchableOpacity
              style={style.leftPaneHeader}
              onPress={() => setShowEventList(!showEventList)}
            >
              <Text style={style.textHeader}>
                {showEventList ? 'Events:' : ellipsify(currentEvent, 13)}
              </Text>
              <EtherButton
                style={style.addPack}
                onPress={() => setShowAdd(true)}
              >
                <FaIcon
                  icon={faFolderPlus}
                  color={values.BGSECOND}
                  size={28}
                  style={{padding: 5}}
                />
              </EtherButton>
            </TouchableOpacity>
            {showEventList ? (
              <DirList
                scrollable
                selected={currentEvent}
                dirs={events}
                onPress={(newEvent) => {
                  navigation.setParams({event: newEvent.name, pack: undefined});
                  selectEvent(newEvent.name);
                  setShowEventList(false);
                }}
                searchStyle={style.searchStyle}
              />
            ) : (
              <DirList
                scrollable
                msgHeader="No Packs Found"
                msgBody="Create a pack to add content"
                selected={currentPack}
                dirs={packs}
                onPress={(newPack) => viewPack(newPack)}
                searchStyle={style.searchStyle}
              />
            )}
          </View>
          <View style={style.galleryContainer}>
            {assets ? <AdminGallery /> : null}
          </View>
        </View>
      </DropdownProvider>
    </View>
  );
}

export const getThemedStyles = (theme, fontSize) => ({
  addPack: {
    border: 'none',
  },
  galleryContainer: {
    flex: 1,
  },
  leftPane: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 280,
    backgroundColor: theme.BGFIRST,
  },
  leftPaneHeader: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: 65,
    padding: 10,
    backgroundColor: theme.SECOND,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.BGSECOND,
    overflow: 'hidden',
  },
  masterContainer: {
    flex: 1,
  },
  searchStyle: {
    borderRadius: 10,
    margin: 10,
    height: 40,
    width: '95%',
    alignSelf: 'center',
  },
  textHeader: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    color: theme.LIGHT,
  },
});
