import React from 'react';
import {View, FlatList} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import PackSummary from './PackSummary.web';

export default function CartSummary({packs, logoURL}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.main}>
      <FlatList
        data={packs}
        renderItem={({item}) => <PackSummary pack={item} />}
        numColumns={1}
        keyExtractor={(item) => `${item.name}`}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  main: {
    width: '100%',
  },
});
