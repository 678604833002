import React, {useState} from 'react';
import {View, Text} from 'react-native';

import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import etherFetch from '../../utils/ether-fetch/etherFetch';

export default function SignUp({navigation}) {
  const {navigate} = navigation;
  const {style} = useTheme(getThemedStyles);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const [sent, setSent] = useState(false);

  function postReset() {
    if (status === STATUS.IDLE) {
      setStatus(STATUS.BUSY);
      etherFetch('/auth/resetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {email},
      })
        .then(() => setSent(true))
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setStatus(STATUS.IDLE);
        });
    }
  }
  return (
    <View style={style.container}>
      {sent ? (
        <View style={style.textInputView}>
          <Text style={style.topText}>Email Sent</Text>
          <Text style={style.subText}>
            If an account is associated with this address, you will receive an
            email with a link to reset your password.
          </Text>
          <View style={style.emailContainer}>
            <Text style={style.subText}>Email:</Text>
            <EtherInput
              disabled={sent}
              onChangeText={setEmail}
              value={email}
              status={status}
            />
          </View>
        </View>
      ) : (
        <>
          <View style={style.textInputView}>
            <Text style={style.topText}>
              Enter the email address associated with your EtherMedia account
            </Text>
            <Text style={style.subText}>
              You'll receive an email with instructions to reset your password.
            </Text>
            <View style={style.emailContainer}>
              <EtherInput
                onChangeText={setEmail}
                onSubmitEditing={postReset}
                value={email}
                status={status}
              />
            </View>
          </View>
          <View style={style.buttonView}>
            <EtherButton
              style={
                status === STATUS.BUSY
                  ? style.cancelButtonBusy
                  : style.cancelButton
              }
              onPress={
                status === STATUS.BUSY ? () => {} : () => navigate('Log In')
              }
            >
              <Text style={style.cancelText}>Cancel</Text>
            </EtherButton>
            <EtherButton
              onPress={postReset}
              style={style.submitButton}
              status={status}
            >
              <Text style={style.submitText}>Submit</Text>
            </EtherButton>
          </View>
        </>
      )}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 10,
  },
  cancelButton: {
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: '30%',
    borderRadius: 5,
    borderColor: theme.FIRST,
    borderWidth: 2,
    backgroundColor: theme.BGSECOND,
  },
  cancelButtonBusy: {
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: '30%',
    borderRadius: 5,
    borderColor: theme.SECOND,
    borderWidth: 2,
    backgroundColor: theme.DISABLED,
  },
  cancelText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
    textAlign: 'center',
  },
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 480,
    marginTop: 50,
    zIndex: 1,
    position: 'absolute',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 10,
  },
  emailContainer: {
    paddingVertical: 20,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: '30%',
    borderRadius: 5,
    borderColor: theme.FIRST,
    borderWidth: 1,
    backgroundColor: theme.SECOND,
  },
  submitText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  subText: {
    alignSelf: 'flex-start',
    paddingVertical: 10,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  textInputView: {
    padding: 10,
  },
  topText: {
    alignSelf: 'flex-start',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.DARK,
  },
});
