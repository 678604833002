import React, {useState, useEffect, useContext} from 'react';

import {getAdminData, saveAdminData} from '../utils/common/admin';

const AdminContext = React.createContext();

export function AdminProvider({children}) {
  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    getAdminData()
      .then((res) => setAdminData(res.adminData))
      .catch((e) => {
        if (e.status === 403) return (window.location.pathname = '404');
      });
  }, []);

  async function save(data) {
    try {
      const result = await saveAdminData(data);
      setAdminData(result.adminData);
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <AdminContext.Provider
      value={{
        adminData,
        save,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

function useAdminData() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdminData must be used within an AdminProvider');
  }

  return context;
}

export {useAdminData};
export default AdminContext;
