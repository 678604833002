import React, {useEffect, useRef, useState} from 'react';
import {View, Text, TouchableOpacity, Modal} from 'react-native';

import EtherButton from './EtherButton';
import EtherInput from './EtherInput';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {
  validateName,
  validateEmail,
  validateMessage,
} from '../utils/common/inputValidation';
import etherFetch from '../utils/ether-fetch/etherFetch';

function postReportForm({reportData, name, email, message}) {
  return etherFetch('/messaging/report', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      reportData,
      name,
      email,
      message,
    },
  });
}

export default function ReportModal({
  reportData,
  onClose,
  show,
  onSuccess,
  onFail,
}) {
  const {style} = useTheme(getThemedStyles);

  const [name, setName] = useState('');
  const [nameStatus, setNameStatus] = useState(STATUS.IDLE);

  const [email, setEmail] = useState('');
  const [emailStatus, setEmailStatus] = useState(STATUS.IDLE);

  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(STATUS.IDLE);

  const [submissionStatus, setSubmissionStatus] = useState(STATUS.IDLE);

  function downHandler({key}) {
    if (key === 'Enter') {
      onSubmit();
    }
    if (key === 'Escape') {
      onClose();
    }
  }
  const downHandlerRef = useRef(downHandler);
  downHandlerRef.current = downHandler;
  useEffect(() => {
    const onDown = downHandlerRef.current;
    window.addEventListener('keydown', onDown);
    return () => {
      window.removeEventListener('keydown', onDown);
    };
  }, []);

  function onChangeName(newName) {
    setName(newName);
    const isValid = validateName(newName);
    setNameStatus(isValid ? STATUS.VALID : STATUS.IDLE);
  }

  function onChangeEmail(newEmail) {
    setEmail(newEmail);
    const isValid = validateEmail(newEmail);
    setEmailStatus(isValid ? STATUS.VALID : STATUS.IDLE);
  }

  function onChangeMessage(newMessage) {
    setMessage(newMessage);
    const isValid = validateMessage(message);
    setMessageStatus(isValid ? STATUS.VALID : STATUS.IDLE);
  }

  function onSubmit() {
    const newNameStatus =
      nameStatus !== STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    setNameStatus(newNameStatus);

    const newEmailStatus =
      emailStatus !== STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    setEmailStatus(newEmailStatus);

    const newMessageStatus =
      messageStatus !== STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    setMessageStatus(newMessageStatus);

    // Bail if form invalid
    if (
      newNameStatus !== STATUS.VALID ||
      newEmailStatus !== STATUS.VALID ||
      newMessageStatus !== STATUS.VALID
    ) {
      return;
    }

    setSubmissionStatus(STATUS.BUSY);

    postReportForm({reportData, name, email, message})
      .then(() => {
        setSubmissionStatus(STATUS.VALID);

        // Blank out all fields
        setName('');
        setNameStatus(STATUS.IDLE);
        setEmail('');
        setEmailStatus(STATUS.IDLE);
        setMessage('');
        setMessageStatus(STATUS.IDLE);
      })
      .catch((error) => {
        console.error('Error:', error);
        onFail();
      })
      .then(() => {
        onSuccess();
      });
  }

  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent={false}
      visible={show}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <TouchableOpacity
        style={style.modalBackdrop}
        onPress={onClose}
        activeOpacity
      />
      <View style={style.modalContainer}>
        <View style={style.modal}>
          <Text style={style.headerText}>Having trouble?</Text>
          <Text style={style.subText}>Tell us what's happening.</Text>
          <View style={style.formRow}>
            <EtherInput
              title="Name*"
              value={name}
              onChangeText={onChangeName}
              status={nameStatus}
            />
            <EtherInput
              title="Email*"
              value={email}
              onChangeText={onChangeEmail}
              status={emailStatus}
            />
          </View>
          <EtherInput
            style={style.inputMessage}
            inputStyle={style.inputMessage}
            title="Message*"
            value={message}
            multiline
            onChangeText={onChangeMessage}
            status={messageStatus}
          />
          <View style={style.modalRow}>
            <EtherButton
              style={style.closeButton}
              onPress={onClose}
              disabled={submissionStatus === STATUS.BUSY}
            >
              <Text style={style.closeText}>Cancel</Text>
            </EtherButton>
            <EtherButton
              onPress={onSubmit}
              style={style.modalButton}
              status={submissionStatus}
            >
              <Text style={style.modalButtonText}>Submit</Text>
            </EtherButton>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  closeButton: {
    backgroundColor: theme.BGFIRST,
    width: 80,
    marginRight: 15,
  },
  closeText: {
    fontSize: fontSize.legal,
    color: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  formRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mobile: {
      flexDirection: 'column',
    },
  },
  headerText: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 5,
    textAlign: 'center',
  },
  inputMessage: {
    paddingTop: 10,
    height: 200,
    width: '100%',
    maxWidth: 400,
    minWidth: 150,
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 20,
  },
  modalBackdrop: {
    inset: 0,
    position: 'absolute',
    backgroundColor: theme.BLACKGROUND,
  },
  modalButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    width: 80,
  },
  modalButtonText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  modalContainer: {
    margin: 'auto',
    width: '100%',
    maxWidth: 425,
  },
  modalRow: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    textAlign: 'center',
  },
});
