import {oneOf, shape, string} from 'prop-types';
import React, {useEffect, useState, useRef} from 'react';
import toast from 'react-hot-toast';
import {Pressable, View, Text, Modal} from 'react-native';

import EtherButton from '../components/EtherButton';
import EtherInput from '../components/EtherInput';
import {useAssets} from '../context/AssetsContext';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';

EventInfoModal.propTypes = {
  mode: oneOf(['add', 'delete', 'new']),
  event: shape({
    name: string.isRequired,
    location: string.isRequired,
  }),
};

function InfoScreen({name, setName, status, location, setLocation, onSave}) {
  // Focus on first text input when shown
  const eventNameRef = useRef(null);
  useEffect(() => {
    if (eventNameRef.current) {
      eventNameRef.current.focus();
    }
  }, []);

  return (
    <>
      <EtherInput
        onChangeText={(text) => setName(text)}
        value={name}
        title="Event Name"
        status={status}
        ref={eventNameRef}
        onSubmitEditing={onSave}
      />
      <EtherInput
        onChangeText={(text) => setLocation(text)}
        value={location}
        title="Event Location"
        status={status}
        onSubmitEditing={onSave}
      />
    </>
  );
}

function DeleteScreen() {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.modeTextContainer}>
      <Text style={style.modeHeader}>Are you sure?</Text>
      <Text style={style.modeSubText}>
        This action is permanent. <br />
        All content for this event will be deleted.
      </Text>
    </View>
  );
}

function Buttons({onPressClose, mode, status, onSave, onDelete}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.buttonContainer}>
      <EtherButton
        style={style.cancelButton}
        onPress={onPressClose}
        status={status}
      >
        <Text style={style.cancelText}>Cancel</Text>
      </EtherButton>
      {mode !== 'delete' ? (
        <EtherButton style={style.saveButton} onPress={onSave} status={status}>
          <Text style={style.buttonText}>Save</Text>
        </EtherButton>
      ) : (
        <EtherButton
          style={style.deleteButton}
          onPress={onDelete}
          status={status}
        >
          <Text style={style.buttonText}>Delete</Text>
        </EtherButton>
      )}
    </View>
  );
}

export default function EventInfoModal({event, mode, onPressClose = () => {}}) {
  const {createEvent, deleteEvent} = useAssets();
  const [location, setLocation] = useState(
    mode !== 'new' ? event.location : '',
  );
  const [name, setName] = useState(mode !== 'new' ? event.name : '');
  const [status, setStatus] = useState(STATUS.IDLE);
  const {style} = useTheme(getThemedStyles);
  function onSave() {
    if (mode === 'new') {
      if (name.length < 1) {
        toast.error('Please enter an event name');
        return;
      }

      createEvent(name, location).then(() => {
        onPressClose();
        setStatus(STATUS.IDLE);
      });
    }
  }

  function onDelete() {
    setStatus(STATUS.BUSY);
    deleteEvent(event.name)
      .then(() => setStatus(STATUS.IDLE))
      .then(onPressClose)
      .catch(console.error);
  }

  return (
    <Modal
      style={style.modal}
      animationType="fade"
      transparent
      ariaHideApp={false}
    >
      <Pressable style={style.outerArea} onPress={onPressClose}>
        <Pressable
          style={
            mode === 'new' ? style.newEventContainer : style.renameContainer
          }
        >
          {mode === 'new' || mode === 'rename' ? (
            <InfoScreen
              name={name}
              setName={setName}
              status={status}
              location={location}
              setLocation={setLocation}
              onSave={onSave}
            />
          ) : null}
          {mode === 'delete' ? <DeleteScreen /> : null}
          <Buttons
            onPressClose={onPressClose}
            mode={mode}
            status={status}
            onSave={onSave}
            onDelete={onDelete}
          />
        </Pressable>
      </Pressable>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    textAlign: 'center',
  },
  cancelButton: {
    borderColor: theme.FIRST,
    backgroundColor: theme.LIGHT,
    marginRight: 15,
    width: 80,
  },
  cancelText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.FIRST,
    textAlign: 'center',
  },
  deleteButton: {
    width: 80,
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modeHeader: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 20,
  },
  modeSubText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginBottom: 20,
  },
  modeTextContainer: {
    alignSelf: 'center',
    height: 'auto',
  },
  newEventContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    margin: 'auto',
    borderRadius: 5,
    height: 300,
    width: 400,
    padding: 20,
    cursor: 'default',
  },
  outerArea: {
    backgroundColor: theme.BLACKGROUND,
    flex: 1,
  },
  renameContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    margin: 'auto',
    borderRadius: 5,
    height: 250,
    width: 460,
    padding: 20,
    cursor: 'default',
  },
  saveButton: {
    width: 80,
  },
});
