import {string, func, shape, object, arrayOf, number} from 'prop-types';
import React, {useState} from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import FaIcon from './FaIcon';

MenuItem.propTypes = {
  item: shape({
    title: string.isRequired,
    faIcon: object.isRequired,
    callback: func.isRequired,
  }).isRequired,
  index: number.isRequired,
};

function MenuItem({item, index, style: overrides}) {
  const [mouseHover, setMouseHover] = useState();
  const {style, values} = useTheme(getThemedStyles);

  const active = mouseHover === index;

  if (item.show === false) {
    return null;
  }

  return (
    <TouchableOpacity
      style={[style.menuItem, active ? style.highlight : null, overrides]}
      onPress={item.callback}
      onMouseEnter={() => setMouseHover(index)}
      onMouseLeave={() => setMouseHover(null)}
    >
      <FaIcon
        icon={item.faIcon}
        size={20}
        color={active ? values.LIGHT : values.SECOND}
      />
      <Text style={[style.menuText, active ? style.menuTextHighlight : null]}>
        {item.title}
      </Text>
    </TouchableOpacity>
  );
}

SimpleDropdown.propTypes = {
  entries: arrayOf(
    shape({
      title: string.isRequired,
      faIcon: object.isRequired,
      callback: func.isRequired,
    }),
  ),
  style: object.isRequired,
};
export default function SimpleDropdown({
  entries,
  style: overrides,
  menuItemStyle,
}) {
  return (
    <View style={overrides}>
      <FlatList
        data={entries}
        numColumns={1}
        keyExtractor={(item) => item.title}
        listKey={(item) => item.title}
        renderItem={({item, index}) => (
          <MenuItem style={menuItemStyle} item={item} index={index} />
        )}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  highlight: {
    backgroundColor: theme.SECOND,
  },
  menuItem: {
    height: 30,
    padding: 5,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    marginLeft: 5,
    color: theme.DARK,
  },
  menuTextHighlight: {
    color: theme.LIGHT,
  },
});
