import {FontAwesomeIcon as FontAwesomeReact} from '@fortawesome/react-fontawesome';
import {FontAwesomeIcon as FontAwesomeNative} from '@fortawesome/react-native-fontawesome';
import {number, object} from 'prop-types';
import React from 'react';
import {Platform, StyleSheet} from 'react-native';

import {PLATFORMS} from '../utils/common/constants';

FaIcon.propTypes = {
  size: number,
  icon: object,
};
export default function FaIcon({size, style, icon, ...props}) {
  if (!icon) return null;

  if (Platform.OS === PLATFORMS.web) {
    const webStyles = StyleSheet.flatten([style, {width: size, height: size}]);
    return <FontAwesomeReact icon={icon} {...props} style={webStyles} />;
  }

  return <FontAwesomeNative icon={icon} {...props} size={size} style={style} />;
}
