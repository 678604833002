import {useEffect, useRef, useState, useCallback} from 'react';
import {Dimensions} from 'react-native';

/*
 *Fade in animation
 */
export function useFadeIn(ref) {
  const [animate, setAnimate] = useState(false);
  /**
   * {React.createRef()} - ref to a dom element
   */
  const fadeIn = useCallback(() => {
    const keyframes = [{opacity: '0'}, {opacity: '1'}];

    const animDuration = {
      duration: 2000,
      iterations: 1,
    };

    ref.current.animate(keyframes, animDuration);
  }, [ref]);

  useEffect(() => {
    if (animate) {
      fadeIn(ref);
      setAnimate(false);
    }
  }, [animate, fadeIn, ref]);

  return () => setAnimate(true);
}

/* Provides object {fontScale: #, width: #, height: #, scale: #, isLandscape: bool, isMobvile: bool}
 *
 * Hook inspired by: https://www.reactnativeschool.com/building-a-dimensions-hook-in-react-native
 * Dimenions.get('screen') vs get('window') https://stackoverflow.com/questions/44978804/whats-the-difference-between-window-and-screen-in-the-dimensions-api
 */
export function useWindowDimensions() {
  const [windowDims, setScreenData] = useState(Dimensions.get('window'));
  const lastUpdate = useRef(0);

  useEffect(() => {
    function onChange(result) {
      // Update a maximum of 4x/sec
      if (lastUpdate.current < performance.now() - 250) {
        setScreenData(result.window);
        lastUpdate.current = performance.now();
      }
    }

    const {remove} = Dimensions.addEventListener('change', onChange);
    return remove;
  }, []);

  return {
    ...windowDims,
    isLandscape: windowDims.width > windowDims.height,
    isMobile: windowDims.width <= 768,
  };
}

/**
 *
 * @param {Object} windowDims - Window dims from react.Dimensions
 * @returns - If it's mobile or not
 */
function isMobileWindow(windowDims) {
  const {width} = windowDims;
  const result = width <= 768;
  return result;
}

/**
 *
 * @returns - if it's mobile; updates on breakpoint change
 */
export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(
    isMobileWindow(Dimensions.get('window')),
  );

  useEffect(() => {
    function onChange(result) {
      const freshIsMobile = isMobileWindow(result.window);
      if (freshIsMobile !== isMobile) {
        setIsMobile(freshIsMobile);
      }
    }

    const {remove} = Dimensions.addEventListener('change', onChange);
    return remove;
  }, [isMobile]);

  return {isMobile};
}
