// Initalize error and session recording
export async function setupLogrocketWeb() {
  const LogRocket = await import('logrocket');
  const setupLogRocketReact = await import('logrocket-react');
  LogRocket.init('wjcrbv/ether-web', {
    network: {
      requestSanitizer: (req) => {
        // Scrub passwords
        const passwordUrlParts = ['signup', 'login', 'resetpassword'];
        const lowercaseUrl = req.url.toLowerCase();
        if (passwordUrlParts.some((part) => lowercaseUrl.includes(part))) {
          let isJsonBody;
          try {
            JSON.parse(req.body);
            isJsonBody = true;
          } catch {
            isJsonBody = false;
          }

          if (isJsonBody) {
            const parsedBody = JSON.parse(req.body);
            parsedBody.password = 'REDACTED';
            req.body = JSON.stringify(parsedBody);
          }
        }

        return req;
      },
    },
  });
  setupLogRocketReact(LogRocket);
}

/**
 * Clear any user session data stored in localStorage.
 */
export function clearLocalStorageSessionInfo() {
  localStorage.token = '';
  localStorage.username = '';
  localStorage.userID = '';
}

/**
 * Check if an image is cached by the browser.
 *
 * @param src - url of an image to check
 * @returns {boolean}
 */
export function imgIsCached(src) {
  const image = new window.Image();
  image.src = src;
  return image.complete;
}

/**
 * Cache an image, do something when it loads in.
 * Returns almost instantly if already cached.
 * @param src img url to cache
 * @param cb callback to run when image is loaded
 * @returns {*}
 */
export function imgOnLoad(src, cb) {
  const image = new window.Image();
  image.src = src;
  image.onload = cb;
}
