import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import {Text, View} from 'react-native-web';

import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {createStripeAccount} from '../../utils/common/orders';

function openNewTab(url) {
  window.open(url, '_blank');
}

export function StripeQuarter({stripeId, stripeAccountUrl}) {
  const {style} = useTheme(getThemedStyles);
  const [stripeLoading, setStripeLoading] = useState(false);

  function connectStripe() {
    setStripeLoading(true);
    createStripeAccount().then(({signUpUrl}) => {
      setStripeLoading(false);
      window.open(signUpUrl.url, '_self');
    });
  }

  return (
    <View style={style.quarter}>
      {stripeId ? (
        <>
          <Text style={style.stripeTextDark}>Stripe Connected</Text>
          <EtherButton
            style={style.stripeLink}
            onPress={() => !stripeLoading && openNewTab(stripeAccountUrl)}
            status={stripeLoading ? STATUS.BUSY : STATUS.IDLE}
          >
            <Text style={style.stripeText}>
              Manage Payments
              <FaIcon style={style.navIcon} icon={faSignInAlt} size={14} />
            </Text>
          </EtherButton>
        </>
      ) : (
        <EtherButton
          style={style.stripeButton}
          status={stripeLoading ? STATUS.BUSY : STATUS.IDLE}
          onPress={connectStripe}
        >
          <Text style={style.stripeText}>Connect stripe</Text>
        </EtherButton>
      )}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  navIcon: {
    marginLeft: 5,
  },
  quarter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
  },
  stripeButton: {
    fontSize: fontSize.legal,
    borderRadius: 5,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.FIRST,
    backgroundColor: theme.SECOND,
    padding: 10,
    width: 120,
  },
  stripeLink: {
    padding: 5,
    marginTop: 20,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    textDecoration: 'none',
    color: theme.DARK,
  },
  stripeText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  stripeTextDark: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
});
