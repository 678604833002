import React, {useState} from 'react';
import {View, Text} from 'react-native';

import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {
  validateName,
  validateEmail,
  validateMessage,
} from '../../utils/common/inputValidation';
import etherFetch from '../../utils/ether-fetch/etherFetch';

function postContactForm({email, name, message}) {
  return etherFetch('/messaging/contact', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      email,
      name,
      message,
    },
  });
}

export default function ContactPage() {
  const {style} = useTheme(getThemedStyles);

  const [email, setEmail] = useState('');
  const [emailStatus, setEmailStatus] = useState(STATUS.IDLE);

  const [name, setName] = useState('');
  const [nameStatus, setNameStatus] = useState(STATUS.IDLE);

  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(STATUS.IDLE);

  const [submissionStatus, setSubmissionStatus] = useState(STATUS.IDLE);

  function onChangeName(newName) {
    setName(newName);
    const isValid = validateName(newName);
    setNameStatus(isValid ? STATUS.VALID : STATUS.IDLE);
  }

  function onChangeEmail(newEmail) {
    setEmail(newEmail);
    const isValid = validateEmail(newEmail);
    setEmailStatus(isValid ? STATUS.VALID : STATUS.IDLE);
  }

  function onChangeMessage(newMessage) {
    setMessage(newMessage);
    const isValid = validateMessage(message);
    setMessageStatus(isValid ? STATUS.VALID : STATUS.IDLE);
  }

  function postContact() {
    const newNameStatus =
      nameStatus !== STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    setNameStatus(newNameStatus);

    const newEmailStatus =
      emailStatus !== STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    setEmailStatus(newEmailStatus);

    const newMessageStatus =
      messageStatus !== STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    setMessageStatus(newMessageStatus);

    // Bail if form invalid
    if (
      newNameStatus !== STATUS.VALID ||
      newEmailStatus !== STATUS.VALID ||
      newMessageStatus !== STATUS.VALID
    ) {
      return;
    }

    setSubmissionStatus(STATUS.BUSY);

    postContactForm({email, name, message})
      .then(() => {
        setSubmissionStatus(STATUS.VALID);

        // Blank out all fields
        setName('');
        setNameStatus(STATUS.IDLE);
        setEmail('');
        setEmailStatus(STATUS.IDLE);
        setMessage('');
        setMessageStatus(STATUS.IDLE);
      })
      .catch((error) => {
        console.error('Error:', error);
        setSubmissionStatus(STATUS.INVALID);
      })
      .finally(() => {
        setTimeout(() => setSubmissionStatus(STATUS.IDLE), 3000);
      });
  }
  return (
    <View style={style.container}>
      <View style={style.header}>
        <Text style={style.textHeader}>Contact Us</Text>
        <Text style={style.subText}>
          We will get back to you as soon as possible
        </Text>
      </View>
      <View style={style.body}>
        <View style={style.topRow}>
          <EtherInput
            value={name}
            onChangeText={onChangeName}
            style={style.input}
            title="Name"
            status={nameStatus}
          />
          <EtherInput
            value={email}
            onChangeText={onChangeEmail}
            style={style.input}
            title="Email"
            status={emailStatus}
          />
        </View>
        <EtherInput
          value={message}
          inputStyle={style.inputMessage}
          multiline
          numberOfLines={10}
          onChangeText={onChangeMessage}
          title="Message  (30 characters minimum)"
          status={messageStatus}
        />
        <View>
          <EtherButton
            onPress={postContact}
            style={style.button}
            status={submissionStatus}
          >
            <Text style={style.buttonText}>
              {submissionStatus === STATUS.VALID ? 'Sent!' : 'Send'}
            </Text>
          </EtherButton>
        </View>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  body: {
    padding: 20,
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderTopWidth: 0,
    borderBottomEndRadius: 5,
    borderBottomStartRadius: 5,
  },
  button: {
    marginTop: 20,
    width: '70%',
    alignSelf: 'center',
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  container: {
    marginTop: 50,
    width: '100%',
    maxWidth: 740,
    minWidth: 350,
    alignSelf: 'center',
  },
  header: {
    padding: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 120,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderTopStartRadius: 5,
    borderTopEndRadius: 5,
  },
  input: {
    width: '48%',
  },
  inputMessage: {
    paddingTop: 5,
    height: 200,
  },
  subText: {
    fontFamily: 'NotoSans_Regular',
    textAlign: 'center',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  textHeader: {
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    fontSize: fontSize.header,
    color: theme.LIGHT,
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
