import {faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {func, number} from 'prop-types';
import React from 'react';
import {View, Text} from 'react-native';

import EtherButton from '../../components/EtherButton';
import {useTheme} from '../../context/ThemeContext';
import FaIcon from './../FaIcon';

PageMenu.propTypes = {
  onNext: func,
  onPrev: func,
  page: number.isRequired,
};
export default function PageMenu({onNext, onPrev, page}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <View style={style.bottomBarItems}>
      <View style={{flex: 1}}>
        {onPrev ? (
          <EtherButton style={{borderColor: values.SECOND}} onPress={onPrev}>
            <FaIcon icon={faArrowLeft} color={values.BGSECOND} size={25} />
          </EtherButton>
        ) : null}
      </View>
      <View style={{flex: 1}}>
        <Text style={style.counter}>{page}</Text>
      </View>
      <View style={{flex: 1}}>
        {onNext ? (
          <EtherButton style={{borderColor: values.SECOND}} onPress={onNext}>
            <FaIcon icon={faArrowRight} color={values.BGSECOND} size={25} />
          </EtherButton>
        ) : null}
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  bottomBarItems: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 86,
  },

  counter: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    backgroundColor: theme.BGSECOND,
    color: theme.DARK,
    padding: 5,
    borderRadius: 5,
    borderColor: theme.FIRST,
    borderWidth: 2,
    textAlign: 'center',
  },
});
