import {func, string, bool} from 'prop-types';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, Switch} from 'react-native';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import {useAdminData} from '../../context/AdminContext';
import {useTheme} from '../../context/ThemeContext';

const HeaderMessages = [
  'Admins only',
  'Type the magic word: sudo rm -rf ',
  '0xDEADBEEF',
  'Password, please!',
  'Warning: Trespassers will be [redacted]',
  'Admin zone ahead',
  '↑ ↑ ↓ ↓ ← → ← → B A',
  'while(!asleep) sheep++',
  'hunter2',
];

const randomIndex = Math.floor(Math.random() * HeaderMessages.length);
const randomMessage = HeaderMessages[randomIndex];

function RandomHeaderText() {
  const {style} = useTheme(getThemedStyles);

  return <Text style={style.mainHeader}>{randomMessage}</Text>;
}

Divider.propTypes = {
  header: string.isRequired,
  description: string.isRequired,
};

function Divider({header, description}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={{alignItems: 'center', marginVertical: 20}}>
      <Text style={style.dividerText}>{header}</Text>
      <Text style={[style.text, {textAlign: 'center'}]}>{description}</Text>
      <View style={style.dividerLine} />
    </View>
  );
}

Toggleable.propTypes = {
  text: string.isRequired,
  description: string.isRequired,
  onToggle: func,
  value: bool,
};

function Toggleable({text, description, onToggle, value}) {
  const {style, values} = useTheme(getThemedStyles);
  return (
    <>
      <View style={style.toggleable}>
        <Text style={style.header}>{text}</Text>
        <Switch
          trackColor={{false: values.FIRST, true: values.GREEN}}
          thumbColor={values.BGFIRST}
          activeThumbColor={values.FIRST}
          onValueChange={onToggle}
          value={value}
        />
      </View>
      <Text style={style.descriptionText}>{description}</Text>
    </>
  );
}

InputField.propTypes = {
  text: string.isRequired,
  description: string.isRequired,
  message: string,
  onChangeMessage: func,
};

function InputField({text, description, message, onChangeMessage}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <>
      <View style={style.inputField}>
        <Text style={[style.header, {marginBottom: 10}]}>{text}</Text>
        <Text style={[style.text, {marginBottom: 20}]}>{description}</Text>
        <EtherInput
          value={message}
          inputStyle={style.inputMessage}
          multiline
          numberOfLines={10}
          onChangeText={onChangeMessage}
        />
      </View>
    </>
  );
}

DropdownMenu.propTypes = {
  text: string.isRequired,
  description: string.isRequired,
  selected: string,
  setSeleted: func,
  creatable: bool,
};

function DropdownMenu({
  text,
  description,
  selected,
  setSelected,
  creatable = false,
}) {
  const {style, values} = useTheme(getThemedStyles);

  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ];

  const customStyle = {
    option: (provided, state) => {
      return {
        ...provided,
        fontFamily: 'NotoSans_Bold',
        fontSize: 12,
        color: state.isSelected
          ? values.LIGHT
          : state.isFocused
          ? values.LIGHT
          : values.DARK,
        backgroundColor: state.isSelected
          ? values.FIRST
          : state.isFocused
          ? values.SECOND
          : values.BGSECOND,
        padding: 10,
        ':active': {
          color: 'white',
          backgroundColor:
            !state.isDisabled &&
            (state.isSelected ? values.SECOND : values.FIRST),
        },
      };
    },
    control: (provided) => {
      return {
        ...provided,
        maxWidth: '100%',
        fontFamily: 'NotoSans_Regular',
        fontSize: 12,
        borderColor: values.FIRST,
        borderWidth: 2,
        boxShadow: 'none',
        ':hover': {
          borderColor: values.FIRST,
        },
      };
    },
  };

  return (
    <View style={[style.dropdown, {zIndex: 9999}]}>
      <Text style={[style.header, {marginBottom: 10}]}>{text}</Text>
      <Text style={[style.text, {marginBottom: 20}]}>{description}</Text>
      {creatable ? (
        <Creatable
          isMulti
          options={options}
          onChange={setSelected}
          value={selected}
          styles={customStyle}
          noOptionsMessage={() => <Text>No options</Text>}
        />
      ) : (
        <Select
          isMulti
          options={options}
          onChange={setSelected}
          value={selected}
          styles={customStyle}
          noOptionsMessage={() => <Text>No options</Text>}
        />
      )}
    </View>
  );
}

export default function AdminPanel() {
  const {style} = useTheme(getThemedStyles);
  const {adminData, save} = useAdminData();

  const [skipStripe, setSkipStripe] = useState(false);
  const [galleryCart, setGalleryCart] = useState(false);
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState(
    'We cannot process orders right now',
  );
  const [signUpAccessCode, setSignUpAccessCode] = useState(
    'NASPhotoViewer2022!',
  );

  useEffect(() => {
    setSkipStripe(adminData?.skipStripe || false);
    setGalleryCart(adminData?.galleryCart || false);
    setCheckoutErrorMessage(
      adminData?.checkoutErrorMessage || 'We cannot process orders right now',
    );
    setSignUpAccessCode(adminData?.signUpAccessCode || 'NASPhotoViewer2022!');
  }, [adminData]);

  function handleSave() {
    save({
      skipStripe,
      galleryCart,
      checkoutErrorMessage,
      signUpAccessCode,
    }).then((res) => toast.success(res.msg || 'Changes Saved.'));
  }

  return (
    <View style={style.main}>
      <RandomHeaderText />
      <View style={style.body}>
        <EtherButton onPress={handleSave}>
          <Text style={style.buttonText}>Save Changes</Text>
        </EtherButton>
        <Divider
          header="Section One: Karl Marx"
          description="The last capitalist we hang shall be the one who sold us the rope."
        />
        <Toggleable
          text="Skip Stripe"
          description="Allow new users to skip the Stripe linking process."
          value={skipStripe}
          onToggle={setSkipStripe}
        />
        <Toggleable
          text="Gallery Cart"
          description="Enable or disable the gallery cart feature."
          value={galleryCart}
          onToggle={setGalleryCart}
        />
        <InputField
          text="Checkout Error Message:"
          description="The error message shown to the user if there is a problem at checkout."
          message={checkoutErrorMessage}
          onChangeMessage={setCheckoutErrorMessage}
        />
        <InputField
          text="Sign Up Access Code:"
          description="The access code required at sign up."
          message={signUpAccessCode}
          onChangeMessage={setSignUpAccessCode}
        />
        <Divider
          header="Extra Stuff"
          description="Work in progress/unused components"
        />
        <DropdownMenu
          creatable
          text="I don't really know what goes on dropdowns"
          description="But whenever we figure it out, it will look kinda like this"
        />
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  body: {
    width: 550,
    maxWidth: 550,
    padding: 40,
    margin: 40,
    borderRadius: 20,
    borderWidth: 4,
    borderColor: theme.FIRST,
    backgroundColor: theme.BGFIRST,
    mobile: {
      width: 340,
      padding: 20,
    },
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    borderColor: theme.DARK,
  },
  descriptionText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    color: theme.DARK,
    maxWidth: 360,
    marginBottom: 20,
    mobile: {
      maxWidth: 220,
    },
  },
  dividerLine: {
    marginTop: 20,
    width: '100%',
    height: 1,
    backgroundColor: theme.FIRST,
  },
  dividerText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 20,
    color: theme.DARK,
  },
  dropdown: {
    marginVertical: 20,
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 16,
    color: theme.DARK,
  },
  inputField: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  inputMessage: {
    paddingTop: 5,
    height: 36,
  },
  main: {
    padding: 40,
    alignItems: 'center',
  },
  mainHeader: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 32,
    color: theme.DARK,
  },
  text: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    color: theme.DARK,
  },
  toggleable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
});
