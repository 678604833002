import etherFetch from '../ether-fetch/etherFetch';

export function logOut() {
  return etherFetch('/auth/logout').then((res) => res.json());
}

export function logIn(username, password) {
  return etherFetch('/auth/login', {
    method: 'POST',
    body: {
      username,
      password,
    },
  }).then((res) => res.json());
}
