import {faCaretDown, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import React, {useState, useEffect} from 'react';
import {View, Text, FlatList, TouchableOpacity} from 'react-native';

import FaIcon from '../../components/FaIcon';
import {useTheme} from '../../context/ThemeContext';
import {useIsMobile} from '../../utils/common/hooks';
import {QAData} from './QAData';

function QAItem({style, item}) {
  const {isMobile} = useIsMobile();
  const [collapsed, setCollapsed] = useState(isMobile);

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  return (
    <TouchableOpacity
      style={style.QARow}
      onPress={isMobile ? () => setCollapsed(!collapsed) : () => {}}
      activeOpacity={1}
    >
      <View style={style.qTextRow}>
        {isMobile ? (
          <FaIcon
            icon={collapsed ? faCaretRight : faCaretDown}
            size={20}
            style={style.caret}
          />
        ) : null}
        <Text style={style.qText}>{item.Q}</Text>
      </View>
      {collapsed ? null : <Text style={style.aText}>{item.A}</Text>}
    </TouchableOpacity>
  );
}

export default function FAQ({navigation}) {
  const {navigate} = navigation;
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.main}>
      <Text style={style.header}>Frequently Asked Questions</Text>
      <Text style={style.subHeader}>
        We care about your input! If your question isn't answered here, feel
        free to reach out!
      </Text>
      <View style={style.QAContainer}>
        <FlatList
          data={QAData}
          keyExtractor={(item) => item.Q}
          renderItem={({item}) => <QAItem style={style} item={item} />}
        />
      </View>
      <Text style={style.subHeader}>
        Still have questions? Feel free to reach out with the button below.
      </Text>
      <TouchableOpacity
        style={style.button}
        onPress={() => navigate('Contact')}
      >
        <Text style={style.buttonText}>Contact Us</Text>
      </TouchableOpacity>
    </View>
  );
}

const getThemedStyles = (theme) => ({
  aText: {
    fontSize: 16,
    fontFamily: 'NotoSans_Regular',
    margin: 5,
    marginLeft: 20,
    color: theme.DARK,
  },
  button: {
    alignSelf: 'center',
    marginBottom: 40,
    height: 60,
    width: 180,
    borderRadius: 10,
    borderColor: theme.FIRST,
    borderWidth: 2,
    backgroundColor: theme.BGSECOND,
  },
  buttonText: {
    padding: 15,
    fontFamily: 'NotoSans_Bold',
    fontSize: 18,
    color: theme.FIRST,
    textAlign: 'center',
  },
  caret: {
    marginTop: 5,
    marginRight: 10,
    color: theme.FIRST,
  },
  header: {
    marginTop: 20,
    fontSize: 35,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    padding: 10,
    color: theme.DARK,
  },
  QAContainer: {
    alignSelf: 'center',
    borderWidth: 2,
    borderColor: theme.SECOND,
    borderRadius: 5,
    width: '85%',
    backgroundColor: theme.BGFIRST,
    maxWidth: 1344,
    padding: 40,
    marginBottom: 20,
    mobile: {
      padding: 10,
    },
  },
  QARow: {
    margin: 15,
  },
  qText: {
    fontSize: 20,
    fontFamily: 'NotoSans_Bold',
    color: theme.FIRST,
  },
  qTextRow: {
    flexDirection: 'row',
    marginBot: 5,
  },
  subHeader: {
    marginBottom: 20,
    fontSize: 16,
    fontFamily: 'NotoSans_Regular',
    textAlign: 'center',
    padding: 10,
    color: theme.DARK,
  },
});
