import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/admin-config';

/**
 * @returns {Object}
 */
export async function getAdminData() {
  return etherFetch(BASE_ROUTE).then((res) => res.json());
}

/**
 * Update the admin data with the provided values.
 *
 * @param {Object} data - New admin data to save.
 * @returns void
 */
export async function saveAdminData(data) {
  return etherFetch(BASE_ROUTE, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {...data},
  }).then((res) => res.json());
}
