import {func, bool} from 'prop-types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import toast from 'react-hot-toast';
import {View, Text, TouchableOpacity, Modal} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import EtherButton from './EtherButton';
import EtherInput from './EtherInput';

FolderModal.proptypes = {
  show: bool.isRequired,
  onHide: bool.isRequired,
  onAddFolder: func.isRequired,
};
export function FolderModal({show, onHide, onAddFolder}) {
  const [folderName, setFolderName] = useState('');
  const inputRef = useRef(null);
  const {style} = useTheme(getThemedStyles);

  function onChangeText(input) {
    setFolderName(input);
  }

  useEffect(() => {
    if (show && inputRef.current) {
      // Wait for show animation to complete
      setTimeout(() => inputRef.current.focus(), 300);
    }
  }, [show]);

  const handleHide = useCallback(
    (...args) => {
      setFolderName('');
      onHide(...args);
    },
    [onHide],
  );

  const onPressAdd = useCallback(() => {
    if (folderName.length > 0) {
      onAddFolder(folderName);
      setFolderName('');
      onHide();
    } else {
      toast.error('Please enter a name for your pack');
    }
  }, [folderName, onAddFolder, onHide]);

  function onPressAddMore() {
    if (folderName.length > 0) {
      onAddFolder(folderName);
      setFolderName('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      toast.error('Please enter a name for your pack');
    }
  }

  const downHandler = useCallback(
    ({key}) => {
      if (key === 'Escape') {
        handleHide();
      }
      if (key === 'Enter') {
        onPressAdd();
      }
    },
    [handleHide, onPressAdd],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);

  return (
    <Modal visible={show} transparent animationType="fade" ariaHideApp={false}>
      <TouchableOpacity
        style={style.backdrop}
        onPress={handleHide}
        activeOpacity={1}
      >
        <TouchableOpacity style={style.dialog} activeOpacity={1}>
          <EtherInput
            value={folderName}
            onChangeText={onChangeText}
            title="Add Pack"
            style={style.input}
            ref={inputRef}
            onKeyPress={downHandler}
            autoFocus
          />
          <View style={style.modalRow}>
            <EtherButton
              onPress={onHide}
              style={style.closeButton}
              status={STATUS.IDLE}
            >
              <Text style={style.closeText}>Close</Text>
            </EtherButton>
            <EtherButton
              onPress={onPressAdd}
              style={style.addButton}
              status={STATUS.IDLE}
            >
              <Text style={style.addText}>Add</Text>
            </EtherButton>
            <EtherButton
              onPress={onPressAddMore}
              style={style.addMoreButton}
              status={STATUS.IDLE}
            >
              <Text style={style.addText}>Add more</Text>
            </EtherButton>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export const getThemedStyles = (theme, fontSize) => ({
  addButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    width: 80,
    marginLeft: 15,
  },
  addMoreButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    marginLeft: 15,
    width: 112,
  },
  addText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    color: theme.LIGHT,
  },
  backdrop: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.BLACKGROUND,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    width: 80,
  },
  closeText: {
    fontSize: fontSize.legal,
    color: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  dialog: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    height: 210,
    width: 380,
    padding: 20,
    cursor: 'default',
  },
  input: {
    width: '90%',
  },
  modalRow: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
