import React from 'react';
import {View, Text, Image} from 'react-native';

import EtherButton from '../../components/EtherButton';
import {useTheme} from '../../context/ThemeContext';

export default function FourOhFour({navigation}) {
  const {style} = useTheme(getThemedStyles);
  const {navigate} = navigation;

  return (
    <View style={style.main}>
      <Text style={style.fourohfour}>404</Text>
      <Image style={style.image} source="https://placedog.net/300/300?random" />
      <Text style={style.text}>Hmm, you seem lost...</Text>
      <EtherButton style={style.button} onPress={() => navigate('Home')}>
        <Text style={style.buttonText}>Return to safety</Text>
      </EtherButton>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    marginTop: 30,
    height: 60,
    width: 180,
    borderRadius: 10,
    backgroundColor: theme.SECOND,
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    borderColor: theme.DARK,
  },
  fourohfour: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 93,
    color: theme.DARK,
  },
  image: {
    height: 300,
    width: 300,
    borderRadius: 15,
  },
  main: {
    padding: 40,
    alignItems: 'center',
  },
  text: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
    marginTop: 10,
  },
});
