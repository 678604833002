import {faCheck, faTrashAlt, faPlus} from '@fortawesome/free-solid-svg-icons';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState, useRef} from 'react';
import toast from 'react-hot-toast';
import {View, Text, FlatList} from 'react-native';

import AdminGalleryTile from './AdminGalleryTile.web';
import AssetViewer from './AssetViewer';
import CreateCartButton from './CreateCartButton.web';
import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import FileManagementMenu from './FileManagementMenu';
import DeletePackModal from './deletePackModal';
import Search from '../components/Search';
import UploadInfoMenu from '../components/UploadInfoMenu.web';
import Uploadzone from '../components/Uploadzone';
import {useAssets} from '../context/AssetsContext';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {ellipsify} from '../utils/common/funcs';
import {useWindowDimensions} from '../utils/common/hooks';

const TILE_HEIGHT = 200;
const TILE_WIDTH = 270;
const TILE_SPACING = 20;

export default function AdminGallery() {
  const {navigate} = useNavigation();
  const {width} = useWindowDimensions();
  const [numColumns, setNumColumns] = useState(4);
  useEffect(() => {
    // Calculate how many tiles fit
    const colCount = Math.floor(width / (TILE_WIDTH + TILE_SPACING));
    setNumColumns(colCount - 1 || 1);
  }, [width]);
  const narrowControlArea = numColumns <= 2;

  const galleryWidth =
    numColumns * TILE_WIDTH + (numColumns + 1) * TILE_SPACING;

  const {
    events,
    currentEvent,
    packs,
    currentPack,
    assets,
    deletePack,
    deleteAssets,
  } = useAssets();
  const [assetQuery, setAssetQuery] = useState('');
  const [selection, setSelection] = useState([]);
  const [lastIndex, setLastIndex] = useState(null);
  const [shiftHeld, setShiftHeld] = useState(false);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(STATUS.IDLE);
  const {style, values} = useTheme(getThemedStyles);
  const uploadInfo = useRef({});

  /**
   * Filter the uploadInfo entries.
   *
   * @param {function(asset): boolean} filter - Filter callback that receives an asset object.
   * A truthy return value will remove the asset from the uploadInfo.
   */
  function filterUploadInfo(filter) {
    const uploadInfoArr = Object.entries(uploadInfo.current);

    uploadInfoArr.forEach((entry) => {
      const key = entry[0];
      const asset = entry[1];

      const result = filter(asset);

      if (result) delete uploadInfo.current[key];
    });
  }

  const filteredAssets =
    assetQuery.length > 0
      ? assets.filter((asset) =>
          asset.name.toLowerCase().includes(assetQuery.toLowerCase()),
        )
      : assets;

  function toggleSelectAll() {
    const hasAllSelected = selection.length === filteredAssets.length;
    const newSelection = hasAllSelected ? [] : filteredAssets;
    setSelection(newSelection);
  }

  function onDelete() {
    setDeleteStatus(STATUS.BUSY);

    // Filtered asset mutates selection so it's up to date
    if (selection.length > 0) {
      const assetNames = selection.map((s) => s.name);

      assetNames.forEach((assetName) =>
        filterUploadInfo(
          (asset) =>
            asset.nameNoExt === assetName && asset.packName === currentPack,
        ),
      );

      deleteAssets(assetNames)
        .catch(() => {
          toast.error(`Failed to delete assets ${assetNames.join(', ')}`);
        })
        .finally(() => setDeleteStatus(STATUS.IDLE));
    } else {
      filterUploadInfo((asset) => asset.packName === currentPack);
      deletePack(currentEvent, currentPack)
        .catch(() => {
          toast.error(`Failed to delete pack ${currentPack}`);
        })
        .finally(() => setDeleteStatus(STATUS.IDLE));
    }
  }

  //Selection handler functions
  if (!selection.every((item) => filteredAssets.includes(item))) {
    setSelection(selection.filter((item) => filteredAssets.includes(item)));
  }

  function toggleSelect(item, index) {
    const isSelected = !!selection.find(
      (s) => s.original.url === item.original.url,
    );
    let itemsToAdd;

    if (shiftHeld) {
      if (index >= lastIndex) {
        itemsToAdd = filteredAssets.slice(lastIndex, index + 1);
      } else {
        itemsToAdd = filteredAssets.slice(index, lastIndex);
      }
    } else {
      itemsToAdd = [item];
    }
    if (isSelected) {
      let newSelection = selection;
      itemsToAdd.forEach(
        (i) =>
          (newSelection = newSelection.filter(
            (s) => s.original.url !== i.original.url,
          )),
      );
      setSelection(newSelection);
    } else {
      setSelection([...selection, ...itemsToAdd]);
    }
    setLastIndex(index);
  }

  function downHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
  }

  function upHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  function viewLeft() {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = filteredAssets.length - 1;
    }
    setCurrentAsset(filteredAssets[newIndex]);
    setCurrentIndex(newIndex);
  }

  function viewRight() {
    let newIndex = currentIndex + 1;
    if (newIndex > filteredAssets.length - 1) {
      newIndex = 0;
    }
    setCurrentAsset(filteredAssets[newIndex]);
    setCurrentIndex(newIndex);
  }

  //Help the user create events and packs.
  if (!events?.length) {
    return (
      <View style={style.emptyPageTextbox}>
        <Text style={style.emptyPageHeader}>
          Create an event to get started
        </Text>
        <EtherButton
          style={style.button}
          onPress={() => navigate('Event Manager')}
        >
          <Text style={style.buttonText}>Event Manager</Text>
        </EtherButton>
      </View>
    );
  }
  if (!currentEvent) {
    return (
      <View style={style.emptyPageTextbox}>
        <Text style={style.emptyPageHeader}>Select an event</Text>
        <EtherButton
          style={style.button}
          onPress={() => navigate('Event Manager')}
        >
          <Text style={style.buttonText}>Event Manager</Text>
        </EtherButton>
      </View>
    );
  }
  if (!packs?.length) {
    return (
      <View style={style.emptyPageTextbox}>
        <Uploadzone uploadInfo={uploadInfo} />
        <Text style={style.emptyPageHeader}>No packs found</Text>
        <Text style={style.emptyPageSubtext}>
          Click the folder icon to create a pack.
        </Text>
      </View>
    );
  }
  if (!currentPack) {
    return (
      <View style={style.emptyPageTextbox}>
        <Uploadzone uploadInfo={uploadInfo} />
        <Text style={style.emptyPageHeader}>No pack selected</Text>
        <Text style={style.emptyPageSubtext}>
          Choose a pack from the list on the left
        </Text>
      </View>
    );
  }

  const allSelected = selection.length === filteredAssets.length;

  return (
    <View style={style.mainView}>
      <Uploadzone uploadInfo={uploadInfo} />
      {Object.keys(uploadInfo.current).length !== 0 ? (
        <View style={style.infoMenuContainer}>
          <UploadInfoMenu
            uploadInfo={uploadInfo}
            inProgress={uploadInfo?.length}
          />
        </View>
      ) : null}
      <style type="text/css">
        {/* Prevent users from dragging elements in the admin gallery */}
        {`
          div {
            user-select: none;
          }
          input {
            user-select: text;
          }
        `}
      </style>
      <AssetViewer
        visible={!!currentAsset}
        asset={currentAsset}
        index={currentIndex}
        arrayLength={filteredAssets.length}
        onPressClose={() => setCurrentAsset(null)}
        onPressLeft={viewLeft}
        onPressRight={viewRight}
      />
      <DeletePackModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={onDelete}
      />
      <View style={style.galleryControlBar}>
        <View style={style.galleryControls}>
          <Text style={style.fileCountText}>
            {ellipsify(
              `${
                filteredAssets === assets
                  ? filteredAssets.length + ' files'
                  : filteredAssets.length + '/' + assets.length + ' files'
              }`,
              22,
            )}
          </Text>
          <Search
            placeholder="Filter by name"
            onChangeText={(value) => setAssetQuery(value)}
            style={style.searchBar}
            textboxStyle={style.searchBarTextbox}
          />
          <FileManagementMenu
            style={style.fileManagementMenu}
            narrow={narrowControlArea}
          >
            {assets.length ? <CreateCartButton /> : null}

            <FileManagementMenu.Button
              icon={faTrashAlt}
              text={selection.length ? 'Delete Selected' : 'Delete Pack'}
              onPress={() => setShowDeleteModal(true)}
              status={deleteStatus}
              style={style.deleteButton}
            />

            {assets.length ? (
              <FileManagementMenu.CheckBox
                onToggle={toggleSelectAll}
                text={allSelected ? 'Select none' : 'Select All'}
                icon={allSelected ? faCheck : null}
                style={style.checkBoxStyle}
              />
            ) : null}
          </FileManagementMenu>
          <Uploadzone
            uploadInfo={uploadInfo}
            buttonMode
            style={style.uploadButton}
          >
            <Text style={style.uploadButtonText}>Upload</Text>
          </Uploadzone>
        </View>
      </View>
      <div style={style.listContainer}>
        <div style={style.staticListContainer}>
          <FlatList
            data={[...filteredAssets, {name: 'Add Asset', addAsset: true}]}
            keyExtractor={(asset) => asset.name}
            numColumns={numColumns}
            key={numColumns}
            // Make the parent in charge of scrolling
            style={{overflow: 'hidden', paddingBottom: 20}}
            ListEmptyComponent={
              <Text style={style.noImages}>Drag and drop your stuff here!</Text>
            }
            renderItem={({item, index}) => {
              if (item.addAsset) {
                return (
                  <Uploadzone
                    uploadInfo={uploadInfo}
                    buttonMode
                    style={style.addSquare}
                  >
                    <Text style={style.addSquareText}>Upload</Text>
                    <FaIcon icon={faPlus} color={values.FIRST} size={35} />
                    <Text style={style.addSquareText}>
                      {ellipsify(currentEvent, 15)}
                    </Text>
                    <Text style={style.addSquareText}>
                      {ellipsify(currentPack, 15)}
                    </Text>
                  </Uploadzone>
                );
              }
              return (
                <AdminGalleryTile
                  style={[
                    style.galleryTile,
                    {height: TILE_HEIGHT, width: TILE_WIDTH},
                  ]}
                  source={item.thumb.url}
                  meta={item}
                  selected={selection.find(
                    (s) => s.original.url === item.original.url,
                  )}
                  onSelectPress={() => toggleSelect(item, index)}
                  onPress={() => {
                    setCurrentAsset(item);
                    setCurrentIndex(index);
                  }}
                  preDeleteCallback={() =>
                    filterUploadInfo(
                      (asset) =>
                        asset.nameNoExt === item.name &&
                        asset.packName === currentPack,
                    )
                  }
                />
              );
            }}
          />
          {assets?.length ? (
            <View style={[style.footer, {width: galleryWidth}]} />
          ) : null}
        </div>
      </div>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  addSquare: {
    width: 270,
    height: 200,
    margin: 20,
    borderColor: theme.FIRST,
    borderWidth: 4,
    borderRadius: 10,
    borderStyle: 'dashed',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addSquareText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.FIRST,
    marginLeft: 5,
    textAlign: 'center',
  },
  breakline: {
    width: 150,
    borderWidth: 1,
    borderColor: theme.BGFIRST,
  },
  breakpoint: {
    color: theme.BGFIRST,
  },
  button: {
    marginTop: 10,
    padding: 10,
  },
  buttonText: {
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  checkBoxStyle: {
    paddingRight: 20,
  },
  deleteButton: {
    width: 130,
  },
  emptyPageHeader: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  emptyPageSubtext: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  emptyPageTextbox: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '25vh',
  },
  fileCountText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    userSelect: 'text',
    marginRight: 10,
    mobile: {
      paddingRight: 10,
      paddingTop: 10,
    },
  },
  fileManagementMenu: {
    marginRight: 10,
    marginVertical: 10,
  },
  footer: {
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  galleryControlBar: {
    width: '100%',
    backgroundColor: theme.BGFIRST,
  },
  galleryControls: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    backgroundColor: theme.BGFIRST,
    alignItems: 'center',
    paddingLeft: 20,
    maxWidth: '100%',
  },
  galleryTile: {
    marginLeft: TILE_SPACING,
    marginTop: 20,
    zIndex: -10,
    position: 'relative',
  },
  infoMenuContainer: {
    bottom: 30,
    right: 30,
    position: 'absolute',
    zIndex: 1,
  },
  listContainer: {
    flex: 1,
    position: 'relative',
    overflowX: 'hidden',
  },
  mainView: {
    flex: 1,
  },
  noImages: {
    marginTop: 280,
    alignSelf: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
  },
  searchBar: {
    marginRight: 10,
    paddingVertical: 10,
    width: 250,
    mobile: {
      padding: 0,
      paddingVertical: 10,
    },
  },
  searchBarTextbox: {
    borderRadius: 15,
    borderWidth: 2,
    width: '100%',
  },
  staticListContainer: {
    position: 'absolute',
    inset: 0,
    overflow: 'hidden scroll',
  },
  uploadButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 10,
    height: 35,
    width: 85,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: 10,
    marginVertical: 10,
  },
  uploadButtonText: {
    fontSize: fontSize.body,
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
});
