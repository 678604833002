import {
  faHistory,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import {func, string, object} from 'prop-types';
import React, {useState, useRef, useEffect} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import FaIcon from '../components/FaIcon';
import {useTheme} from '../context/ThemeContext';

MenuItem.proptypes = {
  title: string.isRequired,
  onPress: func,
  icon: object.isRequired,
};
function MenuItem({title, onPress, icon}) {
  const {style} = useTheme(getThemedStyles);
  const [mouseHover, setMouseHover] = useState(false);
  return (
    <TouchableOpacity
      onMouseOver={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      style={[style.button, mouseHover ? style.buttonHighlighted : null]}
      onPress={onPress}
    >
      <FaIcon
        icon={icon}
        size={20}
        style={[style.icon, mouseHover ? style.highlightColor : null]}
      />
      <Text
        style={[style.buttonText, mouseHover ? style.highlightColor : null]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
}

function useOutsideAlerter(ref, onHide) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onHide();
        console.log(onHide);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onHide]);
}

AccountMenu.proptypes = {
  onPressAccount: func,
  onPressOrders: func,
  onPressLogOut: func,
};
export default function AccountMenu({
  onPressAccount = () => {},
  onPressOrders = () => {},
  onPressLogOut = () => {},
  onHide = () => {},
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onHide);
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={style.mainContainer} ref={wrapperRef}>
      <MenuItem
        icon={faUserCircle}
        title="Manage Account"
        onPress={onPressAccount}
      />
      <MenuItem icon={faHistory} title="Orders" onPress={onPressOrders} />
      <View style={style.separator} />
      <MenuItem icon={faSignOutAlt} title="Log out" onPress={onPressLogOut} />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    height: 35,
    marginVertical: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonHighlighted: {
    backgroundColor: theme.SECOND,
  },
  buttonText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
    marginLeft: 10,
  },
  highlightColor: {
    color: theme.LIGHT,
  },
  icon: {
    color: theme.DARK,
    marginLeft: 10,
  },
  mainContainer: {
    position: 'absolute',
    width: 200,
    marginTop: 48,
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    right: 0,
    paddingVertical: 5,
  },
  separator: {
    borderColor: theme.FIRST,
    borderWidth: 1,
    marginVertical: 5,
  },
});
