import {faClose} from '@fortawesome/free-solid-svg-icons';
import {
  oneOfType,
  node,
  array,
  bool,
  func,
  string,
  number,
  object,
  arrayOf,
  shape,
} from 'prop-types';
import React from 'react';
import {View, TouchableOpacity, Modal, Text, FlatList} from 'react-native';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';

Button.propTypes = {
  text: string,
  onPress: func,
  priority: string,
};
function Button({text, onPress, priority, status}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <EtherButton
      onPress={onPress}
      style={[
        style.button,
        priority === 'primary' ? style.primaryButton : style.secondaryButton,
      ]}
      status={status}
    >
      <Text
        style={[
          style.buttonText,
          priority === 'primary'
            ? style.primaryButtonText
            : style.secondaryButtonText,
        ]}
      >
        {text}
      </Text>
    </EtherButton>
  );
}

EtherDialog.propTypes = {
  show: bool,
  onClose: func,
  children: node,
  header: string,
  subheader: string,
  controls: arrayOf(
    shape({
      text: string,
      onPress: func,
      priority: string,
    }),
  ),
  closable: bool,
  style: oneOfType([object, array, number]),
  backdropStyle: oneOfType([object, array, number]),
};
export default function EtherDialog({
  show,
  onClose,
  children,
  header,
  subheader,
  controls,
  transparent = true,
  closable = true,
  style: overrides,
  backdropStyle,
  status,
  ...rest
}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <Modal
      useNativeDriver={false}
      animationType="fade"
      transparent={transparent}
      visible={show}
      onRequestClose={closable ? onClose : null}
      ariaHideApp={false}
      {...rest}
    >
      <TouchableOpacity
        style={[style.modalBackdrop, backdropStyle]}
        onPress={closable ? onClose : null}
        activeOpacity
      />
      <View style={style.modalContainer}>
        <View style={[style.modal, overrides]}>
          {closable ? (
            <TouchableOpacity style={style.closeButton} onPress={onClose}>
              <FaIcon icon={faClose} color={values.FIRST} size={30} />
            </TouchableOpacity>
          ) : null}
          <View style={style.header}>
            <Text style={style.headerText}>{header}</Text>
            <Text style={style.subText}>{subheader}</Text>
          </View>
          {children}
          {controls ? (
            <FlatList
              numColumns={controls?.length}
              data={controls}
              keyExtractor={(item) => item.text}
              renderItem={({item}) => {
                return (
                  <Button
                    text={item.text}
                    onPress={item.onPress}
                    priority={item.priority}
                    status={status}
                  />
                );
              }}
            />
          ) : null}
        </View>
      </View>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    paddingHorizontal: 20,
    margin: 5,
    height: 40,
  },
  buttonText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    position: 'absolute',
    top: 10,
    right: 10,
  },
  header: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  headerText: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 5,
    textAlign: 'center',
  },
  modal: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 10,
    padding: 20,
    paddingHorizontal: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBackdrop: {
    inset: 0,
    position: 'absolute',
    backgroundColor: theme.BLACKGROUND,
    opacity: 0.8,
  },
  modalContainer: {
    margin: 'auto',
  },
  primaryButtonText: {
    color: theme.LIGHT,
  },
  secondaryButton: {
    backgroundColor: theme.BGFIRST,
  },
  secondaryButtonText: {
    color: theme.DARK,
  },
  subText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    textAlign: 'center',
  },
});
