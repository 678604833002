import {node, any, oneOf, object, bool} from 'prop-types';
import React, {useState} from 'react';
import {TouchableOpacity, ActivityIndicator, Platform} from 'react-native';

import {useTheme} from '../context/ThemeContext';
import {PLATFORMS, STATUS} from '../utils/common/constants';

EtherButton.propTypes = {
  children: node,
  style: any,
  status: oneOf(Object.values(STATUS)),
  spinnerProps: object,
  disabled: bool,
};
export default function EtherButton({
  children,
  style: overrides,
  status = STATUS.IDLE,
  spinnerProps,
  disabled,
  ...rest
}) {
  const {style, values} = useTheme(getThemedStyles);
  const [mouseHover, setMouseHover] = useState(null);
  const displayStatus = disabled ? status.DISABLED : status;
  return (
    <TouchableOpacity
      disabled={disabled}
      pointerEvents={displayStatus === STATUS.BUSY ? 'none' : 'auto'}
      onMouseOver={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      activeOpacity={displayStatus === STATUS.INVALID ? 1 : 0.2}
      style={[
        style[displayStatus],
        overrides,
        mouseHover ? style.brighten : null,
      ]}
      {...rest}
    >
      {displayStatus === STATUS.BUSY ? (
        <ActivityIndicator color={values.FIRST} size={24} {...spinnerProps} />
      ) : (
        children
      )}
    </TouchableOpacity>
  );
}

const getThemedStyles = (theme) => ({
  brighten: {
    filter: 'brightness(1.05)',
  },
  [STATUS.BUSY]: {
    backgroundColor: theme.DISABLED,
    borderColor: theme.FIRST,
    cursor: Platform.OS === PLATFORMS.web ? 'not-allowed' : null,
    borderWidth: 2,
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  [STATUS.DISABLED]: {
    backgroundColor: theme.DISABLED,
    borderColor: theme.DISABLEDBORDER,
    borderRadius: 5,
    borderWidth: 2,
    mobile: {
      borderRadius: 10,
      borderWidth: 3,
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  [STATUS.IDLE]: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  [STATUS.INVALID]: {
    backgroundColor: theme.RED,
    borderColor: theme.RED,
    cursor: Platform.OS === PLATFORMS.web ? 'not-allowed' : null,
    borderWidth: 2,
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  [STATUS.VALID]: {
    backgroundColor: theme.GREEN,
    borderColor: theme.FIRST,
    borderWidth: 2,
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
