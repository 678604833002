import {faCalendar, faFile, faBars} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {View, TouchableOpacity, Text} from 'react-native';

import FaIcon from '../components/FaIcon';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {useIsMobile} from '../utils/common/hooks';
import {logOut} from '../utils/common/login';
import {HEADER_HEIGHT} from '../utils/web/constants.web';
import AccountMenu from './AccountMenu.web';
import EtherMediaFullLogo from './EtherMediaLogoSVG';
import EtherMediaShortLogo from './EtherMediaShortLogoSVG';

export default function TopBar({navigation}) {
  const {navigate} = navigation;
  const [showMenu, setShowMenu] = useState(false);
  const [mouseHover, setMouseHover] = useState(null);

  const {op, clearOperator} = useOperator();
  const {style, values} = useTheme(getThemedStyles);
  const {isMobile} = useIsMobile();

  const pathname = window.location.pathname;
  const notSupported = isMobile && pathname === '/asset-manager';
  const lockedOut = pathname === '/setup';
  const isCheckout = pathname === '/checkout';
  const authenticated = !!op;

  const isCondensed = authenticated || isMobile;
  const Logo = isCondensed ? EtherMediaShortLogo : EtherMediaFullLogo;

  const hoverItems = {
    logo: 'logo',
    event_manager: 'event_manager',
    asset_manager: 'asset_manager',
    menu: 'menu',
  };

  function onLogOut() {
    logOut()
      .finally(() => {
        clearOperator();
        navigate('Log In');
      })
      .catch(console.error);
  }

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  // Hide header if new user hasn't completed profile info
  if (lockedOut) return null;

  return (
    <View style={style.padding}>
      {/* Only show logo if on Checkout */}
      {isCheckout ? (
        <View style={style.checkout}>
          <TouchableOpacity onPress={() => navigate('Home')}>
            <EtherMediaFullLogo
              onMouseOver={() => setMouseHover(true)}
              onMouseLeave={() => setMouseHover(false)}
              style={[style.fullLogo, mouseHover ? style.brighten : null]}
              color={values.FIRST}
            />
          </TouchableOpacity>
        </View>
      ) : (
        <View
          style={
            authenticated
              ? [style.topBar, {paddingHorizontal: 5}]
              : style.topBar
          }
        >
          <View style={style.leftNavContainter}>
            <TouchableOpacity onPress={() => navigate('Home')}>
              <Logo
                onMouseOver={() => setMouseHover(hoverItems.logo)}
                onMouseLeave={() => setMouseHover(null)}
                style={[
                  isCondensed ? style.logo : style.fullLogo,
                  mouseHover === hoverItems.logo ? style.brighten : null,
                ]}
                color={values.FIRST}
              />
            </TouchableOpacity>

            {/* Show icons for asset and event manager on mobile */}
            {authenticated && isMobile ? (
              <>
                <TouchableOpacity
                  style={style.navButton}
                  onPress={() => navigate('Event Manager')}
                >
                  <FaIcon icon={faCalendar} size={30} style={style.navIcon} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={style.navButton}
                  onPress={() => navigate('Asset Manager')}
                >
                  <FaIcon icon={faFile} size={30} style={style.navIcon} />
                </TouchableOpacity>
              </>
            ) : null}

            {/* Show "Not Supported" when viewing asset manager mobile */}
            {notSupported ? (
              <Text style={style.notForMobile}>
                This device size is not fully supported
              </Text>
            ) : null}

            {/* Show text buttons for Asset and Event manager */}
            {authenticated && !isMobile ? (
              <>
                <TouchableOpacity
                  style={style.navButton}
                  onPress={() => navigate('Event Manager')}
                  onMouseEnter={() => setMouseHover(hoverItems.event_manager)}
                  onMouseLeave={() => setMouseHover(null)}
                >
                  <Text
                    style={[
                      style.navText,
                      mouseHover === hoverItems.event_manager
                        ? style.underline
                        : null,
                    ]}
                  >
                    Event Manager
                  </Text>
                </TouchableOpacity>
                <View style={style.lineBreak} />
                <TouchableOpacity
                  style={style.navButton}
                  onPress={() => navigate('Asset Manager')}
                  onMouseEnter={() => setMouseHover(hoverItems.asset_manager)}
                  onMouseLeave={() => setMouseHover(null)}
                >
                  <Text
                    style={[
                      style.navText,
                      mouseHover === hoverItems.asset_manager
                        ? style.underline
                        : null,
                    ]}
                  >
                    Asset Manager
                  </Text>
                </TouchableOpacity>
              </>
            ) : null}
          </View>

          {/* Show account menu if logged in */}
          {authenticated ? (
            <TouchableOpacity
              style={[
                style.accountButton,
                mouseHover === hoverItems.menu
                  ? style.accountButtonHovered
                  : null,
              ]}
              onPress={() => toggleMenu()}
              activeOpacity={0.5}
              onMouseEnter={() => setMouseHover(hoverItems.menu)}
              onMouseLeave={() => setMouseHover(null)}
            >
              <FaIcon
                style={style.menuIcon}
                icon={faBars}
                size={22}
                color={values.FIRST}
              />
              {showMenu ? (
                <AccountMenu
                  onPressAccount={() => {
                    setShowMenu(false);
                    navigate('Profile');
                  }}
                  onPressOrders={() => {
                    setShowMenu(false);
                    navigate('Orders');
                  }}
                  onPressLogOut={() => {
                    setShowMenu(false);
                    onLogOut();
                  }}
                  onHide={() => setTimeout(() => toggleMenu(), 130)}
                />
              ) : null}
            </TouchableOpacity>
          ) : null}

          {/* Show login prompts when not logged in */}
          {!authenticated ? (
            <View style={style.authMenuControls}>
              <TouchableOpacity
                style={style.loginButton}
                onPress={() => navigate('Log In')}
              >
                <Text style={style.loginText}>Log in</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={style.signupButton}
                onPress={() => navigate('Sign Up')}
              >
                <Text style={style.signupText}>Sign up</Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
      )}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  accountButton: {
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    height: 45,
    width: 45,
    borderRadius: 100,
  },
  accountButtonHovered: {
    backgroundColor: theme.BGFIRST,
  },
  accountText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    padding: 5,
  },
  authMenuControls: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  brighten: {
    filter: 'brightness(1.05)',
  },
  checkout: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: HEADER_HEIGHT,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderBottomWidth: 2,
    position: 'fixed',
  },
  fullLogo: {
    width: 220,
    height: 'auto',
    justifyContent: 'center',
  },
  icon: {
    width: 60,
    height: 60,
    justifyContent: 'center',
  },
  leftNavContainter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lineBreak: {
    height: 25,
    borderWidth: 1,
    borderColor: theme.LIGHT,
    marginLeft: 15,
    alignSelf: 'center',
  },
  loginButton: {
    maxWidth: 125,
    width: '100%',
    height: 35,
    borderColor: theme.LIGHT,
    borderWidth: 3,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    mobile: {
      width: 60,
    },
  },
  loginText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Regular',
    color: theme.LIGHT,
  },
  logo: {
    width: 60,
    height: 60,
    justifyContent: 'center',
  },
  menuIcon: {
    alignSelf: 'center',
    marginTop: 10,
  },
  navButton: {
    height: 60,
    justifyContent: 'center',
    marginLeft: 15,
  },
  navIcon: {
    color: theme.LIGHT,
  },
  navText: {
    color: theme.LIGHT,
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
  },
  notForMobile: {
    marginLeft: 20,
    marginTop: 10,
    maxWidth: 160,
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  padding: {
    height: HEADER_HEIGHT,
  },
  signupButton: {
    maxWidth: 125,
    width: '100%',
    height: 35,
    borderRadius: 5,
    backgroundColor: theme.LIGHT,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
    mobile: {
      width: 60,
    },
  },
  signupText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },

  topBar: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: HEADER_HEIGHT,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderBottomWidth: 2,
    paddingHorizontal: '15%',
    position: 'fixed',
    mobile: {
      paddingHorizontal: null,
    },
  },
  underline: {
    textDecorationLine: 'underline',
  },
});
