import axios from 'axios';
import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import toast from 'react-hot-toast';
import io from 'socket.io-client';

import {
  addPack,
  findEvents,
  findPacks,
  getPack,
  addEvent,
  delPack,
  deleteAsset,
  delEvent,
  renameAsset as rename,
} from '../utils/common/packs';
import showNetworkError from '../utils/ether-fetch/showNetworkError.web';

const AssetsContext = React.createContext();

/**
 * Parse a query string into an object
 * @param {String} queryString - query param string i.e. 'foo=bar&biz=baz'
 * @returns {Object} - map of query string
 */
function parseQueryString(queryString) {
  if (queryString.length === 0) return {};
  const queryPairs = queryString.replaceAll('?', '').split('&');
  const result = queryPairs.reduce((acc, pair) => {
    const [key, rawValue] = pair.split('=');
    const value = decodeURIComponent(rawValue);
    acc[key] = value;
    return acc;
  }, {});
  return result;
}

/**
 * Set a URL param, if the value is falsey it will remove the URL param
 * Only sets if the value is new.
 * @param {String} newKey - query param key
 * @param {String | null} newValue - query param string
 */
function setUrlParam(newKey, newValue) {
  const queryParams = parseQueryString(window.location.search);
  if (queryParams[newKey] === newValue) return;
  queryParams[newKey] = newValue;

  const newQueryString = Object.entries(queryParams).reduce((acc, entry, i) => {
    const [key, value] = entry;
    if (!value) return acc;

    const encodedVal = encodeURIComponent(value);
    const queryPart =
      acc.length !== 0 ? `&${key}=${encodedVal}` : `?${key}=${encodedVal}`;
    return acc.concat(queryPart);
  }, '');

  const [querylessUrl] = window.location.href.split('?');

  // Set URL without reloading
  window.history.replaceState(null, '', querylessUrl.concat(newQueryString));
}

function AssetsProvider({children}) {
  const [events, setEvents] = useState(null); // array of assets belonging to the op
  const [currentEvent, setCurrentEvent] = useState(null); //name of event

  const [packs, setPacks] = useState(null); //array of packs in selected event
  const [currentPack, setCurrentPack] = useState(null); //name of pack
  const currentPackRef = useRef(currentPack);
  currentPackRef.current = currentPack;
  const [packPrice, setPackPrice] = useState();

  const [assets, setAssets] = useState(null); // array of assets in currently selected pack
  const assetsRef = useRef(assets);
  assetsRef.current = assets;

  const [, setForceRender] = useState({});

  const socketRef = useRef(null);

  const currentUpdateController = useRef(null);

  if (currentEvent) setUrlParam('event', currentEvent);
  if (currentPack) setUrlParam('pack', currentPack);

  //-------------------------Internal-----------------------------

  /**
   * Handle a message from the server
   * @param id
   * @returns {boolean} - True if connection made, false if it already exists
   */
  const trySocketConnection = useCallback(
    (id) => {
      const hasUserId =
        id ||
        (localStorage.userID &&
          localStorage.userID !== 'undefined' &&
          localStorage.userID !== 'null');
      if (socketRef.current || !hasUserId) return false;

      const userID = id || localStorage.userID;
      socketRef.current = io(window.location.origin, {
        query: `userID=${userID}`,
      });
      socketRef.current.onAny(onMessage);
      return true;
    },
    [onMessage],
  );

  /**
   * Refresh current operator's events
   */
  const _refreshEvents = useCallback(async () => {
    try {
      const apiEvents = await findEvents();
      setEvents(apiEvents);
    } catch (err) {
      console.error(err);
      toast.error('Unable to refresh events');
    }
  }, []);

  /**
   * Refresh currently selected event's packs
   */
  async function _refreshPacks() {
    try {
      const apiPacks = await findPacks(currentEvent);
      setPacks(apiPacks);
      return apiPacks;
    } catch (err) {
      console.error(err);
      toast.error(`Unable to refresh packs for ${currentEvent}`);
    }
  }

  /**
   * Refresh currently selected packs's assets
   */
  const _refreshAssets = useCallback(async () => {
    if (currentUpdateController.current) {
      currentUpdateController.current.abort();
    }
    currentUpdateController.current = new AbortController();
    const signal = currentUpdateController.current.signal;

    try {
      if (currentEvent && currentPack) {
        const apiPackPromise = getPack(currentEvent, currentPack, {
          signal,
          hideError: true,
        });
        const apiPack = await apiPackPromise;
        setAssets(apiPack.assets);
      }
    } catch (err) {
      console.error('Failed to find assets', err);
      if (err.name === 'AbortError') {
        return;
      }
      toast.error(
        `Unable to refresh assets for ${currentPack} in ${currentEvent}`,
      );
    } finally {
      currentUpdateController.current = null;
    }
  }, [currentEvent, currentPack]);

  const _refreshAssetsRef = useRef(_refreshAssets);
  _refreshAssetsRef.current = _refreshAssets;

  //-------------------------Events-----------------------------

  /**
   * Create a new event for the current operator, refresh events
   * @param {String} name - Event name
   * @param {String} location - Location name
   * @returns Promise
   */
  async function createEvent(name, location) {
    try {
      const result = await addEvent(name, location);
      await _refreshEvents();
      return result;
    } catch (err) {
      toast.error(`Unable to add event: ${name}`);
      console.error(err);
    }
  }

  /**
   * Select an event, load its packs
   * @param {String} name - event to select
   * @returns Promise
   */
  async function selectEvent(name) {
    if (!events) {
      console.warn('Tried to load events when none were loaded');
      return;
    }

    if (events.find((e) => e.name === name)) {
      try {
        const apiPacks = await findPacks(name);
        const [firstPack] = apiPacks;
        setCurrentEvent(name);
        setPacks(apiPacks);
        setAssets(null);
        if (firstPack) {
          setCurrentPack(firstPack.name);
          const apiPack = await getPack(name, firstPack.name);
          setAssets(apiPack.assets);
        } else {
          setPacks(null);
          setCurrentPack(null);
          setAssets(null);
        }
      } catch (err) {
        console.error('Failed to find packs', err);
      }
    } else {
      throw new Error("Selected event wasn't found");
    }
  }

  /**
   * Delete an event, refresh events
   * @param {String} name - event to delete
   */
  async function deleteEvent(name) {
    try {
      const result = await delEvent(name);
      await _refreshEvents();
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  //-------------------------Packs-----------------------------

  /**
   * Create a pack for the currently selected event, refresh packs
   * @param {String} name - pack to create
   */
  async function createPack(name) {
    try {
      const result = await addPack(currentEvent, name);
      const freshPacks = await _refreshPacks();

      // Auto-select first pack
      if (!currentPack && freshPacks.length > 0) {
        setCurrentPack(freshPacks[0].name);
        setAssets(freshPacks[0].assets);
      }

      return result;
    } catch (err) {
      console.error('Unable to add pack', err);
    }
  }

  /**
   * Select a pack, load its assets
   * @param {String} name - pack to select
   */
  async function selectPack(name) {
    if (!packs) {
      console.error('Tried to select pack when none were loaded');
      return;
    }

    if (packs.find((p) => p.name === name)) {
      try {
        const apiPack = await getPack(currentEvent, name);
        setCurrentPack(name);
        setAssets(apiPack.assets);
        setPackPrice(apiPack.price);
      } catch (err) {
        console.error('Failed to find assets', err);
      }
    } else {
      const errMsg = `Selected pack "${name}" could not be found`;
      toast.error(errMsg);
      console.error(errMsg);
      throw errMsg;
    }
  }

  /**
   * Delete current pack, refresh events and packs
   */
  async function deletePack() {
    try {
      const result = await delPack(currentEvent, currentPack);
      setCurrentPack(null);
      setPacks(null);
      setPackPrice(null);
      await _refreshEvents();
      const freshPacks = await _refreshPacks();

      // Auto-select first pack
      if (freshPacks.length > 0) {
        selectPack(freshPacks[0].name);
      }

      await _refreshAssetsRef.current();

      return result;
    } catch (err) {
      console.error(err);
      toast.error('Unable to delete pack');
    }
  }

  //-------------------------Assets-----------------------------

  /**
   * Rename an asset, refresh assets
   * @param {String} name - asset to rename
   * @param {String} newName - new name for asset
   */
  async function renameAsset(name, newName) {
    try {
      const result = await rename(currentEvent, currentPack, name, newName);
      await _refreshAssets();
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Delete an asset or array of assets from the current pack, by name. Refresh assets.
   * @param {[String] | String} names - name or names of assets to delete
   */
  async function deleteAssets(names) {
    const namesDelimited = Array.isArray(names) ? names.join(',') : names;

    try {
      const result = await deleteAsset(
        currentEvent,
        currentPack,
        namesDelimited,
      );
      await _refreshAssets();
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  //-------------------------Legacy-----------------------------

  /**
   * Make the form data to be sent to the server
   * @param {Object<File>} file - browser file to upload
   * @param {Object} data - JSON to pass along with file
   */
  function makeFormUpload(file, data) {
    const formData = new FormData();
    formData.append('uploads', file);
    formData.append('upload_options', JSON.stringify(data));
    return formData;
  }

  /*
   * Upload files to a pack and keep track of their progress
   * @param {Array} files - files to upload
   * @param {String} event - event directory name
   * @param {String} pack - name of pack
   * @param {Object} uploadInfo - progress of uploads
   */
  function uploadFiles(files, event, pack, uploadInfo) {
    return files.map((file) => {
      const formData = makeFormUpload(file, {event, pack});

      function updateProgress(progress) {
        const percentage = Math.floor((progress.loaded * 100) / progress.total);
        const uploadId = file.path + file.dropTime;
        if (!uploadInfo.current[uploadId]) {
          uploadInfo.current[uploadId] = {
            name: file.name,
            nameNoExt: file.name.replace(/\.[^/.]+$/, ''),
            packName: pack,
            percentage,
            path: file.path.includes('/') ? file.path : `${pack}/${file.name}`,
            status: 'pending',
            dropTime: file.dropTime,
            mime: file.type,
          };
        } else {
          uploadInfo.current[uploadId].percentage = percentage;
        }
        setForceRender(Object.entries(uploadInfo.current));
      }

      const config = {
        onUploadProgress: updateProgress,
        headers: {
          authorization: localStorage.token,
        },
      };
      return axios
        .post(`${window.location.origin}/upload`, formData, config)
        .then((res) => {
          const uploadedAssets = res.data.assets;
          if (pack === currentPackRef.current) {
            setAssets((currentAssets) => [...currentAssets, ...uploadedAssets]);
          }
        })
        .catch((res) => {
          showNetworkError(res, config);
          throw {msg: res?.msg || 'Unknown upload error'};
        });
    });
  }

  //-------------------------Init-----------------------------

  // Handle websocket messages
  const onMessage = useCallback((event, ...args) => {
    if (event === 'refresh') {
      const assetId = args[0].assetId;
      if (assetsRef.current.findIndex(({_id}) => assetId === _id) > -1)
        _refreshAssetsRef.current();
    }
    if (event === 'update') {
      const updatedAsset = args[0].asset;
      const assetIndex = assetsRef.current.findIndex(
        ({_id}) => updatedAsset._id === _id,
      );
      if (assetIndex > -1) {
        setAssets((currentAssets) => {
          const currentCopy = [...currentAssets];
          currentCopy[assetIndex] = updatedAsset;
          return currentCopy;
        });
      }
    }
  }, []);

  // Establish websocket connection
  useEffect(() => {
    trySocketConnection();
  }, [trySocketConnection]);

  useEffect(() => {
    function cleanup() {
      if (!socketRef.current) return;
      socketRef.current.offAny(onMessage);
    }
    return cleanup;
  }, [onMessage]);

  const initialize = useCallback(() => {
    const {event: urlEvent, pack: urlPack} = parseQueryString(
      window.location.search,
    );

    let selectedEvent;
    let selectedPack;

    findEvents()
      .then((apiEvents) => {
        setEvents(apiEvents);

        if (apiEvents.length > 0) {
          const [firstEvent] = apiEvents;
          selectedEvent = urlEvent || firstEvent.name;

          setCurrentEvent(selectedEvent);
          return findPacks(selectedEvent);
        }
      })
      .catch(() => {
        setCurrentEvent(null);
        setUrlParam('event', null);
        setUrlParam('pack', null);
      })
      .then((apiPacks) => {
        setPacks(apiPacks);

        if (apiPacks.length > 0) {
          const [firstPack] = apiPacks;
          selectedPack = urlPack || firstPack.name;

          setCurrentPack(selectedPack);
          return getPack(selectedEvent, selectedPack);
        }
      })
      .catch(() => {
        setCurrentPack(null);
        setUrlParam('pack', null);
      })
      .then((apiPack) => {
        setAssets(apiPack?.assets || []);
        setPackPrice(apiPack?.price || null);
      })
      .catch((err) =>
        //TODO: Redirect the user?
        console.error('Unable to load api data: ', err),
      );
  }, []);

  //-------------------------END Init-----------------------------

  return (
    <AssetsContext.Provider
      value={{
        initialize,
        events,
        createEvent,
        currentEvent,
        deleteEvent,
        selectEvent,
        deletePack,
        createPack,
        packs,
        currentPack,
        selectPack,
        packPrice,
        assets,
        renameAsset,
        uploadFiles,
        deleteAssets,
        trySocketConnection,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
}

function useAssets() {
  const context = useContext(AssetsContext);
  if (!context) {
    throw new Error('useAssets must be used within an AssetsProvider');
  }

  return context;
}

export {useAssets, AssetsProvider};
export default AssetsContext;
