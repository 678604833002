import React, {useContext, createContext, useState, useMemo} from 'react';

import {useIsMobile} from '../utils/common/hooks';

const ThemeContext = createContext();

function getFontSize(isMobile) {
  const baseFontSize = isMobile ? 16 : 16.5;

  return {
    //Scale is 1.125 - Major Second
    starHeader: baseFontSize * 3.25, //~58
    planetHeader: baseFontSize * 2.75, //~44
    header: baseFontSize * 1.602, //~25
    subheader: baseFontSize * 1.266,
    bigBody: baseFontSize * 1.125, //~18
    body: baseFontSize, //~16
    legal: baseFontSize * 0.79,
    tiny: baseFontSize * 0.702,
  };
}
const palettes = {
  ether: {
    FIRST: '#4C6CD3',
    SECOND: '#878DEC',
    THIRD: '#B6A1F7',
    FOURTH: '#E7B0FF',
    BGFIRST: '#E2E2E2',
    BGSECOND: '#F2F2F2',
    RED: '#B73C3C',
    YELLOW: '#FFBF00',
    GREEN: '#44B149',
    LIGHT: '#E2E2E2',
    DARK: '#444444',
    DISABLED: '#B0B0B0',
    DISABLEDBORDER: '#858585',
    DISABLEDTEXT: 'GRAY',
    BLUR: '#7D87AD',
    BLACKGROUND: 'rgba(0, 0, 0, 0.8)',
  },
  lanoire: {
    FIRST: '#516371',
    SECOND: '#60717B',
    THIRD: '#73818E',
    FOURTH: '#96A6B3',
    BGFIRST: '#B8B8B8',
    BGSECOND: '#D0D0D0',
    RED: '#A14B4B',
    YELLOW: '#E0A55B',
    GREEN: '#48954B',
    LIGHT: '#D0D0D0',
    DARK: '#444444',
    DISABLED: '#B0B0B0',
    DISABLEDBORDER: '#858585',
    DISABLEDTEXT: 'GRAY',
    BLACKGROUND: 'rgba(0, 0, 0, 0.8)',
  },
  cyanMono: {
    FIRST: '#5971AD',
    SECOND: '#95A9DE',
    THIRD: '#40589A',
    FOURTH: '#32488E',
    BGFIRST: '#E2E2E2',
    BGSECOND: '#F2F2F2',
    RED: '#B73C3C',
    YELLOW: '#FEAE4A',
    GREEN: '#44B149',
    LIGHT: '#E2E2E2',
    DARK: '#444444',
    DISABLED: '#B0B0B0',
    DISABLEDBORDER: '#858585',
    DISABLEDTEXT: 'GRAY',
    BLUR: '#7D87AD',
    BLACKGROUND: 'rgba(0, 0, 0, 0.8)',
  },
};

// Handle styles with mobile styles
function parseMobileStyle(styleObject, isMobile) {
  if ('mobile' in styleObject) {
    if (isMobile) {
      const {mobile, ...desktopStyles} = styleObject;
      const compositeStyles = {...desktopStyles, ...mobile};

      // Don't include desktop styles in mobile if the mobile definition is null
      const unNulledStyles = Object.entries(compositeStyles).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {},
      );
      return unNulledStyles;
    } else {
      delete styleObject.mobile;
      return styleObject;
    }
  } else {
    return styleObject;
  }
}

/**
 * Given a stylesheet, parse out mobile and desktop styles.
 * Allows us to nest mobile styles within existing style definitions.
 * At mobile, mobile styles are used INSTEAD of the other styles surrounding it.
 * If mobile styles aren't defined, the styles defined will be used for all breakpoints.
 *
 * EXAMPLE style definition:
 * if isMobile is true, componentStyle will be magenta with no defined width
 * false it will be blue with width 100, and the mobile object will be removed.
 *
 * componentStyle: {
 *   backgroundColor: 'blue',
 *   width: 100,
 *   mobile: {
 *     backgroundColor: 'magenta',
 *   },
 * },
 *
 * if isMobile is false, it returns             if isMobile is true, it returns
 *
 *   componentStyle: {                            componentStyle: {
 *     backgroundColor: 'blue',                     backgroundColor: 'magenta',
 *     width: 100,                                }
 * },
 *
 * @param {object} style - Style object
 * @param {bool} isMobile - true for mobile, false for other breakpoints
 */

function getBreakpointStyles(style, isMobile) {
  if (!style || typeof style !== 'object' || Array.isArray(style)) {
    return style;
  }

  const breakpointedStyle = parseMobileStyle(style, isMobile);

  // Parse as array, recursively
  const arrayStyles = Object.entries(breakpointedStyle).map(
    ([name, stylePart]) => {
      return [name, getBreakpointStyles(stylePart, isMobile)];
    },
  );

  // Build array of key value pairs into object
  const objectStylesheet = arrayStyles.reduce((acc, [name, stylePart]) => {
    acc[name] = stylePart;
    return acc;
  }, {});

  return objectStylesheet;
}

function ThemeProvider({children}) {
  const [theme, setTheme] = useState('ether');

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

function useTheme(styleMaker) {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  const {isMobile} = useIsMobile();
  const {theme, setTheme} = context;

  const fontSize = useMemo(() => getFontSize(isMobile), [isMobile]);

  // styleMaker won't always be defined, so default to empty object
  const style = useMemo(
    () => (styleMaker ? styleMaker(palettes[theme], fontSize) : {}),
    [styleMaker, fontSize, theme],
  );

  const breakpointedStyle = useMemo(() => {
    return getBreakpointStyles(style, isMobile);
  }, [style, isMobile]);
  const values = palettes[theme];

  return {style: breakpointedStyle, values, setTheme, fontSize};
}

export {useTheme, ThemeProvider};
export default ThemeContext;
