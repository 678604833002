import {bool, string} from 'prop-types';
import React, {useEffect, useCallback, useState} from 'react';
import {TouchableOpacity, ScrollView, Modal} from 'react-native';

import {useTheme} from '../context/ThemeContext';

DocumentModal.proptypes = {
  show: bool.isRequired,
  onHide: bool.isRequired,
  doc: string.isRequired,
};
export function DocumentModal({show, onHide, doc}) {
  const {style} = useTheme(getThemedStyles);
  const [documentText, setDocumentText] = useState(null);

  const downHandler = useCallback(
    ({key}) => {
      if (key === 'Escape') {
        onHide();
      }
    },
    [onHide],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);

  useEffect(() => {
    fetch(doc)
      .then((res) => res.text())
      .then((text) => setDocumentText(text));
  }, [doc]);

  return (
    <Modal visible={show} transparent animationType="fade" ariaHideApp={false}>
      <TouchableOpacity
        style={style.backdrop}
        onPress={onHide}
        activeOpacity={1}
      >
        <TouchableOpacity style={style.dialog} activeOpacity={1}>
          <ScrollView style={{padding: 20}}>
            <div style={style.text}>{documentText}</div>
          </ScrollView>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export const getThemedStyles = (theme) => ({
  backdrop: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.BLACKGROUND,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    height: '90%',
    width: '90%',
    maxWidth: 900,
    cursor: 'default',
  },
  text: {
    fontFamily: 'NotoSans_Regular',
    whiteSpace: 'pre-wrap',
  },
});
