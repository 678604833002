export const QAData = [
  {
    Q: 'What is Ether Media?',
    A: 'Ether Media is a sales solution for photo and video sales at live events. With the goal of convenience and ease of use, Ether Media provides an outlet for digital photos and videos to be delivered directly to your customers phone.',
  },
  {
    Q: 'How does Ether Media work?',
    A: "Photographers at an event upload images and videos to the Ether Media portal. Once those images are uploaded, they're immediately available to purchase from an Ether Media kiosk operated by the owner of the images. When a customer wants to make a purchase, they select the items they'd like and scan the accompanied QR code for their cart. They complete payment on their own device, and download their purchase immediately. No paperwork, no wait time, just high quality images delivered directly to their phone.",
  },
  {
    Q: 'What is it like to use Ether Media?',
    A: "Here are two stories: \nAlex wants high quality pictures of an event she's attending. She tries taking pictures with her phone, but the quality just isn't there. She finds an Ether Media kiosk where she can browse high quality photos from professional photographers at the event. She selects several images she'd like to purchase and adds them to her cart. She scans the QR code on the screen, and completes the checkout process independently. She downloads the images directly to her phone, adding them to her collection to save forever.\n\nAaron checks on the Ether Media kiosk at the event he's manageing. He's able to see that Alex has made a purchase and knows which photographer took the pictures she purchased. He thanks her for the purchase she made and checks to make sure everything went smoothly. Alex is thrilled with the quality of pictures she recieved and thanks Aaron for the oportunity to purchase them. With the time Aaron's freed up by using Ether Media, Aaron passes on the message to the photographers and commends them on their work.",
  },
  {
    Q: 'Who can use Ether Media?',
    A: "Ether Media is currently in a closed beta testing period. If you're interested in participating, reach out to us!",
  },
  {
    Q: 'What makes Ether Media different?',
    A: 'The days of mail in photo orders and selling dvds outside concerts are over. With Ether Media, you can deliver high quality photos and videos directly to your customers in a format they actually want. No more mail slips, no more dvds, no more confusion, just simple, modern downloads.',
  },
  {
    Q: 'Where is Ether Media available?',
    A: 'Ether Media can be found at events across the united states.',
  },
  {
    Q: 'What does Ether Media cost?',
    A: "Our plans are flexible at this time. As one of our early clients, you'll have the oportunity to work with us on customizing your payment plan in a way that suits your business needs.",
  },
  {
    Q: 'How much data can I upload?',
    A: 'Uploads are currently unlimited. You can add as much content to our servers as you want!',
  },
  {
    Q: 'How many events can I run at once?',
    A: "With Ether Media, you can host as many events as you'd like. There is no limit to the amount of events you can host at a single time.",
  },
  {
    Q: 'Does your team offer support?',
    A: "We're here to help you. If you have trouble operating our service, feel free to reach out.",
  },
  {
    Q: 'Do you take bug reports/feature requests?',
    A: "We would be delighted to hear about any bugs you find in our application or ideas to make it better. We're always working to improve",
  },
  {
    Q: "I'm convinced, where do I sign up?",
    A: "Contact us using the form on our website and we'll get back to you as soon as possible!",
  },
];
