import React, {useCallback, useEffect} from 'react';
import {Modal, Text, TouchableOpacity, View} from 'react-native-web';

import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import EtherButton from './EtherButton';

export default function DeletePackModal({show, onHide, onDelete}) {
  const {style} = useTheme(getThemedStyles);
  const handleHide = useCallback(
    (...args) => {
      onHide(...args);
    },
    [onHide],
  );

  const modalDownHandler = useCallback(
    ({key}) => {
      if (key === 'Escape') {
        handleHide();
      }
      if (key === 'Enter') {
        onDelete();
        onHide();
      }
    },
    [handleHide, onDelete, onHide],
  );

  useEffect(() => {
    window.addEventListener('keydown', modalDownHandler);
    return () => {
      window.removeEventListener('keydown', modalDownHandler);
    };
  }, [modalDownHandler]);

  return (
    <Modal visible={show} transparent animationType="fade" ariaHideApp={false}>
      <TouchableOpacity
        style={style.backdrop}
        onPress={onHide}
        activeOpacity={1}
      >
        <TouchableOpacity style={style.dialog} activeOpacity={1}>
          <Text style={style.modalText}>
            This action is permanent.
            <br />
            All selected content will be
            <br />
            permanently deleted.
          </Text>
          <View style={style.modalRow}>
            <EtherButton
              onPress={onHide}
              style={style.closeButton}
              status={STATUS.IDLE}
            >
              <Text style={style.closeText}>Close</Text>
            </EtherButton>
            <EtherButton
              onPress={() => {
                onDelete();
                onHide();
              }}
              style={style.deleteButton}
              status={STATUS.IDLE}
            >
              <Text style={style.deleteText}>Delete</Text>
            </EtherButton>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  backdrop: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.BLACKGROUND,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    width: 80,
  },
  closeText: {
    fontSize: fontSize.legal,
    color: theme.FIRST,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
  },
  deleteButton: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    width: 80,
    marginLeft: 15,
  },
  deleteText: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    textAlign: 'center',
    color: theme.LIGHT,
  },
  dialog: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    height: 210,
    width: 380,
    padding: 20,
    cursor: 'default',
  },
  modalRow: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  modalText: {
    color: theme.DARK,
    textAlign: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    marginBottom: 10,
  },
});
