import * as React from 'react';
import Svg, {Path, G} from 'react-native-svg';

import {useTheme} from '../context/ThemeContext';

function SvgComponent(props) {
  const {values} = useTheme();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      //width={1469}
      //height={354}
      viewBox="0 0 1469 354"
      {...props}
    >
      <Path
        fill={values.BGSECOND}
        d="M23 304L21.939 70.336l702.72-50.584v45.576l702.719-52.456V249L730 297v-43z"
      />
      <Path
        fill={values.FIRST}
        d="M753.459,72.229V29.6l-725.5,49.88v261l706.94-48.96v42.08l726.061-49.12v-261L753.459,72.229z
		 M1436.875,261.813l-678.75,46.661v-42.166L51.958,314.354V102.149l677.875-47.17v42.5l707.042-48.917V261.813z"
      />
      <Path
        fill={values.SECOND}
        d="M734.458 55.229V12.6L8.958 62.48v261l706.94-48.96v42.08l726.061-49.12v-261L734.458 55.229zm683.417 189.584l-678.75 46.661v-42.166L32.958 297.354V85.149l677.875-47.17v42.5l707.042-48.917v213.251z"
      />
      {/* //THIRD */}
      <G fill={values.THIRD}>
        <Path d="M78 270.001V123.169l73.573-5.079v14.683l-58.859 4.063v44.05l44.144-3.048v14.683l-44.144 3.047V254.3l58.859-4.063v14.684L78 270.001zM241.392 258.722V126.573l-29.43 2.031v-14.683l73.573-5.079v14.683l-29.429 2.032v132.148l-14.714 1.017zM345.924 251.506V104.675l14.715-1.016v58.732l44.144-3.047v-58.732l14.715-1.016v146.831l-14.715 1.016v-73.416l-44.144 3.047v73.416l-14.715 1.016zM479.886 242.258V95.427l73.573-5.079v14.683l-58.858 4.063v44.05l44.144-3.047v14.683l-44.144 3.047v58.732l58.858-4.063v14.684l-73.573 5.078zM613.849 233.011V86.18l44.144-3.047c8.123-.561 15.02 1.87 20.693 7.289 5.823 5.258 8.736 11.94 8.736 20.045v14.683c0 8.26-2.914 15.423-8.736 21.481-5.673 6.052-12.57 9.356-20.693 9.917l-7.357.508 36.787 70.877-14.714 1.016-36.787-70.877-7.357.508v73.416l-14.716 1.015zm44.143-91.146c3.984-.275 7.433-1.89 10.346-4.844 2.91-3.104 4.369-6.726 4.369-10.855v-14.683c0-3.976-1.458-7.316-4.369-10.022-2.914-2.702-6.362-3.92-10.346-3.645l-29.429 2.031v44.05l29.429-2.032z" />
        <G>
          <Path d="M781.167 265.082V118.251l22.246-1.571L818.242 145l14.83-30.414 22.244-1.571v146.831l-14.829 1.047V128.746l-22.245 45.621-22.244-42.479v132.148l-14.831 1.046zM917.129 255.482V108.651l74.149-5.235v14.683l-59.319 4.188v44.05l44.49-3.142v14.683l-44.49 3.142v58.732l59.319-4.188v14.684l-74.149 5.234zM1053.089 245.883V99.052l44.49-3.142c8.187-.578 15.138 1.838 20.854 7.246 5.869 5.246 8.805 11.921 8.805 20.027v88.099c0 8.259-2.936 15.428-8.805 21.499-5.717 6.064-12.668 9.383-20.854 9.961l-44.49 3.141zm44.49-17.825c4.016-.283 7.491-1.904 10.427-4.865 2.934-3.111 4.403-6.734 4.403-10.864V124.23c0-3.976-1.47-7.313-4.403-10.013-2.936-2.696-6.411-3.907-10.427-3.624l-29.66 2.094v117.465l29.66-2.094zM1189.051 236.283V221.6l29.659-2.095V102.04l-29.659 2.094V89.452l74.149-5.235V98.9l-29.66 2.094v117.465l29.66-2.095v14.684l-74.149 5.235zM1325.012 226.683V109.218c0-8.105 2.856-15.19 8.573-21.254 5.714-6.214 12.745-9.618 21.086-10.207l14.831-1.047c8.187-.578 15.138 1.838 20.854 7.245 5.869 5.246 8.806 11.921 8.806 20.027v117.464l-14.829 1.047v-73.416l-44.49 3.141v73.416l-14.831 1.049zm59.32-92.287V105.03c0-3.976-1.471-7.313-4.403-10.013-2.937-2.696-6.412-3.907-10.427-3.623l-14.831 1.047c-4.17.294-7.726 2.001-10.658 5.112-2.78 3.104-4.171 6.643-4.171 10.619v29.366l44.49-3.142z" />
        </G>
      </G>
      {/* //FOURTH */}
      <G fill={values.FOURTH}>
        <Path d="M69 262.001V115.169l73.573-5.079v14.683l-58.859 4.063v44.05l44.144-3.047v14.683l-44.144 3.047v58.732l58.859-4.063v14.684L69 262.001zM232.392 250.722V118.573l-29.43 2.031v-14.683l73.573-5.079v14.683l-29.429 2.032v132.148l-14.714 1.017zM336.924 243.506V96.675l14.715-1.016v58.732l44.144-3.047V92.612l14.715-1.016v146.831l-14.715 1.016v-73.416l-44.144 3.047v73.416l-14.715 1.016zM470.886 234.258V87.427l73.573-5.079v14.683l-58.858 4.063v44.05l44.144-3.047v14.683l-44.144 3.047v58.732l58.858-4.063v14.684l-73.573 5.078zM604.849 225.011V78.18l44.144-3.047c8.123-.561 15.02 1.87 20.693 7.289 5.823 5.258 8.736 11.94 8.736 20.045v14.683c0 8.26-2.914 15.423-8.736 21.481-5.673 6.052-12.57 9.356-20.693 9.917l-7.357.508 36.787 70.877-14.714 1.016-36.787-70.877-7.357.508v73.416l-14.716 1.015zm44.143-91.146c3.984-.275 7.433-1.89 10.346-4.844 2.91-3.104 4.369-6.726 4.369-10.855v-14.683c0-3.976-1.458-7.316-4.369-10.022-2.914-2.702-6.362-3.92-10.346-3.645l-29.429 2.031v44.05l29.429-2.032z" />
        <G>
          <Path d="M772.167 257.082V110.251l22.246-1.571L809.242 137l14.83-30.414 22.244-1.571v146.831l-14.829 1.047V120.746l-22.245 45.621-22.244-42.479v132.148l-14.831 1.046zM908.129 247.482V100.651l74.149-5.235v14.683l-59.319 4.188v44.05l44.49-3.142v14.683l-44.49 3.142v58.732l59.319-4.188v14.684l-74.149 5.234zM1044.089 237.883V91.052l44.49-3.142c8.187-.578 15.138 1.838 20.854 7.246 5.869 5.246 8.805 11.921 8.805 20.027v88.099c0 8.259-2.936 15.428-8.805 21.499-5.717 6.064-12.668 9.383-20.854 9.961l-44.49 3.141zm44.49-17.825c4.016-.283 7.491-1.904 10.427-4.865 2.934-3.111 4.403-6.734 4.403-10.864V116.23c0-3.976-1.47-7.313-4.403-10.013-2.936-2.696-6.411-3.907-10.427-3.624l-29.66 2.094v117.465l29.66-2.094zM1180.051 228.283V213.6l29.659-2.095V94.04l-29.659 2.094V81.452l74.149-5.235V90.9l-29.66 2.094v117.465l29.66-2.095v14.684l-74.149 5.235zM1316.012 218.683V101.218c0-8.105 2.856-15.19 8.573-21.254 5.714-6.214 12.745-9.618 21.086-10.207l14.831-1.047c8.187-.578 15.138 1.838 20.854 7.245 5.869 5.246 8.806 11.921 8.806 20.027v117.464l-14.829 1.047v-73.416l-44.49 3.141v73.416l-14.831 1.049zm59.32-92.287V97.03c0-3.976-1.471-7.313-4.403-10.013-2.937-2.696-6.412-3.907-10.427-3.623l-14.831 1.047c-4.17.294-7.726 2.001-10.658 5.112-2.78 3.104-4.171 6.643-4.171 10.619v29.366l44.49-3.142z" />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
