import React, {useEffect} from 'react';
import toast from 'react-hot-toast';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import {useTheme} from '../../context/ThemeContext';
import {confirmStripeAccount} from '../../utils/common/orders';

export default function StripeLink({route, navigation: {navigate}}) {
  const {style} = useTheme(getThemedStyles);

  const stripeId = route?.params?.id;
  const refererPath = decodeURIComponent(route?.params?.referer);

  useEffect(() => {
    confirmStripeAccount(stripeId)
      .then((res) => {
        toast.success(res.msg);
        window.location.pathname = refererPath;
      })
      .catch((err) => {
        console.log('Error confirming stripe account');
        console.error(err);
      });
  }, [navigate, refererPath, stripeId]);

  return (
    <View style={style.main}>
      <ActivityIndicator size={60} />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  copy: {
    fontSize: fontSize.body,
    color: theme.Light,
  },
  header: {
    fontSize: fontSize.header,
    color: theme.Light,
  },
  main: {
    backgroundColor: theme.dark,
    textAlign: 'center',
    margin: 'auto',
  },
});
