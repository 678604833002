import {Platform} from 'react-native';

import showNetworkError from './showNetworkError';
import {PLATFORMS} from '../common/constants';
import {getToken} from '../common/funcs';
import {getSettings} from '../native/funcs';
import {clearLocalStorageSessionInfo} from '../web/funcs';

/**
 *
 * @param {String} route - Route for request
 * @param {*} config - Options for request
 * @param {*} config.body - Object for body, auto-parsed to JSON
 * @param {*} config.method - default: GET
 * @param {*} config.headers - Additional/override headers
 * @param {*} config.timeout - default: 10 Seconds, use 0 for no timeout
 * @param {*} config.hideError - default: false
 * @returns
 */
export default async function etherFetch(route, config) {
  const {
    body,
    method = 'GET',
    headers,
    timeout = 10,
    hideError = false,
    ...rest
  } = config || {};

  let targetHost;
  if (Platform.OS === PLATFORMS.web) {
    targetHost = window.location.host;
  } else {
    const settings = await getSettings();
    const host = settings?.host || 'ethermedia.app';
    targetHost = host;
  }

  const isLocalHost =
    targetHost.includes('localhost') || targetHost.includes('10.0.2.2');
  const protocol = isLocalHost ? 'http' : 'https';

  const token = await getToken();

  const fetchParams = {
    ...(body && {body: JSON.stringify(body)}),
    method,
    headers: {
      ...(typeof body === 'object'
        ? {'Content-Type': 'application/json'}
        : null),
      ...(token ? {authorization: token} : null),
      ...headers,
    },
    ...rest,
  };
  const url = `${protocol}://${(config && config?.host) || targetHost}${route}`;

  return new Promise((resolve, reject) => {
    if (timeout) {
      setTimeout(
        () => reject(new Error(`Network request timeout after ${timeout}s`)),
        timeout * 1000,
      );
    }
    fetch(url, fetchParams)
      .then((res) => {
        if (!res.ok) {
          const resCopy = res.clone();
          if (!hideError) {
            showNetworkError(resCopy, fetchParams);
          }
          reject(res);

          if (res.status === 401) {
            clearLocalStorageSessionInfo();

            if (window.location.pathname !== '/log-in') {
              window.location.pathname = '/log-in';
            }
          }

          return;
        }
        resolve(res);
      })
      .catch((reason) => {
        if (!hideError) {
          showNetworkError(null, fetchParams);
        }
        reject(reason);
      });
  });
}
