import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import EMLOGO from './../assets/EtherMediaIcon.png';

export default function LoadingScreen() {
  return (
    <View style={style.loadingContainer}>
      <Image style={style.image} source={{uri: EMLOGO}} />
      <ActivityIndicator size="large" color="#E2E2E2" />
      <Text style={style.subText}>
        If the page does not load within a couple seconds, try reloading
      </Text>
    </View>
  );
}

const style = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: '#878DEC', //theme.SECOND
  },
  image: {
    width: '10rem',
    height: '10rem',
    marginBottom: 40,
  },
  subText: {
    position: 'absolute',
    bottom: '0',
    paddingBottom: '2rem',
    fontFamily: 'NotoSans_Bold',
    color: '#E2E2E2', //theme.LIGHT
  },
});
