import * as React from 'react';
import Svg, {Path, G} from 'react-native-svg';

import {useTheme} from '../context/ThemeContext';

function SvgComponent(props) {
  const {values} = useTheme();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1010.16 1010.16"
      {...props}
    >
      <Path
        fill={values.BGSECOND}
        d="M483.831 728.929v110.945l348.75-27.264V244.311l-348.75 25.863-5.159-111.971L135.08 187.53v566.576z"
      />
      <Path
        fill={values.FIRST}
        d="M489.997 196.944v123.21l51.787-4.098 307.797-24.36v528.789L538.08 843.416V721.164l-51.684 3.978-308.316 23.727V221.62l311.917-24.676m48-51.947L130.08 177.268v623.438l360-27.705v122.08l407.501-30V239.747l-359.584 28.458V144.997z"
      />
      <Path
        fill={values.SECOND}
        d="M459.997 175.944v123.21l51.787-4.098 307.797-24.36v528.789L508.08 822.416V700.164l-51.683 3.978-308.317 23.727V200.62l311.917-24.676m48-51.947L100.08 156.268v623.438l360-27.705v122.08l407.501-30V218.747l-359.584 28.458V123.997z"
      />
      <Path
        fill={values.THIRD}
        d="M572.193 787.921l.278-440.349 66.138-4.74 44.037 84.91 44.147-91.23 66.139-4.74-.278 440.35-44.093 3.16.25-396.314-66.221 136.845-66.056-127.364-.25 396.314-44.091 3.158zM223.185 694.955l.277-440.35 220.461-15.515-.028 44.035-176.369 12.412-.083 132.104 132.277-9.309-.028 44.035-132.277 9.309-.111 176.139 176.369-12.411-.028 44.035-220.46 15.516z"
      />
      <G>
        <Path
          fill={values.FOURTH}
          d="M553.193 774.921l.278-440.349 66.138-4.74 44.037 84.91 44.147-91.23 66.139-4.74-.278 440.35-44.093 3.16.25-396.314-66.221 136.845-66.056-127.364-.25 396.314-44.091 3.158zM204.185 681.955l.277-440.35 220.461-15.515-.028 44.035-176.369 12.412-.083 132.104 132.277-9.309-.028 44.035-132.277 9.309-.111 176.139 176.369-12.411-.028 44.035-220.46 15.516z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
