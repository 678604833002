export const STATUS = {
  BUSY: 'busy',
  VALID: 'valid',
  INVALID: 'invalid',
  IDLE: 'idle',
};

export const SELECT = {
  DEFAULT: 'default',
  SELECTABLE: 'selectable',
  SELECTED: 'selected',
};

export const PLATFORMS = {
  android: 'android',
  ios: 'ios',
  web: 'web',
};
